import {
    V_GAMES,
    POOL_OBJECT_KEYS,
    POOL_TURNOVER_KEYS,
    PRODUCT_IDS,
    MERGED_POOLS_ORDER,
} from 'configs/products';
import omit from 'lodash/omit';

class PoolOverview {
    /**
     * The keys are the product ids
     * the values are the <ProductPool>
     * objects
     * @type {Object.<ProductPool>}
     */
    poolByProduct = {};

    /**
     * @type {MergedPool[]}
     */
    mergedPools = [];

    /**
     * Allows to watch updates between react commits
     * Format: 2018-11-02 13:24:21
     * @type {string}
     */
    racingCardTimestamp;

    /**
     * @type {Object}
     * @private
     */
    _poolDataRaw = {};

    /**
     * @type {Object}
     * @private
     */
    _mergedPoolsRaw = {};

    /**
     *
     * @param  {Object} poolDataRaw
     * @param  {Object} mergedPoolsRaw
     * @param  {string} timestamp
     * @return {PoolOverview}
     */
    unserialize = (poolDataRaw, mergedPoolsRaw, timestamp) => {
        this._poolDataRaw = poolDataRaw;
        this._mergedPoolsRaw = mergedPoolsRaw;
        this.racingCardTimestamp = timestamp;

        this.unserializeMergedPools();
        this.unserializeProductPools();

        return this;
    };

    /**
     * @mutable
     * @return {PoolOverview}
     */
    unserializeMergedPools = () => {
        try {
            this.mergedPools = Array.isArray(this._mergedPoolsRaw)
                ? this._mergedPoolsRaw.map(({ betTypes, races }) => {
                      // the turnover will be the same for all the products
                      // so we use just the first one
                      const productId = betTypes[0].code;
                      const productPoolRaw = this._poolDataRaw[productId];

                      const turnovers = this.unserializeTurnovers(
                          productId,
                          productPoolRaw
                      )
                          // Some of the races can be part
                          // of the merged pool some can not
                          .filter(([raceNumber]) => races.includes(raceNumber));

                      return new MergedPool(
                          MergedPool.sort(betTypes.map(({ code }) => code)),
                          Object.fromEntries(turnovers),
                          races
                      );
                  })
                : [];
        } catch (e) {
            console.log(`PoolOverview.MergedPool deserializing error.`);
        }

        return this;
    };

    /**
     * Mutably adds the product pools to the given PoolOverview instance
     * @mutable
     * @return {PoolOverview}
     */
    unserializeProductPools = () => {
        const poolKeys = Object.keys(
            omit(this._poolDataRaw, ['multiTrackVpPoolInfo'])
        );

        if (!poolKeys || !Array.isArray(poolKeys)) {
            return this;
        }

        poolKeys.forEach((productId) => {
            const productPoolRaw = this._poolDataRaw[productId];
            if (!productPoolRaw) return; // some pool keys have just 'null' value
            let pool = new ProductPool(productId, productPoolRaw);
            pool.isVGame = V_GAMES.some((vGameId) => productId === vGameId);

            try {
                if (pool.isVGame) {
                    pool.allRacesPoolAmount = productPoolRaw.turnover.sum;
                } else {
                    let turnovers = this.unserializeTurnovers(
                        productId,
                        productPoolRaw
                    );
                    // Some of the races can be part
                    // of the merged pool some can not
                    if (turnovers) {
                        turnovers = turnovers.filter(
                            ([raceNumber]) =>
                                !this.isPartOfMergedPool(productId, raceNumber)
                        );

                        pool.turnovers = Object.fromEntries(turnovers);
                    }
                }
            } catch (e) {
                this._logProductPoolError(productId, e);
            }

            this.poolByProduct[productId] = pool;
        });

        return this;
    };

    /**
     * @mutable
     * @param  {string}      productId
     * @param  {Object}      productPoolRaw
     * @return {Array}       The format is suitable for Object.entries
     *                       Product pool for common (not multileg) product
     *                       like V, T, TV, P, K
     */
    unserializeTurnovers = (productId, productPoolRaw) => {
        const turnovers = [];

        const poolObjectKey = POOL_OBJECT_KEYS[productId];
        const poolTurnoverKey = POOL_TURNOVER_KEYS[productId];

        if (!productPoolRaw[poolObjectKey]) {
            return;
        }

        productPoolRaw[poolObjectKey].forEach((race, index) => {
            if (productId !== PRODUCT_IDS.T) {
                const turnover = race[poolTurnoverKey] || race.turnover;

                turnovers.push([race.raceNumber, turnover ? turnover.sum : 0]);
            }

            if (!this._poolDataRaw[PRODUCT_IDS.V]) {
                return;
            }

            const vinderItem = this._poolDataRaw[PRODUCT_IDS.V][
                POOL_OBJECT_KEYS[PRODUCT_IDS.V]
            ][index];
            // sometimes we're getting more races for trio than for vinder
            // in this case vinderItem might be undefined
            if (!vinderItem) {
                return;
            }

            // for Trio AIS returns raceNumber = 0 for all the races.
            // So we take the race numbers from Vinder.

            const turnover = race[poolTurnoverKey] || race.turnover;

            turnovers.push([
                vinderItem.raceNumber,
                turnover ? turnover.sum : 0,
            ]);
        });

        return turnovers;
    };

    /**
     * Determines if the product pool is a part
     * of a merged pool for the certain race
     * @param  {string}  productId
     * @param  {number}  raceNumber
     * @return {boolean}
     */
    isPartOfMergedPool = (productId, raceNumber) => {
        if (!this._mergedPoolsRaw || !Array.isArray(this._mergedPoolsRaw)) {
            return false;
        }
        return this._mergedPoolsRaw.some(({ betTypes, races }) => {
            const productIds = betTypes.map(({ code }) => code);
            return productIds.includes(productId) && races.includes(raceNumber);
        });
    };

    /**
     * @param   {string} productId
     * @param   {Error}  e
     * @private
     */
    _logProductPoolError = (productId, e) => {
        console.error(
            `PoolOverview.ProductPool deserializing error: ${e}. 
                     Check if product ${productId} properly configured 
                     in configs/products.js. It must be present in 
                     POOL_OBJECT_KEYS config or V_GAMES if it is a 
                     V product type.`
        );
    };
}

class MergedPool {
    /**
     * Product ids
     * @type {string[]}
     */
    products = [];

    /**
     * Keys are race numbers, keys are amounts
     * @type {Object.<number>}
     */
    turnovers = {};

    /**
     * Race numbers included into the merged pool
     * @type {number[]}
     */
    races = [];

    /**
     * @param {string[]}        products
     * @param {Object.<number>} turnovers
     * @param {number[]}        races
     */
    constructor(products, turnovers, races) {
        this.products = products;
        this.turnovers = turnovers;
        this.races = races;
    }

    /**
     * @param  {string[]} productIds
     * @return {string[]}
     */
    static sort = (productIds) =>
        productIds.sort((a, b) => {
            const indexA = MERGED_POOLS_ORDER.indexOf(a);
            const indexB = MERGED_POOLS_ORDER.indexOf(b);
            if (indexA === -1 || indexB === -1) {
                return 0;
            }
            return indexA < indexB ? -1 : 1;
        });
}

class ProductPool {
    /**
     * @type {string|array|null}
     */
    productId = null;

    /**
     * Keys are race numbers, keys are amounts
     * @type {Object.<number>}
     */
    turnovers = {};

    /**
     * For V games only
     * @type {number}
     */
    allRacesPoolAmount = 0;

    /**
     * @type {boolean}
     */
    isVGame = false;

    constructor(productId) {
        this.productId = productId;
    }

    /**
     * @param  {number} raceNr
     * @return {number}
     */
    getAmount = (raceNr) => {
        if (this.isVGame) {
            return this.allRacesPoolAmount;
        } else {
            return this.turnovers[raceNr];
        }
    };
}

export { PoolOverview, ProductPool, MergedPool };
