import { ProductId } from 'features/EventBoard/server/calendar';
import { STRICT_V_GAMES, V_GAMES } from 'configs/products';

export default interface Product {
    id: ProductId;
    disabled: boolean;
    isMultitrack: boolean;
}

export const defaultProduct: Product = {
    id: 'V',
    disabled: false,
    isMultitrack: false,
};

export function isVProduct(productId: ProductId) {
    return V_GAMES.some((vProductId) => vProductId === productId);
}

export function isStrictVProduct(productId: ProductId) {
    return STRICT_V_GAMES.some((vProductId) => vProductId === productId);
}
