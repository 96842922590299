import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { byPlatform } from 'utils/platforms';
import useRaceDayState from 'features/EventBoard/hooks/useRaceDayState';

import { COLORS as THEME_COLORS } from 'features/EventBoard/theme';
import { CountryImage, getCountryImage } from 'features/EventBoard/components/TrackNameWithCountry';
import useShortcutState from 'features/EventBoard/hooks/useShortcutState';

import { SET_RACE_DAY_SELECTED, setProduct } from 'features/AISDataProvider/actions';

import Fa from 'ui/Fa';
import Text from 'ui/Text';
import { fadeInMixin } from 'ui/animations/FadeIn2';
import { history, isDirectEventLink } from 'utils/navigation';
import { CloseButtonArea } from 'ui/Popup';

import { COLORS } from 'themes';
import { DEFAULT_SELECTED_PRODUCT } from 'configs/products';

import { NEW_HOMEPAGE_UI, NEW_TRACK_PAGE } from 'configs/main';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

//@TODO: Remove when we completely move to NEW_TRACK_PAGE
import { getRaceDay } from 'common/selectors/raceDaySelector';
import { getMultitrackTrackNames } from 'common/selectors/trackSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import { pageContainerWidth } from '../../../configs/layout';
import useCalendarDate from '../hooks/useCalendarDate';
import useCalendarQuery from '../hooks/useCalendarQuery';

const mobileFlagWidth = '17px';
const desktopFlagWidth = '20px';

const TrackBarBackground = styled.div`
    background: ${THEME_COLORS.trackBarBg};
    display: flex;
    justify-content: center;
`;

const TrackBarContainer = styled.nav`
    height: ${byPlatform('65px', '75px')};
    width: 100%;
    color: ${COLORS.white};
    font-size: 26px;
    font-weight: 900;
    ${fadeInMixin};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: ${pageContainerWidth};
`;

const BackButtonArea = styled(CloseButtonArea)`
    left: 25px;
    top: unset;
    & > i {
        display: flex;
    }
`;

const TrackNameArea = styled.div`
    position: relative;
    left: -${byPlatform(mobileFlagWidth, desktopFlagWidth)};
`;

const SecondTrackName = styled.span`
    font-size: 14px;
`;

const TrackBar = () => {
    const {
        raceDay: raceDayProp,
        isMultitrack: isMultitrackProp,
        trackNames,
    } = useTrackPage({
        fetchRaces: NEW_TRACK_PAGE,
    });

    const { date, isToday: todaysDateSelected } = useCalendarDate();
    const { refetchCalendar } = useCalendarQuery({ date });

    //@TODO: Remove when we completely move to NEW_TRACK_PAGE
    const legacyRaceDay = useSelector(getRaceDay);
    const legacyIsMultitrack = useSelector(isMultitrackProduct);
    const legacyTrackNames = useSelector(getMultitrackTrackNames);

    const raceDay = NEW_TRACK_PAGE ? raceDayProp : legacyRaceDay;
    const isMultitrack = NEW_TRACK_PAGE ? isMultitrackProp : legacyIsMultitrack;
    const mainTrackName = raceDay?.trackName;
    const [firstTrackName, secondTrackName] = NEW_TRACK_PAGE ? trackNames : legacyTrackNames;

    const dispatch = useDispatch();

    const { setRaceDay, raceDayFound: legacyRaceDayFound } = useRaceDayState();
    const { setShortcut } = useShortcutState();

    const goBack = () => {
        //@TODO: Remove when we completely move to NEW_TRACK_PAGE
        if (!NEW_TRACK_PAGE) {
            setRaceDay(null);
            setShortcut(null);
            dispatch({ type: SET_RACE_DAY_SELECTED, payload: false });
            dispatch(setProduct(DEFAULT_SELECTED_PRODUCT));

            if (todaysDateSelected) {
                // sometimes post time of the races might be updated. This additional request needed in order
                // to prevent different post times / timers between track page and the homepage.
                refetchCalendar();
            }
        }

        history.push('/');
    };

    const isHomepageLegacyReduxBasedCheck = !NEW_TRACK_PAGE && !legacyRaceDayFound;
    const isHomepageURLBasedCheck = !isDirectEventLink();

    const isHomepage = NEW_TRACK_PAGE ? isHomepageURLBasedCheck : isHomepageLegacyReduxBasedCheck;

    //@TODO: Remove !isHomePage check once we completely move to NEW_TRACK_PAGE flag state.
    // Old layout uses TrackBar in <Desktop/> and <Mobile/> layouts, but new track page uses it as children
    if (isHomepage || !NEW_HOMEPAGE_UI) {
        return null;
    }

    const countryCode = raceDay?.country?.code;

    return (
        <TrackBarBackground>
            <TrackBarContainer>
                <BackButtonArea onClick={goBack}>
                    <Fa color={'white'} size={'x1'} section={'solid'} svgName={'angle-left'} />
                    {byPlatform(null, <Text color={'white'}>Oversigt</Text>)}
                </BackButtonArea>

                <TrackNameArea>
                    {countryCode && (
                        <CountryImage
                            src={raceDay ? getCountryImage(countryCode) : ''}
                            height={byPlatform(mobileFlagWidth, desktopFlagWidth)}
                        />
                    )}

                    {isMultitrack ? (
                        <>
                            {mainTrackName}{' '}
                            {secondTrackName ? (
                                <SecondTrackName>
                                    (
                                    {mainTrackName === firstTrackName
                                        ? secondTrackName
                                        : firstTrackName}
                                    )
                                </SecondTrackName>
                            ) : null}
                        </>
                    ) : (
                        raceDay?.trackName
                    )}
                </TrackNameArea>
            </TrackBarContainer>
        </TrackBarBackground>
    );
};

export default TrackBar;
