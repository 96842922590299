import React from 'react';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { RaceCardHeaderContainer, HeaderCol, JackpotLabel } from './styled';
import RaceInfo from './RaceInfo';
import { thousandsDots } from 'utils/formatters';
import Countdown from 'ui/Countdown';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';

const RaceCardHeader = () => {
    const { raceDay, product, productPool, isVProduct, race, racesFetched } = useTrackPage();
    const showJackpot = isVProduct && productPool?.jackpot?.sum > 0;

    const { serverTime } = useServerTimeQuery();

    const startDateTime = raceDay.raceDayDate + ' ' + race.postTime;
    const startDateTimeUTC = raceDay.raceDayDate + ' ' + race.postTimeUTC;

    return (
        <RaceCardHeaderContainer className="race-header">
            <HeaderCol className="flex">
                {product.name}{' '}
                {showJackpot && (
                    <JackpotLabel className="flex">
                        Jackpot overført: {thousandsDots(productPool.jackpot.sum)} kr.
                    </JackpotLabel>
                )}
            </HeaderCol>
            <HeaderCol>
                <RaceInfo />
            </HeaderCol>
            <HeaderCol>
                <time>
                    <b className="text-bold">
                        {!racesFetched || race.postTimeUTC === null ? (
                            '--:--:--'
                        ) : (
                            <Countdown
                                time={startDateTime}
                                timeUTC={startDateTimeUTC}
                                serverTime={serverTime}
                                withDay
                            />
                        )}
                    </b>
                </time>
            </HeaderCol>
        </RaceCardHeaderContainer>
    );
};

export default RaceCardHeader;
