import React, { ChangeEvent, useRef, useState } from 'react';
import { UploadHandler } from './types';
import { createNullParseXMLDataResponse, parseXMLData } from './server';
import getTexts from '../../utils/localization';

const t = getTexts();

const useUpload = () => {
    const fileInputRef = useRef<HTMLInputElement>(
        Object.create(HTMLElement.prototype, {})
    );

    const [fileData, setFileData] = useState(createNullParseXMLDataResponse());
    const [fileName, setFileName] = useState('');
    const [uploadPending, setUploadPending] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [error, setError] = useState('');

    const openFileBrowser = () => {
        fileInputRef.current.click();
    };

    const upload: UploadHandler = (files) => {
        const file = files[0];
        if (!checkFileSizeMB(file, 2)) {
            return setError('Maksimal filstørrelse er 2 MB');
        }
        setUploadPending(true);
        parseXMLData(files[0])
            .then((res) => {
                if (res.statusCode === 200) {
                    setUploadPending(false);
                    setFileData(res.data);
                    setUploadSuccess(true);
                    setFileName(file.name);
                } else {
                    setUploadPending(false);
                    setUploadSuccess(false);
                    // response messages from res.errorMessage are currently ignored
                    // @see https://ecosys.atlassian.net/browse/DER-2235?focusedCommentId=22816
                    setError(t.batchBetting.wrongFormatError);
                }
            })
            .catch((e) => {
                setUploadPending(false);
                setError(t.unhandledError);
                console.error(e);
            });
    };

    const checkFileSizeMB = (file: File, max: number) =>
        Math.round(file.size / 1024 / 1024) <= max;

    const onFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null) {
            upload(e.target.files);
        }
    };

    const FileInput = () => (
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onFileInputChange}
        />
    );

    const reset = () => {
        setFileData(createNullParseXMLDataResponse());
        setFileName('');
        setUploadSuccess(false);
        setError('');
    };

    return {
        FileInput,
        fileInputRef,
        openFileBrowser,
        upload,
        uploadPending,
        uploadSuccess,
        fileData,
        fileName,
        reset,
        error,
    };
};

export default useUpload;
