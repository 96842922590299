import React, { useState } from 'react';
import moment from 'moment';
import UserSessionAdapter from 'common/adapters/UserSession';
import { getTranslatedErrorMessage } from 'utils/betting-service';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { BET_API_MARKS_STRING_LENGTH } from 'configs/products';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import racesSelector, { getLegNumbers } from 'common/selectors/racesSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import { performQuickPickBet as performQuickBet } from 'features/BetSlip/state/actions';
import { getLockedLegs } from 'features/MobileBet/selectors/lockedLegsSelector';
import { getSmartLynBet } from 'common/selectors/smartLynSelector';
import useTrackPage from '../TrackPage/hooks/useTrackPage';
import betSlipSelector from 'common/selectors/betSlipSelector';
import SmartLynBet from 'common/DataObjects/SmartLynBet';
import { NEW_TRACK_PAGE } from 'configs/main';
import useAuth from 'common/hooks/useAuth';

const withQuickPickBetting = WrappedComponent => {
    return props => {
        const { noSmartLyn, isUserLoggedIn, track } = props;
        const [pending, setPending] = useState(false);
        const [amount, setAmount] = useState(props.amount || 50);
        // locked legs
        const racesLegacy = useSelector(racesSelector, shallowEqual);
        const isMultitrackLegacy = useSelector(isMultitrackProduct);

        const {
            raceDay,
            product,
            productId,
            isMultitrack,
            races,
            product: trackPageProduct,
        } = useTrackPage();

        // selectors
        const betSlip = useSelector(betSlipSelector);

        const auth = useAuth();

        const smartLynBet = NEW_TRACK_PAGE
            ? SmartLynBet.unserialize(
                  raceDay.raceDayDate,
                  product,
                  raceDay.track.code,
                  races,
                  betSlip,
                  isMultitrack ? raceDay.multipleTrackPoolSetups[productId] : null
              )
            : useSelector(getSmartLynBet);

        const selectedLegNumbers = Object.entries(smartLynBet.selections)
            .filter(([, selections]) => Object.keys(selections).length !== 0)
            .map(([legNr]) => legNr);

        const legNumbers = useSelector(getLegNumbers, shallowEqual);
        const mobileBetData = useSelector(getLockedLegs);
        const lockedLegDefaults = mobileBetData.mobileBetInit
            ? mobileBetData.lockedLegs
            : legNumbers.filter(lnr => !selectedLegNumbers.includes(lnr));

        const [lockedLegs, setLockedLegs] = useState(lockedLegDefaults);
        const hasUnselectedLegs = selectedLegNumbers.length < racesLegacy.length;
        smartLynBet.locked = lockedLegs;

        const toggleLegStatus = legNr =>
            lockedLegs.includes(legNr)
                ? setLockedLegs(lockedLegs.filter(lnr => lnr !== legNr))
                : setLockedLegs([...lockedLegs, legNr]);

        const createSmartLynLegs = () => {
            return smartLynBet.raceNumbers.map((raceNumber, index) => {
                const apiMarksStringLength = BET_API_MARKS_STRING_LENGTH[smartLynBet.product.id];
                let marks = '';

                if (smartLynBet.selections[index]) {
                    for (let i = 0; i < apiMarksStringLength; i++) {
                        marks += '' + (smartLynBet.selections[index][i + 1] ? 1 : 0);
                    }
                } else {
                    for (let i = 0; i < apiMarksStringLength; i++) {
                        marks += '0';
                    }
                }

                return {
                    legNumber: index + 1,
                    marks,
                    open:
                        smartLynBet.selections[index] &&
                        Object.keys(smartLynBet.selections[index])?.length
                            ? !lockedLegs.includes(raceNumber)
                            : true,
                };
            });
        };

        const dispatch = useDispatch();
        const performQuickPickBet = async () => {
            setPending(true);

            const product = NEW_TRACK_PAGE ? trackPageProduct : props.product;
            const date = NEW_TRACK_PAGE ? raceDay.raceDayDate : props.date;
            const trackId = NEW_TRACK_PAGE ? raceDay.trackId : props.track.id;
            const programNumber = NEW_TRACK_PAGE ? raceDay.programNumber : props.programNumber;
            const selectedRaceDay = NEW_TRACK_PAGE ? raceDay : props.selectedRaceDay;

            const { smartLynMode } = props;
            const params = {
                raceDay: date.substring(0, 10),
                trackCode:
                    smartLynMode && isMultitrackLegacy ? smartLynBet.multitrack.trackId : trackId,
                maxTotalBetAmount: amount,
                programNumber:
                    smartLynMode && isMultitrackLegacy
                        ? smartLynBet.multitrack.programNumber
                        : programNumber,
            };

            let postTime = moment(date).format('YYYY-MM-DD HH:mm');

            if (smartLynMode && smartLynBet && !noSmartLyn) {
                params.smartLyn = true;
                params.legs = createSmartLynLegs(props);
                postTime = selectedRaceDay.firstRacePostTime;
            }

            const bettingData = UserSessionAdapter.getPerformSaleData(
                params,
                auth.user.id,
                null,
                null,
                postTime
            );

            try {
                const res = await dispatch(performQuickBet(bettingData, product));

                if (res.success) {
                    UserSessionAdapter.performUserUpdate(() => {}, props.updateUserInfo, res);

                    props.onBetPerformed(res, { product, raceDay, smartLynMode, track });
                    isUserLoggedIn && isUserLoggedIn();
                } else {
                    setPending(false);

                    const error = getTranslatedErrorMessage(res);
                    NotificationConductor.error(error);
                }
            } catch (error) {
                setPending(false);

                NotificationConductor.error(error.message);
                console.error(error);
            }
        };

        return (
            <WrappedComponent
                {...props}
                performQuickPickBet={performQuickPickBet}
                setQuickPickAmount={setAmount}
                pending={pending}
                quickPickBetPending={pending}
                lockedLegs={lockedLegs}
                toggleLegStatus={toggleLegStatus}
                allLegsLocked={
                    hasUnselectedLegs ? false : selectedLegNumbers.length === lockedLegs.length
                }
                createSmartLynLegs={createSmartLynLegs}
            />
        );
    };
};

export default withQuickPickBetting;
