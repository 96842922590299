import 'console-polyfill';
import 'babel-polyfill';
import 'react-app-polyfill/stable';
import 'core-js';
import { registerObserver } from 'react-perf-devtool';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import { unregister } from './registerServiceWorker';
import { Provider } from 'react-redux';
import store from './common/store';
import './defineGlobals';
import UserConductor from 'common/conductors/UserConductor';
//import 'core-js/modules/es.object.from-entries';

// import { createLogger } from 'redux-logger';
// const logger = createLogger();

// @TODO make a unvisible app if it's deposit redirect
// const search = new URLSearchParams(window.location.search);
//
// if (
//     window.self !== window.top &&
//     (search.get('deposit-error') || search.get('deposit-success'))
// ) {
//     const rootView = window.document.getElementById('root');
//     const rootModalView = window.document.getElementById('modal-root');
//
//     rootView.style.visibility = 'hidden';
//     rootModalView.style.visibility = 'hidden';
//
//     window.document.getElementById('app-loader').style.display = 'flex';
// }

window.observer = registerObserver();

// Sentry.init({
//     dsn:
//         'https://40b065c68943439bb6c764c748708c21@o4504841932570624.ingest.sentry.io/4504841934536704',
// });
//
// Sentry.setContext('env', {
//     platform: getPlatform(),
//     hostname: window.location.hostname,
// });

const root = createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

//registerServiceWorker();

window.login = (username, password) => {
    UserConductor.login(username, password)
        .then((res) => {
            if (res.id) {
                window.location.reload();
            } else {
                console.log(res.errorMessage);
            }
        })
        .catch((err) => {
            console.log(err.errorMessage);
        });
};

unregister();
