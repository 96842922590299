import React from 'react';
import { Col, Row } from 'react-grid-system';
import Text from 'ui/Text';
import { numberWithCommasAndDots } from 'utils/formatters';
import { ParseXMLDataResponse } from '../server';

const SystemDataTable = ({ fileData }: { fileData: ParseXMLDataResponse }) => {
    return (
        <>
            <Row>
                <Col sm={2}>
                    <Text bold black>
                        Bane
                    </Text>
                </Col>
                <Col sm={4}>
                    <Text black>{fileData.total.trackName}</Text>
                </Col>
                <Col sm={2}>
                    <Text bold black>
                        Systemer
                    </Text>
                </Col>
                <Col sm={4}>
                    <Text black>{fileData.total.numberOfSystems} st.</Text>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Text bold black>
                        Dato
                    </Text>
                </Col>
                <Col sm={4}>
                    <Text black>{fileData.total.startTime}</Text>
                </Col>
                <Col sm={2}>
                    <Text bold black>
                        Indsats
                    </Text>
                </Col>
                <Col sm={4}>
                    <Text black>{numberWithCommasAndDots(fileData.total.price)} kr.</Text>
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <Text bold black>
                        Kuponer
                    </Text>
                </Col>
                <Col sm={4}>
                    <Text black>{fileData.total.totalCoupons} stk.</Text>
                </Col>
                <Col sm={2} />
                <Col sm={4} />
            </Row>
        </>
    );
};

export default SystemDataTable;
