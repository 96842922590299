import React, { Component } from 'react';
import { FilledWrapper } from 'ui/Wrapper';
import VRaceCardHeader from 'features/RacesCarusel/VRaceOverview/VRaceCardHeader';
import VRaceSubHeader from 'features/RacesCarusel/VRaceOverview/VRaceSubHeader';
import styled from 'styled-components';
import VGameHeader from 'features/RacesCarusel/VRaceOverview/VGameHeader';
import VGameCard from 'features/RacesCarusel/VRaceOverview/VGameCard';
import { connect } from 'react-redux';
import { addBet, removeBet, resetBet } from 'features/BetSlip/state/actions';
import betSlipSelector from 'common/selectors/betSlipSelector';
import { boxShadowMixin } from 'ui/Card';
import { fadeInMixin } from 'ui/animations/FadeIn2';
import productSelector from 'common/selectors/productSelector';
import { getSelectedDate } from 'common/selectors/raceDaySelector';
import { isCurrentRaceResulted } from '../../../common/selectors/racesSelector';

const StyledWrapper = styled(FilledWrapper).attrs({ white: true })`
    ${boxShadowMixin};
    ${fadeInMixin};
    position: relative;
    z-index: 15;
    border-radius: 10px;
    overflow: hidden;
`;
class VRaceOverview extends Component {
    constructor() {
        super();

        this.state = {
            raceStarted: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.races[0].raceNumber !== prevProps.races[0].raceNumber ||
            this.props.races[0].trackCode !== prevProps.races[0].trackCode
        ) {
            this.setState({ raceStarted: false });
        }
    }

    raceFinishedHandler = () => {
        this.setState({ raceStarted: true });
    };

    addBet = (race, startNr, betValue, trackCode = null, isBetbuddy = false) => {
        const { selectedProduct, selectedDate, addBet } = this.props;
        addBet(
            selectedDate,
            trackCode,
            selectedProduct.id,
            race.index,
            startNr,
            betValue,
            isBetbuddy
        );
    };
    removeBet = (race, startNr, betValue) => {
        const { selectedProduct, selectedDate, trackCode, removeBet } = this.props;

        removeBet(selectedDate, trackCode, selectedProduct.id, race.index, startNr, betValue);
    };

    render() {
        const startDateTime = this.props.selectedDate + ' ' + this.props.races[0].postTime;
        const startDateTimeUTC = this.props.selectedDate + ' ' + this.props.races[0].postTimeUTC;

        if (this.state.raceStarted) {
            return null;
        }

        return (
            <StyledWrapper padding="0" margin="20px 20px 40px" animationName="vRaceOverviewFadeIn">
                <VRaceCardHeader
                    from={startDateTime}
                    race={this.props.races[0]}
                    postTimeUTC={startDateTimeUTC}
                    product={this.props.selectedProduct}
                    onRaceFinished={this.raceFinishedHandler}
                    hasResult={this.props.hasResult}
                />
                <VRaceSubHeader
                    reserveModeEnabled={this.props.reserveModeEnabled}
                    toggleReserveMode={this.props.setReserveMode}
                    raceCardData={{ ...this.props }}
                />
                <VGameHeader />
                <VGameCard
                    reserveModeEnabled={this.props.reserveModeEnabled}
                    raceCardData={{ ...this.props }}
                    onBetAdded={this.addBet}
                    onBetRemoved={this.removeBet}
                    raceStarted={this.state.raceStarted}
                    maximumRaceLength={this.props.maximumRaceLength}
                />
            </StyledWrapper>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    betSlip: betSlipSelector(state),
    selectedBets: state.BetSlip.selectedBets,
    selectedProduct: productSelector(state),
    selectedDate: getSelectedDate(state),
    maximumRaceLength: ownProps.races
        .map(race => race.starts.length)
        .reduce((prevLength, currLength) => (currLength > prevLength ? currLength : prevLength), 0),
    hasResult: isCurrentRaceResulted(state),
});

const mapDispatchToProps = dispatch => {
    return {
        addBet: (date, trackId, productId, raceNr, startNr, betValue) => {
            dispatch(addBet(date, trackId, productId, raceNr, startNr, betValue));
        },
        removeBet: (date, trackId, productId, raceNr, startNr, betValue) => {
            dispatch(removeBet(date, trackId, productId, raceNr, startNr, betValue));
        },
        resetBet: () => {
            dispatch(resetBet());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VRaceOverview);
