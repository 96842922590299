import React, { Component } from 'react';
import Button from 'ui/controls/Button';
import styled from 'styled-components';
import { CMSPdf, BlobPdf } from 'features/RaceCard/PdfDownload';
import Wrapper from 'ui/Wrapper';
import { getIn } from 'immutable';
import { byPlatform, forMobile } from 'utils/platforms';
import Spinner from 'ui/Spinner';
import TrioMassSelectionHOC from 'features/RaceCard/TrioMassSelectionHOC';
import { BACKGROUNDS, COLORS } from 'themes/index';
import {
    resetSmartLyn,
    setDerbyLynBet,
    setSmartLynBet,
    setSmartLynMode,
} from 'features/BetSlip/state/actions';
import { connect } from 'react-redux';
import smartLynActiveIcon from 'features/BetSlip/components/SmartLyn/assets/smart_lyn_a.png';
import betSlipSelector from 'common/selectors/betSlipSelector';
import { getMultitrackCodes, getTrackFromRacingCard } from 'common/selectors/trackSelector';
import raceDaySelector from 'common/selectors/raceDaySelector';
import { showModal } from 'common/actions/uiActions';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import getTexts from 'utils/localization';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import { FadeInSpan } from 'ui/animations/FadeIn2';
import { BetPickBtn } from 'features/TrackPage/components/RaceCard/common/PickButtons.styled';
const t = getTexts();

const StyledContainer = styled(Wrapper)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 10px;
    padding: ${byPlatform('2px', '2px', '6px')} 0;
    margin-left: 20px;
    margin-right: 20px;
`;

const StyledButton = styled(Button)`
    border-radius: 5px;
    margin-right: 5px;
    width: 100px;
    height: ${byPlatform('auto', 'auto', '45px')};
    font-size: ${byPlatform('10px', '10px', '12px')};
    font-weight: 900;
    cursor: pointer;
    line-height: 14px;
    border: ${props =>
        props.specialRed ? `0.5px solid ${COLORS.special.red}` : '0.5px solid #c6ccdb'};
    transition: background 300ms ease;
    &:hover {
        background: ${props =>
            props.noHover ? '' : props.specialRed ? COLORS.special.red : '#c6ccdb'};
    }
`;

const ReserveButton = styled(StyledButton)`
    ${({ reserveMode }) =>
        reserveMode &&
        `
        background: ${BACKGROUNDS.blue};
        border: 0;
        color: ${COLORS.white};
        &:hover {
            background: ${BACKGROUNDS.blue};
        }
    `};
`;

const PdfDownloadSection = styled(Wrapper)`
    display: flex;
    padding: 0;
`;

const TrioButtons = styled.div`
    display: flex;
    flex-grow: ${byPlatform('0.1', '0.08', '0.18')};
    flex-flow: row;
    justify-content: flex-start;
    position: relative;
    height: 100%;
`;

const SpecialBtn = styled(Button)`
    padding: 6px 15px;
    text-align: center;
    margin: 3px;
    border-radius: 12px;
    margin-left: 12px;
    ${props => `background: ${props.active ? BACKGROUNDS.secondary : COLORS.secondary};`};
`;

const TrioSelectAllBtn = styled(BetPickBtn)`
    margin-right: 10px;
`;

@TrioMassSelectionHOC
class RaceCardSubheader extends Component {
    constructor() {
        super();

        this.state = {
            statLoadingInProgress: false,
            buttonContent: t.RacingCard.controlArea.statistic,
        };
    }

    toggleStartsSelecting = () => {
        const {
            race,
            raceStarted,
            selectedProduct,
            selectedDate,
            trackCode,
            addAllBets,
            addBet,
            selectedBets,
            resetRaceBet,
        } = this.props.raceCardData;

        if (raceStarted) return;

        let startsAlreadyChoosen = getIn(
            selectedBets,
            [trackCode + selectedProduct.id + race.index],
            false
        );
        if (startsAlreadyChoosen) {
            resetRaceBet &&
                resetRaceBet(
                    selectedDate,
                    trackCode,
                    selectedProduct.id,
                    race.index,
                    this.props.betBuddySelectionsMode
                );
        } else {
            let selectedStarts = {},
                betsByDates = {},
                starts = race.starts;
            //TODO remove loop, add new action which will handle array
            starts.forEach((start, index) => {
                if (start.isScratched()) return;

                if (selectedProduct.isKomb()) {
                    addBet(
                        selectedDate,
                        trackCode,
                        selectedProduct.id,
                        race.index,
                        start.startNr,
                        1,
                        this.props.betBuddySelectionsMode
                    );
                    addBet(
                        selectedDate,
                        trackCode,
                        selectedProduct.id,
                        race.index,
                        start.startNr,
                        2
                    );
                } else {
                    addBet(
                        selectedDate,
                        trackCode,
                        selectedProduct.id,
                        race.index,
                        start.startNr,
                        index + 1
                    );
                }

                selectedStarts[start.startNr] = this.getBetValueArr(
                    selectedProduct.id,
                    start.startNr,
                    this.props.selections
                );
            });

            betsByDates = { [race.index]: selectedStarts };
            addAllBets(selectedDate, trackCode, selectedProduct.id, race.index, betsByDates);
        }
    };

    getBetValueArr = (productId, startNr, existingSelections) => {
        let betValues = {
            TV: [
                startNr,
                // preserve U horses
                existingSelections.getUHorseNumbers().includes(startNr) ? 'U' : undefined,
            ].filter(item => item !== undefined),
            T: [1, 2, 3],
            K: [1, 2],
        };

        return typeof betValues[productId] === 'object' ? betValues[productId] : [startNr];
    };

    clearStartsSelecting = () => {
        this.props.raceCardData.resetBet();
    };

    onExpandAllStatsHandler = () => {
        if (!this.state.statLoadingInProgress) {
            this.setState(
                {
                    statLoadingInProgress: true,
                    buttonContent: <Spinner size="x04" />,
                },
                () => {
                    this.props.onExpandAllStatsHandler(() => {
                        this.setState({
                            statLoadingInProgress: false,
                            buttonContent: t.RacingCard.controlArea.statistic,
                        });
                    });
                }
            );
        }
    };

    componentDidUpdate(prevProps) {
        if (
            prevProps.allStatsExpanded !== this.props.allStatsExpanded &&
            !this.props.allStatsExpanded
        ) {
            this.setState({
                statLoadingInProgress: false,
                buttonContent: t.RacingCard.controlArea.statistic,
            });
        }
    }

    render() {
        const { selectedBets, race, trackCode, selectedProduct } = this.props.raceCardData;

        const {
            trio1stRowSelected,
            trio2ndRowSelected,
            trio3rdRowSelected,
            toggleTrio1stRow,
            toggleTrio2ndRow,
            toggleTrio3rdRow,
            toggleReserveMode,
            reserveModeEnabled,
            combinationsCount,
            allStatsExpanded,
        } = this.props;

        const allPicked = getIn(selectedBets, [trackCode + selectedProduct.id + race.index], false);

        const chooseAllButtonText = allPicked
            ? t.RacingCard.controlArea.unselectAll
            : t.RacingCard.controlArea.selectAll;
        return (
            <StyledContainer>
                <div className="flex align-center">
                    {race.saleOpen ? (
                        selectedProduct.isTrio() ? (
                            <TrioButtons>
                                <TrioSelectAllBtn
                                    betButtonStyles={{}}
                                    active={trio1stRowSelected}
                                    grey={!trio1stRowSelected}
                                    onClick={toggleTrio1stRow}
                                    style={{
                                        marginLeft: byPlatform('35px', '0', '40px'),
                                    }}
                                >
                                    1
                                </TrioSelectAllBtn>
                                <TrioSelectAllBtn
                                    betButtonStyles={{}}
                                    active={trio2ndRowSelected}
                                    grey={!trio2ndRowSelected}
                                    onClick={toggleTrio2ndRow}
                                >
                                    2
                                </TrioSelectAllBtn>
                                <TrioSelectAllBtn
                                    betButtonStyles={{}}
                                    active={trio3rdRowSelected}
                                    grey={!trio3rdRowSelected}
                                    onClick={toggleTrio3rdRow}
                                >
                                    3
                                </TrioSelectAllBtn>
                            </TrioButtons>
                        ) : (
                            <StyledButton
                                onClick={this.toggleStartsSelecting}
                                grey={!allPicked}
                                noHover={allPicked}
                            >
                                {chooseAllButtonText}
                            </StyledButton>
                        )
                    ) : null}
                    <StyledButton
                        grey={!allStatsExpanded}
                        noHover={allStatsExpanded}
                        onClick={this.onExpandAllStatsHandler}
                        disabled={this.state.statLoadingInProgress}
                    >
                        {this.state.buttonContent}
                    </StyledButton>
                    {combinationsCount > 0 && (
                        <FadeInSpan>
                            <StyledButton onClick={this.clearStartsSelecting} specialRed>
                                {t.RacingCard.controlArea.clearAll}
                            </StyledButton>
                        </FadeInSpan>
                    )}
                    {selectedProduct.canManuallyReserve() && (
                        <ReserveButton
                            onClick={toggleReserveMode}
                            reserveMode={reserveModeEnabled}
                            grey
                        >
                            Reserver
                        </ReserveButton>
                    )}

                    {selectedProduct.isStrictVProduct()
                        ? forMobile(
                              <SpecialBtn
                                  active={true}
                                  onClick={this.props.activateSmartLyn}
                                  size="x05"
                              >
                                  <img src={smartLynActiveIcon} alt="SL" height="16px" />
                              </SpecialBtn>
                          )
                        : null}
                </div>

                {byPlatform(
                    null,
                    <PdfDownloadSection>
                        <CMSPdf type="tips" />
                        <CMSPdf type="play-list" />
                        <BlobPdf />
                    </PdfDownloadSection>,
                    <span />
                )}
            </StyledContainer>
        );
    }
}

const mapStateToProps = state => ({
    smartLynMode: state.BetSlip.smartLynMode,
    betSlip: betSlipSelector(state),
    date: raceDaySelector(state).date,
    trackCode: getTrackFromRacingCard(state).code,
    selectedProduct: state.AISDataProvider.selectedProduct,
    selectedRaceDay: state.AISDataProvider.selectedRaceDay,
    derbyLynBet: state.BetSlip.derbyLynBet,
    races: state.AISDataProvider.racingCardData.races,
    auth: state.auth,
    serverTime: state.AISDataProvider.serverTime,
    isMultitrack: isMultitrackProduct(state),
    multitrackCodes: getMultitrackCodes(state),
    shopConfig: state.ShopConfig,
    performBetPending: state.BetSlip.performBetPending,
    betBuddySelectionsMode: state.BetSlip.betBuddySelectionsMode.status,
    combinationsCount: combinationsCountSelector(state),
});

const mapDispatchToProps = dispatch => {
    return {
        setSmartLynMode: enabled => {
            dispatch(setSmartLynMode(enabled));
        },
        resetSmartLyn: () => dispatch(resetSmartLyn()),
        showQuickPickAmountModal: data => {
            dispatch(showModal('QUICK_PICK_AMOUNT', -896, data));
        },
        setSmartLynBet: smartLynBet => {
            dispatch(setSmartLynBet(smartLynBet));
        },
        setDerbyLynBet: (date, trackCode, product) => {
            dispatch(setDerbyLynBet(date, trackCode, product));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RaceCardSubheader);
