import React from 'react';
import Text from 'ui/Text';
import { connect } from 'react-redux';
import { updateUserInfo } from 'common/actions/authActions';
import { showKeyboard } from 'ui/ScreenKeyboard/actions';
import { hideModal } from 'common/actions/uiActions';
import { IS_TERMINAL } from 'utils/platforms';
import betSlipSelector from 'common/selectors/betSlipSelector';
import { resetBet, resetSmartLyn } from 'features/BetSlip/state/actions';
import QuickPickAmount from 'features/QuickPick/QuickPickAmount/QuickPickAmount';
import { getSmartLynBet } from 'common/selectors/smartLynSelector';
import { MAX_DERBY_LYN_COUPON_AMOUNT, MIN_DERBY_LYN_COUPON_AMOUNT } from 'configs/products';

class QuickPickAmountContainer extends React.Component {
    validateAmount = (value) => {
        return value >= MIN_DERBY_LYN_COUPON_AMOUNT && value < MAX_DERBY_LYN_COUPON_AMOUNT;
    };

    constructor() {
        super();

        this.state = {
            amount: MIN_DERBY_LYN_COUPON_AMOUNT,
            amountValid: true,
        };
    }
    componentDidMount() {
        if (this.props.useDesktopModal === undefined) {
            this.props.enableCloseButton(
                <Text white>
                    <span className="triangle" />
                    Lynspil
                </Text>
            );
        } else {
            this.props.useDesktopModal();
            this.props.setBackground('rgba(0,0,0,0.7)');
        }
    }

    onFocusHandler = () => {
        /**TODO create decorator for method */
        if (IS_TERMINAL) {
            this.props.showKeyboard();
        }
    };

    componentDidUpdate(prevProps) {
        /**TODO create decorator for method */
        if (IS_TERMINAL) {
            let value = this.props.screenKeyboard.value;
            if (prevProps.screenKeyboard.value !== value && value !== '') {
                this.setState({
                    amount: value,
                    amountValid: this.validateAmount(value),
                });
            }
        }
    }

    onChangeHandler = (value) => {
        this.setState({
            amount: value.replace(/[^0-9]+/g, ''),
            amountValid: this.validateAmount(value),
        });
    };

    onCancelHandler = () => {
        //this.props.resetBet();
        this.props.hideModal('QUICK_PICK_AMOUNT');
    };

    setAmount = (amount) => {
        this.setState({ amount, amountValid: true });
    };

    switchModals = (response) => {
        if (!IS_TERMINAL) {
            this.props.showModal('QUICK_PICK_RECEIPT', -896, {
                receipt: response.data,
                modalAmountData: this.props.modalData,
                amount: this.state.amount,
            });
        }

        this.props.hideModal('QUICK_PICK_AMOUNT');
    };

    render() {
        const { product, track, date, programNumber } = this.props.modalData.item;

        const {
            useDesktopModal,
            auth,
            updateUserInfo,
            resetBet,
            smartLynBet,
            smartLynMode,
            resetSmartLyn,
            hideModal,
        } = this.props;

        const { amount, amountValid } = this.state;

        return (
            <QuickPickAmount
                amount={amount}
                amountValid={amountValid}
                product={product}
                track={track}
                programNumber={programNumber}
                date={date}
                useDesktopModal={useDesktopModal}
                onCancelHandler={this.onCancelHandler}
                onFocusHandler={this.onFocusHandler}
                setAmount={this.setAmount}
                onChangeHandler={this.onChangeHandler}
                validateAmount={this.validateAmount}
                auth={auth}
                resetBet={resetBet}
                updateUserInfo={updateUserInfo}
                onBetPerformed={this.switchModals}
                noSmartLyn={true}
                smartLynBet={smartLynBet}
                smartLynMode={smartLynMode}
                resetSmartLyn={resetSmartLyn}
                hideModal={hideModal}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        screenKeyboard: state.ScreenKeyboard,
        smartLynBet: getSmartLynBet(state),
        smartLynMode: state.BetSlip.smartLynMode,
        betSlip: betSlipSelector(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateUserInfo: (authData) => dispatch(updateUserInfo(authData)),
    showKeyboard: () => dispatch(showKeyboard()),
    resetBet: () => dispatch(resetBet()),
    hideModal: (modalId) => dispatch(hideModal(modalId)),
    resetSmartLyn: () => {
        dispatch(resetSmartLyn());
    },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuickPickAmountContainer);
