import React from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import { connect } from 'react-redux';
import { DEFAULT_SELECTED_PRODUCT } from 'configs/products';
import {
    setProduct,
    setProductShortcut,
    setRaceDay,
} from 'features/AISDataProvider/actions';
import {
    getMultitrackCouple,
    isMultitrackProduct,
} from 'common/selectors/multipleTrackSetupsSelector';
import Track from 'common/DataObjects/Track';
import {
    getTrackFromRacingCard,
    getTracksJackpotAvailabilityMap,
} from 'common/selectors/trackSelector';

import getTexts from 'utils/localization';
import * as RaceNavigation from 'ui/RaceNavigation';
import { byPlatform, forMobile, isMobile } from 'utils/platforms';
import {
    PreloaderButtons,
    RaceNextButton,
    RacePrevButton,
    JackpotLabel,
    jackpotLabelTopOffset,
    SlickSliderContainer,
} from 'ui/RaceNavigation';

import './slick.css';
import {
    getAllMultitrackSetupsByDate,
    getUniversalTrackId,
} from 'common/selectors/raceDaySelector';

const t = getTexts();

class TracksBySelectedDay extends React.Component {
    onSelectTrackhandler = (raceDay) => {
        const {
            isMultitrackProduct,
            selectedProduct,
            coupleTrack,
            racingCardPending,
            trackPoolPending,
            selectedRaceDay,
        } = this.props;

        if (racingCardPending || trackPoolPending) {
            return;
        }

        this.props.setRaceDay(raceDay);

        // in case of multi track product if the user switches between
        // tracks for the same multi track pool we have to choose
        // the same product for it automatically
        isMultitrackProduct &&
        (raceDay.trackId === coupleTrack.id ||
            raceDay.trackId === selectedRaceDay.trackId)
            ? this.props.setProduct(selectedProduct)
            : this.props.setProduct(DEFAULT_SELECTED_PRODUCT);
    };

    filterRaceDayByProduct(races, product) {
        return races
            ? races.filter(
                  (raceDay) =>
                      !this.props.productShortcut
                          ? true
                          : raceDay.products.find(
                                (raceDayProduct) =>
                                    raceDayProduct.id === product.id
                            )
              )
            : [];
    }

    getTracks = () => {
        const { raceDayData, selectedDate, selectedProduct } = this.props;

        let data = raceDayData;

        if (selectedProduct) {
            data = this.filterRaceDayByProduct(data, selectedProduct);
        }

        data = data
            .filter(
                (raceDay) =>
                    raceDay.date === moment(selectedDate).format('YYYY-MM-DD')
            )
            .sort(
                (a, b) =>
                    a.firstRacePostTime !== b.firstRacePostTime
                        ? a.firstRacePostTime < b.firstRacePostTime
                            ? -1
                            : 1
                        : a.trackCode > b.trackCode
                            ? -1
                            : 1
            );

        return data;
    };

    renderTrackList() {
        const {
            selectedRaceDay,
            racingCardPending,
            trackPoolPending,
            serverTime,
            defaultRaceDay,
            tracksJackpotAvailabilityMap,
            allTracksMultitrackSetupsEntries,
            coupleTrack,
        } = this.props;

        const tracksData = this.getTracks();

        return tracksData.map((raceDay, i) => {
            const track = raceDay.track;
            const universalTrackId = getUniversalTrackId(
                allTracksMultitrackSetupsEntries,
                track
            );
            const hasJackpot =
                tracksJackpotAvailabilityMap[universalTrackId] ||
                // additional check to consider jackpot for non-multitrack pools
                // of the tracks that actually also have multitrack pools
                tracksJackpotAvailabilityMap[track.id];
            const isActive =
                selectedRaceDay &&
                selectedRaceDay.trackId === raceDay.trackId &&
                defaultRaceDay;

            const countryImage =
                RaceNavigation.countryCodeMap[raceDay.country.code] ||
                RaceNavigation.countryCodeMap.Default;

            return (
                <RaceNavigation.ButtonWithSublabel
                    key={raceDay.trackId}
                    style={{ display: 'flex' }}
                >
                    {hasJackpot && <JackpotLabel>Jackpot</JackpotLabel>}

                    <RaceNavigation.TrackNavigationButton
                        disabled={racingCardPending || trackPoolPending}
                        active={isActive}
                        data-test-id={isActive ? 'active' : 'inactive'}
                        accent={raceDay.trackId === coupleTrack.id}
                        onClick={this.onSelectTrackhandler.bind(this, raceDay)}
                        style={{
                            padding: byPlatform('10 14px', '0 13px', '0 18px'),
                            // fixes Mob. Padding after the last button DER-2629 (padding-right doesn't work for mobile)
                            marginRight: forMobile(
                                i + 1 === tracksData.length ? '20px' : '5px'
                            ),
                            // react ignores 'padding' property here for mobile
                            paddingLeft: byPlatform('14px', '13px', '18px'),
                            paddingRight: byPlatform('14px', '13px', '18px'),
                            minWidth: 'auto',
                        }}
                    >
                        <RaceNavigation.CountryImage src={countryImage} />
                        {track.name}
                    </RaceNavigation.TrackNavigationButton>
                    <RaceNavigation.SubLabel>
                        {moment(raceDay.firstRacePostTime).utc() >
                        moment(serverTime).utc()
                            ? moment(raceDay.firstRacePostTime)
                                  .utc()
                                  .from(moment(serverTime).utc())
                            : t.RacingCard.ongoing}
                    </RaceNavigation.SubLabel>
                </RaceNavigation.ButtonWithSublabel>
            );
        });
    }

    renderPreloader = () => <PreloaderButtons count={4} width="100px" />;

    render() {
        if (this.props.loading) {
            return this.renderPreloader();
        }

        const tracks = this.getTracks();

        return (
            <Container numberOfTracks={tracks.length}>
                {this.renderTrackList()}
            </Container>
        );
    }
}

const Container = ({ children, numberOfTracks }) => {
    const sliderArrowsStyle = {
        marginTop: byPlatform('0', jackpotLabelTopOffset),
    };

    const sliderSettings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 2,
        variableWidth: true,
        prevArrow: <RacePrevButton styles={sliderArrowsStyle} />,
        nextArrow: <RaceNextButton styles={sliderArrowsStyle} />,
    };

    return numberOfTracks > 6 && !isMobile ? (
        <SlickSliderContainer className="homepage-tracks">
            <Slider {...sliderSettings}>{children}</Slider>
        </SlickSliderContainer>
    ) : (
        <RaceNavigation.TracksScrollBar>
            {children}
        </RaceNavigation.TracksScrollBar>
    );
};

const mapStateToProps = (state) => ({
    isMultitrackProduct: isMultitrackProduct(state),
    coupleTrack: isMultitrackProduct(state)
        ? getMultitrackCouple(state)
        : Track.getNullObject(),
    allTracksMultitrackSetupsEntries: getAllMultitrackSetupsByDate(state),
    track: getTrackFromRacingCard(state),
    selectedDate: state.DatePicker.date,
    selectedProduct: state.AISDataProvider.selectedProduct,
    selectedRaceDay: state.AISDataProvider.selectedRaceDay,
    serverTime: state.AISDataProvider.serverTime,
    raceDayFetched: state.AISDataProvider.raceDayFetched,
    raceDayData: state.AISDataProvider.raceDayData,
    tracks: state.AISDataProvider.tracks,
    tracksJackpotAvailabilityMap: getTracksJackpotAvailabilityMap(state),
    racingCardPending: state.AISDataProvider.racingCardPending,
    trackPoolPending: state.AISDataProvider.trackPoolPending,
    defaultRaceDay: state.AISDataProvider.defaultRaceDay,
    raceDayError: state.AISDataProvider.raceDayError,
    racingCardError: state.AISDataProvider.racingCardError,
    loading:
        state.AISDataProvider.raceDayPending ||
        state.AISDataProvider.racingCardPending,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setRaceDay: (raceDay) => {
            dispatch(setRaceDay(raceDay));
        },
        setProduct: (product) => {
            dispatch(setProduct(product));
        },
        setProductShortcut: (product) => {
            dispatch(setProductShortcut(product));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TracksBySelectedDay);
