import { css } from 'styled-components';
import React, { useEffect, useState } from 'react';
import { AnimationWrapper } from 'features/Spilklub/Mobile/styled';
import { priority } from 'configs/layout';

interface TransitionProps {
    children?(disappear: () => void): JSX.Element;
    duration?: number;
    destroy?(): void;
    appearMixin?: any;
    disappearMixin?: any;
    staticMixin?: any;
    mainMixin?: any;
    onExpand?(): void;
    transparent?: boolean;
    styles?: any;
}

const AnimateMoving = (props: TransitionProps) => {
    return (
        <Transition
            duration={200}
            {...props}
            appearMixin={css`
                transform: translateX(100%);
            `}
            disappearMixin={css`
                transform: translateX(100%);
            `}
            staticMixin={css`
                transform: translateX(0);
            `}
            mainMixin={css`
                transition: transform ${props.duration}ms ease-in;
            `}
        />
    );
};

const Transition = ({
    children,
    duration = 200,
    onExpand = () => {},
    destroy,
    appearMixin,
    disappearMixin,
    staticMixin,
    mainMixin,
    transparent,
    styles,
}: TransitionProps) => {
    const [animationMixin, setAnimationMixin] = useState(appearMixin);

    const disappear = () => setAnimationMixin(disappearMixin);

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            if (animationMixin === disappearMixin && destroy) {
                destroy();
            } else {
                setAnimationMixin(staticMixin);
                onExpand();
            }
        }, duration);

        return () => clearTimeout(timeoutId);
    }, [animationMixin]);

    return (
        <AnimationWrapper
            priority={priority.overlapBottomBar}
            duration={duration}
            animationMixin={animationMixin}
            mainMixin={mainMixin}
            transparent={transparent}
            style={styles}
        >
            {typeof children === 'function' ? children(disappear) : children}
        </AnimationWrapper>
    );
};

export default { Transition };

export { AnimateMoving };
