import React from 'react';
import {
    ArrowDown,
    ArrowUp,
    CombinationsCounter,
    Toggler,
    TopLine,
} from 'features/BetSlip2/platforms/Desktop/styled';
import Text from 'ui/Text';
import { NotifierCounter } from 'features/BetSlip2/components/BetslipNotifier/StyledViews';
import { useSelector } from 'react-redux';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

const BetslipHeader = ({ toggleBetslipVisibility, isBetslipHidden, combinationPrice }) => {
    const { productId, raceDay, race, races, isVProduct } = useTrackPage();

    const combinationsCount = useSelector(state =>
        combinationsCountBy(state, {
            productId,
            trackCode: raceDay.track.code,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            races,
        })
    );

    return (
        <TopLine onClick={toggleBetslipVisibility}>
            <CombinationsCounter>
                {isVProduct ? (
                    <Text white align="center">
                        <NotifierCounter combinationsCount={combinationsCount}>
                            {combinationsCount}
                        </NotifierCounter>{' '}
                        x <b>Indsats</b>
                    </Text>
                ) : (
                    <Text white align="center">
                        <b>Indsats:</b> <NotifierCounter>{combinationsCount}</NotifierCounter> x{' '}
                        {combinationPrice}
                    </Text>
                )}
            </CombinationsCounter>
            <Toggler>{isBetslipHidden ? <ArrowUp /> : <ArrowDown />}</Toggler>
        </TopLine>
    );
};

export default BetslipHeader;
