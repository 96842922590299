import React from 'react';
import { BetStatus } from '../types';
import Text from 'ui/Text';
import Icon from 'ui/Icon';
import { Link } from './styled';
import { Link as RouterLink } from 'react-router-dom';
import { COLORS } from '../../../themes';

export interface StatusProps {
    betStatus: BetStatus;
}

const Status = ({ betStatus }: StatusProps) => {
    switch (betStatus) {
        case BetStatus.accepted: {
            return (
                <>
                    <Text size={'x2'} primary bold>
                        {/*
                            //@ts-ignore*/}
                        <Icon icheckcolorful /> Dit spil er placeret
                    </Text>
                    <Text black>
                        Du kan finde oversigten over dit spil under{' '}
                        <RouterLink
                            to={'/konto/activebets'}
                            style={{ color: COLORS.black, fontWeight: 900 }}
                        >
                            Mine spil
                        </RouterLink>
                        .
                    </Text>
                </>
            );
        }
        case BetStatus.pending: {
            return (
                <>
                    <Text size={'x2'} bold black>
                        {/*
                            //@ts-ignore*/}
                        <Icon iwarncolorful /> Dit spil er endnu ikke godkendt
                    </Text>
                    <Text black>
                        I nogle tilfælde, skal ATG brug ekstra tid på at
                        godkende spillet. Det er vores erfaring, at det kan tage
                        mellem 30 sekunder og 10 minutter.
                    </Text>
                    <Text black>
                        Du kan holde øje med spillet under{' '}
                        <RouterLink
                            to={'/konto/activebets'}
                            style={{ color: COLORS.black, fontWeight: 900 }}
                        >
                            Mine spil
                        </RouterLink>
                        .
                    </Text>
                    <Text black>
                        Hvis status stadig er “afventer” efter 10 minutter,
                        bedes du kontakte vores support.
                    </Text>
                </>
            );
        }
        case BetStatus.failed: {
            return (
                <>
                    <Text size={'x2'} red bold>
                        {/*
                            //@ts-ignore*/}
                        <Icon ierrorcolorful /> Dit spil er blev afvist
                    </Text>
                    <Text black>
                        Dit spil blev afvist og er derfor ikke blevet
                        gennemført.
                    </Text>
                    <Text black bold>
                        Tjek gerne efter om:
                    </Text>
                    <ul>
                        <li>Du har penge nok på din konto</li>
                        <li>Din XML fil ikke er beskadiget</li>
                        <li>Din internetforbindelse er stabil</li>
                        <li>
                            Du overholder reglerne omkring max antal rækker på
                            et V-spil. Læs reglerne{' '}
                            <Link
                                href={
                                    'https://intercom.help/25syv/da/articles/3493018-spilleregler-for-derby25'
                                }
                            >
                                her
                            </Link>
                        </li>
                    </ul>
                    <Text black>
                        Ellers er du altid velkommen til at skrive til vores
                        support.
                    </Text>
                </>
            );
        }
        default: {
            return <Text>Unhandled status</Text>;
        }
    }
};

export default Status;
