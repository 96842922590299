import React from 'react';
import Clock from '../common/Clock';
import { RaceCardHeaderContainer, HeaderButton } from './styled';
import { RaceDay } from 'features/EventBoard/server/calendar';
import { AnyRace } from 'features/TrackPage/model/Race';

interface RaceCardHeaderProps {
    raceDay: RaceDay;
    race: AnyRace;
    reservesMode: boolean;
    toggleReservesMode(): void;
    isVProduct: boolean;
}

const RaceCardHeader = ({
    raceDay,
    race,
    toggleReservesMode,
    reservesMode,
    isVProduct,
}: RaceCardHeaderProps) => {
    return (
        <RaceCardHeaderContainer>
            <Clock raceDay={raceDay} race={race} />

            <div className="flex gap-10">
                {isVProduct && (
                    <HeaderButton active={reservesMode} onClick={toggleReservesMode}>
                        Reserve heste
                    </HeaderButton>
                )}
                <HeaderButton>Luk stats</HeaderButton>
            </div>
        </RaceCardHeaderContainer>
    );
};

export default RaceCardHeader;
