import styled, { css } from 'styled-components';
import { FilledWrapper } from 'ui/Wrapper';
import { boxShadowMixin } from 'ui/Card';
import { COLORS, BACKGROUNDS } from 'themes';
import { pagePadding } from 'features/TrackPage/components/styled';
import { typographyCSS, TypographyProps } from 'ui/typography';
import { ProductId } from 'features/EventBoard/server/calendar';
import { PRODUCT_IDS } from 'configs/products';

const borderRadius = '5px';

export const SlickCustomizations = styled.div`
    .slick-list {
        box-sizing: initial;
        overflow: visible;
    }

    .slick-list {
        width: calc(100% + ${pagePadding});
    }
`;

export const RaceCardWrapper = styled(FilledWrapper)`
    position: relative;
    z-index: 15;
    padding: 0;
    border-radius: ${borderRadius};
    margin-right: ${pagePadding}; // slick fix
    background: ${COLORS.white};
    ${boxShadowMixin};
`;

export const RaceCardHeaderContainer = styled.div`
    color: ${COLORS.white};
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    background: ${BACKGROUNDS.primaryFlat};
    padding: 0 10px 0 23px;
    height: 50px;
    box-sizing: border-box;
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
`;

export const HeaderButton = styled.button<{ active?: boolean }>`
    color: #111;
    border-radius: 5px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 0;
    font-size: 12px;
    font-weight: bold;
    transition: background 200ms linear;
    background: ${(props) => (props.active ? '#FED300' : '#F5F7FC')};
`;

interface HorseInfoRowElementProps {
    isVProduct: boolean;
    productId: ProductId;
}

const getGridColumns = ({ isVProduct, productId }: HorseInfoRowElementProps) => {
    const nrColMap = { [PRODUCT_IDS.T]: '160px', [PRODUCT_IDS.TV]: '110px' };
    const oddsColMap = { [PRODUCT_IDS.T]: '60px' };

    const nrCol = nrColMap[productId] ?? '60px';
    const oddsCol = oddsColMap[productId] ?? '80px';

    return isVProduct ? `60px 1fr 40px 60px` : `${nrCol} 1fr ${oddsCol}`;
};

const tableGrid = css<HorseInfoRowElementProps>`
    display: grid;
    align-items: center;
    column-gap: 5px;
    grid-template-columns: ${getGridColumns};
`;

export const TableHeader = styled.div<HorseInfoRowElementProps>`
    ${tableGrid};
    grid-template-rows: 20px; // header row height
    border-bottom: 1px solid #eceff6;
`;

export const TableRow = styled.div<HorseInfoRowElementProps>`
    ${tableGrid};
    grid-template-rows: 50px; // rest rows height

    &:nth-of-type(even) {
        background: #ebeff6;
    }
`;

const tableColCSS = css<TypographyProps>`
    ${typographyCSS};
`;

export const TableHeaderCol = styled.div<TypographyProps>`
    ${tableColCSS};
    font-size: 12px;
    font-weight: 900;
`;
export const TableCol = styled.div<TypographyProps>`
    ${tableColCSS};
    font-size: 16px;
`;
