import React from 'react';
import styled from 'styled-components';
import { BACKGROUNDS } from 'themes';
import { fadeInMixin } from 'ui/animations/FadeIn2';

const CheckboxWrapper = styled.div`
    background: ${({ checked }) =>
        checked ? BACKGROUNDS.primaryFlat : BACKGROUNDS.lightGrey};
    height: ${(props) => props.size || 24}px;
    width: ${(props) => props.size || 24}px;
    border-radius: 15px;
    border: 0.5px solid #c6ccdb;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CheckMark = styled.div`
    width: 35%;
    height: 35%;
    border-radius: inherit;
    background: ${BACKGROUNDS.lightGrey};
    ${fadeInMixin};
`;

const Checkbox2 = ({ checked, onChange, id, size }) => (
    <CheckboxWrapper checked={checked} onClick={onChange} id={id} size={size}>
        {checked && <CheckMark animationName="checkbox2-fadeIn" />}
    </CheckboxWrapper>
);

export default Checkbox2;
