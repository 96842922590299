import React, { useEffect, useState } from 'react';

import BetSlip from 'features/BetSlip/index';

import {
    performBet as postBet,
    resetBet,
    resetSmartLyn,
    setDerbyLynBet,
    setPrevAmount,
    setSmartLynBet,
    setSmartLynMode,
} from 'features/BetSlip/state/actions';
import { isUserLoggedIn, updateUserInfo, terminalLogin } from 'common/actions/authActions';
import { connect } from 'react-redux';
import betSlipSelector, {
    checkMaximumPicks,
    checkMinimumPicks,
    getReserves,
} from 'common/selectors/betSlipSelector';
import productSelector from 'common/selectors/productSelector';
import {
    getCombinedTrackName,
    getMultitrackCodes,
    getTrackFromRacingCard,
} from 'common/selectors/trackSelector';
import NotificationConductor from 'common/conductors/NotificationConductor';
import {
    ACCEPTABLE_FLOAT_POINTS,
    COMBINATION_PRICE_EVEN_NUMBERS_ONLY,
    MAX_COMBINATION_PRICES,
    MAX_COUPON_AMOUNT,
    MIN_COMBINATION_PRICES,
    SMART_LYN_AMOUNTS,
    MAX_SMART_LYN_COUPON_AMOUNT,
} from 'configs/products';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import UserSessionAdapter from 'common/adapters/UserSession';
import { extractErrorMessageFromResponse, getTranslatedErrorMessage } from 'utils/betting-service';
import { postPlayAndPaySale } from 'features/BetSlip/components/PlayAndPay/actions';
import { getPostTimeFromMutitrackLegacy } from 'utils/retail';
import raceDaySelector, { getMultitrackLegs } from 'common/selectors/raceDaySelector';
import { getSmartLynBet } from 'common/selectors/smartLynSelector';
import { getMethodName, getQuickPickMethodName, adjustCouponToSmartLyn } from 'utils/retail';
import { setMobileBetInit } from 'features/MobileBet/state/actions';
import getTexts from 'utils/localization';
import { logMessage } from 'utils/log';
import RacingCard from 'common/DataObjects/RacingCard';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import betTableSelector from 'common/selectors/betTableSelector';
import { hideModal, showModal } from 'common/actions/uiActions';
import withQuickPickBetting from '../QuickPick/withQuickPickBetting';
import withSmartLynManager from './components/SmartLyn/withSmartLynManager';
import { byPlatform } from '../../utils/platforms';
import { getCouponFactory } from './BettingREST/couponSelector';
import CouponFactory from './BettingREST/DataObjects/CouponFactory';

const t = getTexts();

const BetSlipContainer = props => {
    const {
        product,
        auth,
        postBet,
        isUserLoggedIn,
        updateUserInfo,
        onBetPerformed,
        onAuthenticationRequired,
        onMobileBetWorkflow,
        setMobileBetInit,
        mobileBetInit,
        performBetError,
        performBetPending,
        availableCardsPending,
        availableCardsError,
        onCombinationPriceSet,
        combinationsCount,
        minimumPicksValid,
        maximumPicksValid,
        prevAmount,
        racingCardData,
        coupleRacingCardData,
        raceIndex,
        smartLynMode = false,
        smartLynBet,
        createSmartLynLegs,
        performQuickPickBet,
        setQuickPickAmount,
        lockedLegs,
        allLegsLocked,
        toggleLegStatus,
        selectedRaceDay,
        selectedProduct,
        quickPickBetPending /* from withQuickPickBetting hoc */,
        terminalLogin,
        setSmartLynMode,
        activateSmartLyn,
        couponFactory,
    } = props;

    useEffect(() => {
        return () => {
            resetSmartLyn();
        };
    }, []);

    const toggleSmartLynMode = () => {
        if (smartLynMode) {
            setSmartLynMode(false);
        } else {
            activateSmartLyn();
        }
    };

    const [combinationPrice, setCombinationPrice] = useState(
        !prevAmount ? product.getBasicPrice() : prevAmount
    );
    const [isCombinationPriceValid, setCombinationPriceValid] = useState(true);
    const [combPriceError, setCombPriceError] = useState('');

    const [isCouponAmountExceed, setCouponAmountExceed] = useState(false);

    useEffect(
        () => {
            validateCouponAmount(combinationPrice);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [combinationsCount]
    );

    useEffect(() => {
        setCombinationPrice(selectedProduct.getBasicPrice());
    }, [selectedProduct, selectedProduct.id]);

    const applyCombinationPrice = combinationPrice => {
        const isValid = validateCombinationPrice(combinationPrice);
        const isCouponAmountValid = validateCouponAmount(combinationPrice);
        setCombinationPrice(combinationPrice);
        if (smartLynMode) {
            setQuickPickAmount(parseInt(combinationPrice, 10));
        }
        isValid &&
            isCouponAmountValid &&
            onCombinationPriceSet &&
            onCombinationPriceSet(combinationPrice);
    };

    const validateCouponAmount = combinationPrice => {
        let couponAmountExceed;

        if (smartLynMode) {
            couponAmountExceed = combinationPrice >= MAX_SMART_LYN_COUPON_AMOUNT;
        } else {
            couponAmountExceed = combinationsCount * combinationPrice > MAX_COUPON_AMOUNT;
        }

        setCouponAmountExceed(couponAmountExceed);
        return !couponAmountExceed;
    };

    const isFloat = val => /\./g.test(val);
    //@TODO use useAmount hook, this code duplicates a lot from it
    const isDecimalPointValid = val => {
        const acceptablePoints = ACCEPTABLE_FLOAT_POINTS[product.id];
        const regex = new RegExp(`^\\d+\\.(${acceptablePoints.join('|')})$`, 'g');
        return regex.test(val);
    };
    const isNumberEven = val => val % 2 === 0;

    const validateCombinationPrice = combinationPrice => {
        const MIN_RATE = smartLynMode ? SMART_LYN_AMOUNTS[0] : MIN_COMBINATION_PRICES[product.id];
        const MAX_RATE = MAX_COMBINATION_PRICES[product.id];

        const incorectMinRate = MIN_RATE && combinationPrice < MIN_RATE;
        const incorectMaxRate = MAX_RATE && combinationPrice > MAX_RATE;
        const incorrectFloatAmount =
            isFloat(combinationPrice) && !isDecimalPointValid(combinationPrice);
        const incorrectByEvenNumberCheck =
            COMBINATION_PRICE_EVEN_NUMBERS_ONLY.includes(product.id) &&
            !isNumberEven(combinationPrice);

        if (incorectMinRate) {
            setCombPriceError(t.betSlip.cpLow);
        }

        if (smartLynMode) {
            // smartLynMode needs only checking the min rate
            setCombinationPriceValid(!incorectMinRate);
            return !incorectMinRate;
        }

        if (incorectMaxRate) {
            setCombPriceError(t.betSlip.cpHigh);
        }

        if (incorrectByEvenNumberCheck && !incorectMaxRate) {
            // since there will be displayed only 1 message
            // incorrectMaxRate message is over even numbers checking
            // @see https://ecosys.atlassian.net/browse/DER-1358
            setCombPriceError(t.betSlip.cpEvenNumbers);
        }

        if (incorrectFloatAmount) {
            setCombPriceError(
                `Tilladte decimaltal: ${ACCEPTABLE_FLOAT_POINTS[product.id].map(
                    point => `0.${point}`
                )}`
            );
        }

        const isPriceValid = !(
            incorectMinRate ||
            incorectMaxRate ||
            incorrectFloatAmount ||
            incorrectByEvenNumberCheck
        );

        setCombinationPriceValid(isPriceValid);

        return isPriceValid;
    };

    const performBet = async () => {
        setPrevAmount(0);

        try {
            if (!auth.user) {
                onAuthenticationRequired && onAuthenticationRequired();
                return;
            } else {
                const coupon = couponFactory.create(combinationPrice);

                try {
                    const couponData = UserSessionAdapter.getPerformSaleData(
                        coupon,
                        auth.user.id,
                        product.isMultitrack
                            ? getPostTimeFromMutitrackLegacy({
                                  selectedRaceDay,
                                  selectedProduct,
                                  racingCardData,
                                  coupleRacingCardData,
                              })
                            : UserSessionAdapter.getPostTime(racingCardData, raceIndex)
                    );
                    const response = await postBet(product, couponData);
                    if (response.success) {
                        onBetPerformed && onBetPerformed(response.data, props);

                        await UserSessionAdapter.performUserUpdate(
                            isUserLoggedIn,
                            updateUserInfo,
                            response
                        );
                    } else {
                        const error = getTranslatedErrorMessage(response);

                        logMessage(extractErrorMessageFromResponse(response), {
                            context: 'BetSlipConntainer.performBet:responseError',
                        });

                        NotificationConductor.error(error);
                    }
                } catch (e) {
                    console.error(e);
                    logMessage(e, { context: 'BetSlipConntainer.performBet' });
                    NotificationConductor.error(t.betSlip.performBetError);
                }
            }
        } catch (e) {
            console.error(e);
            logMessage(e, { context: 'BetSlipConntainer.performBet' });
            NotificationConductor.error(t.betSlip.getAvailableCardsError);
        }
    };

    const getUnpaidSaleCoupon = async () => {
        const smartLynWorkflow = smartLynMode && smartLynBet;
        const method = smartLynWorkflow ? getQuickPickMethodName(product) : getMethodName(product);

        let coupon = couponFactory.create(/*availableCards,*/ combinationPrice);
        coupon.method = method;
        coupon.productInfo = {
            id: product.id,
            name: product.name,
        };

        coupon.postTime = product.isMultitrack
            ? getPostTimeFromMutitrackLegacy({
                  selectedRaceDay,
                  selectedProduct,
                  racingCardData,
                  coupleRacingCardData,
              })
            : UserSessionAdapter.getPostTime(racingCardData, raceIndex);
        coupon.trackInfo = selectedRaceDay ? selectedRaceDay.track : {};

        if (smartLynWorkflow) {
            coupon = adjustCouponToSmartLyn(coupon, createSmartLynLegs);
        }

        return coupon;
    };

    const performMobileBet = async prices => {
        try {
            setMobileBetInit(true);
            const uSaleInfo = await getUnpaidSaleCoupon();
            onMobileBetWorkflow(prices, uSaleInfo, lockedLegs);
        } catch (e) {
            console.error(e);
        }
    };

    const performPlayAndPaySale = async () => {
        try {
            const coupon = await getUnpaidSaleCoupon();

            return postPlayAndPaySale(coupon, response => {
                if (response.success) {
                    onBetPerformed && onBetPerformed(response.data, props, true);
                } else {
                    NotificationConductor.error(
                        'PlayAndPay request error: ' + response.error_message
                    );
                }

                return response;
            });
        } catch (e) {
            console.error(e);
            return e;
        }
    };

    const sharedProps = {
        ...props,
        toggleSmartLynMode: byPlatform(toggleSmartLynMode, props.toggleSmartLynMode),
        combinationPrice,
        applyCombinationPrice,
        isCombinationPriceValid,
        combinationPriceError: combPriceError,
        isCouponAmountExceed,
        confirmBet: smartLynMode ? performQuickPickBet : performBet,
        performPlayAndPaySale,
        performMobileBet,
        mobileBetInit,
        terminalLogin,
        minimumPicksValid,
        maximumPicksValid,
        performBetPending,
        availableCardsPending,
        availableCardsError,
        performBetError,
        user: auth.user,
        onAuthenticationRequired,
        lockedLegs,
        allLegsLocked,
        toggleLegStatus,
        smartLynBet,
        smartLynMode,
        quickPickBetPending,
    };

    return <BetSlip {...sharedProps} />;

    // return (
    //     <BetProvider>
    //         {(betData) => {
    //             betProviderData = betData;
    //             return children({
    //                 ...betData,
    //                 combinationPrice,
    //                 applyCombinationPrice,
    //                 isCombinationPriceValid,
    //                 combinationPriceError: combPriceError,
    //                 isCouponAmountExceed,
    //                 confirmBet: smartLynMode ? performQuickPickBet : performBet,
    //                 performPlayAndPaySale,
    //                 performMobileBet,
    //                 mobileBetInit,
    //                 terminalLogin,
    //                 minimumPicksValid,
    //                 maximumPicksValid,
    //                 performBetPending,
    //                 availableCardsPending,
    //                 availableCardsError,
    //                 performBetError,
    //                 user: auth.user,
    //                 onAuthenticationRequired,
    //                 lockedLegs,
    //                 allLegsLocked,
    //                 toggleLegStatus,
    //                 smartLynBet,
    //                 smartLynMode,
    //                 quickPickBetPending,
    //             });
    //         }}
    //     </BetProvider>
    // );
};

const mapStateToProps = state => {
    const raceNumber = state.AISDataProvider.selectedRaceNumber;
    const racingCard = RacingCard.fill(state.AISDataProvider);
    const isMultitrack = isMultitrackProduct(state);
    const product = productSelector(state);
    const track = getTrackFromRacingCard(state);

    const ready = state.AISDataProvider.racingCardFetched && !racingCard.isNull();

    return {
        ready,
        betSlip: betSlipSelector(state),
        date: raceDaySelector(state).date,
        product,
        track,
        programNumber: raceDaySelector(state).programNumber,
        races: state.AISDataProvider.racingCardData.races, //races: racesSelector(state) ? <- BetProvider
        racingCardData: state.AISDataProvider.racingCardData,
        coupleRacingCardData: state.AISDataProvider.coupleRacingCardData,
        raceIndex: state.AISDataProvider.raceIndex,
        selectedRaceDay: state.AISDataProvider.selectedRaceDay,
        selectedProduct: state.AISDataProvider.selectedProduct,
        auth: state.auth,
        availableCardsPending: state.BetSlip.availableCardsPending,
        availableCardsError: state.BetSlip.availableCardsError,
        availableCardsData: state.BetSlip.availableCardsData,
        performBetError: state.BetSlip.performBetError,
        performBetPending: state.BetSlip.performBetPending,
        couponFactory: state.AISDataProvider.racingCardFetched
            ? getCouponFactory(state)
            : new CouponFactory({}),
        combinationsCount: combinationsCountSelector(state),
        minimumPicksValid: checkMinimumPicks(state),
        maximumPicksValid: checkMaximumPicks(state),
        prevAmount: state.BetSlip.prevAmount,
        smartLynBet: getSmartLynBet(state),
        smartLynMode: state.BetSlip.smartLynMode,
        mobileBetInit: state.MobileBet.initialization,
        // moved from BetProvider
        race: product.isVProduct()
            ? racingCard.races[0]
            : racingCard.races.find(r => r.raceNumber === raceNumber),
        raceNumber,
        combinedTrackName: getCombinedTrackName(state),
        trackCode: track.code,
        bet: betTableSelector(state),
        rawPool: state.AISDataProvider.trackPool,
        availableRaceNumbers: racingCard.races.map(race => race.raceNumber),
        serverTime: state.AISDataProvider.serverTime,
        isMultitrackProduct: isMultitrack,
        multitrackLegs: getMultitrackLegs(state),
        tracks: state.AISDataProvider.tracks,
        reserves: getReserves(state),
        // moved from BetSlip/platforms/Mobile/index
        isMultitrack,
        multitrackCodes: getMultitrackCodes(state),
        // moved from BetSlip/platforms/Desktop/index
        derbyLynBet: state.BetSlip.derbyLynBet,
    };
};

const mapDispatchToProps = dispatch => ({
    postBet: (product, coupon) => dispatch(postBet(product, coupon)),
    postPlayAndPaySale: (product, coupon) => dispatch(postPlayAndPaySale(product, coupon)),
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
    updateUserInfo: authData => dispatch(updateUserInfo(authData)),
    setPrevAmount: amount => {
        dispatch(setPrevAmount(amount));
    },
    setSmartLynBet: smartLynBet => {
        dispatch(setSmartLynBet(smartLynBet));
    },
    resetBet: () => dispatch(resetBet()),
    setMobileBetInit: status => {
        dispatch(setMobileBetInit(status));
    },
    terminalLogin: sessionId => {
        dispatch(terminalLogin(sessionId));
    },
    // moved from BetSlip/platforms/Mobile/index
    setSmartLynMode: status => {
        dispatch(setSmartLynMode(status));
    },
    resetSmartLyn: () => {
        dispatch(resetSmartLyn());
    },
    // moved from  BetSlip/platforms/Desktop/index
    setDerbyLynBet: (date, trackCode, product) => {
        dispatch(setDerbyLynBet(date, trackCode, product));
    },
    showQuickPickAmountModal: data => {
        dispatch(showModal('QUICK_PICK_AMOUNT', -896, data));
    },
    hideQuickPickAmountModal: () => {
        dispatch(hideModal('QUICK_PICK_AMOUNT'));
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withQuickPickBetting(withSmartLynManager(BetSlipContainer)));
