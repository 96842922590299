import { hookstate, useHookstate } from '@hookstate/core';

export const reservesMode = hookstate(false);
const useReservesMode = () => {
    const reservesModeState = useHookstate(reservesMode);
    const toggleReservesMode = () => reservesModeState.set(!reservesModeState.get());

    return { reservesMode: reservesModeState.get(), toggleReservesMode };
};

export default useReservesMode;
