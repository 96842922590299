import React, { Component, Fragment } from 'react';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';
import TopBar from 'ui/topbar';
import TopBarText from 'ui/topbar/Text';
import TopBarInput from 'ui/topbar/Input';
import { ContentHeight, Fillable, FullScreen } from 'ui/FullScreen';
import { connect } from 'react-redux';
import { getUser } from 'common/actions/authActions';
import NotificationConductor from 'common/conductors/NotificationConductor';
import userConductor from 'common/conductors/UserConductor';
import Spinner from 'ui/Spinner';
import { setState } from 'utils/promise';
import User from 'common/DataObjects/User';
import { sprintf } from 'sprintf-js';
import {
    CheckboxLine,
    ConfirmBtn,
    IconWithIndent,
    Message,
    MessageTitle,
} from './styled';
import { byPlatform } from 'utils/platforms';
import { AMOUNT_REG_EX } from 'features/UserArea/Deposit';
import { isMobile } from 'utils/platforms';
import { isUserLoggedIn } from 'common/actions/authActions';
import getTexts from 'utils/localization';
import { withRouter } from 'react-router-dom';
import { ActiveBonuses } from '@it25syv/25syv-ui';
import ActiveBonus from '../Deposit/ActiveBonus';
import { setComponentsOptions, showModal } from 'common/actions/uiActions';

const t = getTexts();

const MIN_RATE = process.env.NODE_ENV === 'development' ? 1 : 100;

class Withdraw extends Component {
    constructor() {
        super();
        this.state = {
            withdrawAmount: '',
            loading: true,
            payout: null,
            bonusAgreementAccepted: false,
            activeBonus: null,
        };
        this.inputRef = React.createRef();
        this.isInputValid = false;
    }

    componentDidMount() {
        setState(this, { loading: true })
            .then(() => {
                if (!this.props.user) {
                    return Promise.reject('Logged out');
                }
                return userConductor.getActiveBonus();
            })
            .then(activeBonus => {
                this.props.setComponentsOptions({ resetBonusStatus: false });
                return setState(this, {
                    activeBonus: ActiveBonus.unserialize(activeBonus.data),
                    loading: false,
                });
            })
            .catch(e => console.log(e));

        isMobile && this.props.enableCloseButton();

        setState(this, { loading: true })
            .then(() => {
                if (!this.props.user) {
                    return Promise.reject('Logged out');
                }
                return userConductor.getPayout();
            })
            .then(payout => setState(this, { payout, loading: false }))
            .catch(e => console.log(e));
    }

    chooseDeposit = deposit => {
        this.setState({ withdrawAmount: deposit });
        this.isInputValid = true;
    };

    getAmountFloatRepresantation = withdrawAmount => {
        return parseFloat(withdrawAmount.replace(',', '.'));
    };

    changeDeposit = e => {
        const withdrawAmount = e.target.value.replace(/[^0-9,]+/g, '');
        this.isInputValid = this.getAmountFloatRepresantation(withdrawAmount) >= MIN_RATE;

        this.setState(
            {
                withdrawAmount,
            },
            this.setCaretPosition
        );
    };

    confirm = () => {
        const { getUser, hideModal } = this.props;
        const withdrawAmount = this.state.withdrawAmount.replace(AMOUNT_REG_EX, '');

        setState(this, { loading: true })
            .then(() => {
                if (!this.props.user) {
                    return Promise.reject('Logged out');
                }
                return userConductor.getPayout();
            })
            .then(payout => setState(this, { payout }))
            .then(() => userConductor.withdraw(this.getAmountFloatRepresantation(withdrawAmount)))
            .then(res => {
                NotificationConductor.success(t.userArea.withdraw.success);

                if (isMobile) {
                    hideModal('WITHDRAW');
                } else {
                    this.props.isUserLoggedIn();

                    this.setState({
                        loading: false,
                    });
                }

                return res;
            })
            .then(getUser)
            .catch(err => {
                console.log(err.toString());
            });
    };

    toggleBonusAgreement = () => {
        this.setState({
            bonusAgreementAccepted: !this.state.bonusAgreementAccepted,
        });
    };

    setCaretPosition = e => {
        const length = this.state.withdrawAmount.length;

        if (this.inputRef.current.selectionStart > length) {
            this.inputRef.current.selectionStart = length;
            this.inputRef.current.selectionEnd = length;
        }
    };

    onFocusHandler = () => {
        const { withdrawAmount } = this.state;

        this.setState({
            withdrawAmount: withdrawAmount.replace(AMOUNT_REG_EX, ''),
        });
    };

    onBlurHandler = () => {
        let { withdrawAmount } = this.state;

        withdrawAmount = withdrawAmount.endsWith(',')
            ? withdrawAmount.slice(0, withdrawAmount.length - 1)
            : withdrawAmount;

        this.setState({ withdrawAmount: withdrawAmount + ' ' + t.currency });
    };

    /**
     * Determines if current state allows to withdraw
     * @returns {boolean}
     */
    canWithdraw = () => {
        const { payout, bonusAgreementAccepted } = this.state;

        const withdrawAmount = this.state.withdrawAmount.replace(AMOUNT_REG_EX, '');

        return (
            this.isInputValid &&
            withdrawAmount &&
            (payout.hasBonus() ? bonusAgreementAccepted : true) &&
            payout.isAmountAvailable(withdrawAmount)
        );
    };

    renderBonusMessage() {
        const { payout } = this.state;
        return (
            <Fragment>
                <MessageTitle>{t.userArea.withdraw.rollover.title}</MessageTitle>
                <Message accent>
                    <p>
                        {sprintf(
                            t.userArea.withdraw.rollover.yourBalance,
                            payout.amount,
                            payout.bonus
                        )}
                    </p>
                    {payout.voucher && (
                        <p>
                            {sprintf(
                                t.userArea.withdraw.rollover.voucher,
                                payout.calculateVoucher()
                            )}
                        </p>
                    )}
                    <p>
                        {sprintf(
                            t.userArea.withdraw.rollover.minimumOdds,
                            payout.rollover,
                            payout.getMinOdds()
                        )}
                    </p>
                </Message>
                <CheckboxLine>
                    {t.userArea.withdraw.rollover.agreement}
                    <input
                        type="checkbox"
                        checked={this.state.bonusAgreementAccepted}
                        onClick={this.toggleBonusAgreement}
                    />
                </CheckboxLine>
            </Fragment>
        );
    }

    renderRestrictionMessage = () => {
        const { payout } = this.state;

        return (
            <Message accent>
                Du har en aktiv bonus på {payout?.totalBonus}
                kr, og du mangler at omsætte før {payout?.remainingCashinWageringAmount}
                kr på Bet25, før at du kan foretage en udbetaling.
            </Message>
        );
    };

    renderBonusInfo = () => {
        const { activeBonus } = this.state;
        const { showModal } = this.props;

        const removeBonus = () => {
            showModal('REMOVE_BONUS_CONFIRM', 2, {
                bonusAmount: activeBonus.totalBonus,
            });
        };

        return (
            <Wrapper
                maxWidth="375px"
                margin="0 auto"
                style={{ fontSize: '16px', fontFamily: 'Roboto Condensed' }}
            >
                <ActiveBonuses
                    onRemove={removeBonus}
                    activeBonus={activeBonus.totalBonus}
                    rollover={activeBonus.rollover}
                    initialRollover={activeBonus.initialRollover}
                    gameId={activeBonus.gameId}
                    bonusButtonText={'Gå til Bet25 Casino'}
                />
            </Wrapper>
        );
    };

    renderForm = () => {
        const { payout } = this.state;

        const withdrawAmount = this.state.withdrawAmount.replace(AMOUNT_REG_EX, '');

        const { user } = this.props;

        return (
            <Fragment>
                <Fillable>
                    <TopBarInput
                        value={this.state.withdrawAmount}
                        onChange={this.changeDeposit}
                        onFocus={this.onFocusHandler}
                        onBlur={this.onBlurHandler}
                        inputRef={this.inputRef}
                        placeholder="Indtast beløb"
                        top={!this.props.desktop ? '-36px' : '10px'}
                    />
                    <Text
                        size="x05"
                        className="uppercase text-center"
                        style={{
                            position: 'relative',
                            top: byPlatform('-36px', '0px'),
                        }}
                    >
                        {sprintf(t.userArea.withdraw.minAmount, MIN_RATE)}
                    </Text>

                    {!user.isAccountApproved() && (
                        <Message accent>{t.userArea.withdraw.tempRegistrationWarning}</Message>
                    )}
                    {withdrawAmount &&
                        !payout.isAmountAvailable(withdrawAmount) && (
                            <Message red>
                                {/* In most of the cases it means the user DOES NOT HAVE CPR */}
                                {t.userArea.withdraw.unavailableAmount}
                            </Message>
                        )}
                    {withdrawAmount &&
                        withdrawAmount < MIN_RATE && (
                            <Message red>
                                {sprintf(
                                    t.userArea.withdraw.smallAmount,
                                    MIN_RATE
                                )}
                            </Message>
                        )}
                    {/*{payout.hasBonus() && this.renderBonusMessage()}*/}
                </Fillable>

                <ContentHeight>
                    <ConfirmBtn
                        accent
                        size="x3"
                        className="center uppercase block-center"
                        onClick={this.confirm}
                        disabled={!this.canWithdraw()}
                    >
                        {t.userArea.withdraw.next}
                    </ConfirmBtn>
                </ContentHeight>
            </Fragment>
        );
    };

    render() {
        const { loading, payout, activeBonus } = this.state;

        return (
            <FullScreen>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <TopBar>
                            <TopBarText className="lg">
                                <IconWithIndent
                                    ipayout
                                    size="x1"
                                    alt={t.userArea.withdraw.title}
                                    color="white"
                                />
                                {t.userArea.withdraw.title}
                            </TopBarText>
                        </TopBar>
                    </ContentHeight>
                ) : null}

                {loading ? (
                    <Spinner />
                ) : payout && payout.remainingCashinWageringAmount > 0 ? (
                    this.renderRestrictionMessage()
                ) : activeBonus && !this.props.resetBonusStatus ? (
                    this.renderBonusInfo()
                ) : (
                    this.renderForm()
                )}
            </FullScreen>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: User.unserialize(state.auth.user),
        resetBonusStatus: state.ui.components.resetBonusStatus || false,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        showModal: (modalId, priority, data) => {
            dispatch(showModal(modalId, priority, data));
        },
        getUser: () => {
            return dispatch(getUser());
        },
        isUserLoggedIn: () => dispatch(isUserLoggedIn()),
        setComponentsOptions: options => dispatch(setComponentsOptions(options)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Withdraw));

export { Withdraw, MIN_RATE };
