import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Text from 'ui/Text';
import Icon from 'ui/Icon';
import copyLink from 'images/icons/copyLink.svg';
import starIcon from 'images/icons/star_in_yellow.svg';
import getTexts from 'utils/localization';
import { BetBuddyBar } from './styled';
import { useSelector } from 'react-redux';
import FadeIn from 'ui/animations/FadeIn';
import Group, { GroupMember } from '../model/Group';
import Pool, { createNullPool, totalSharesForUser } from '../model/Pool';
import useAuth from 'common/hooks/useAuth';
import { getHost, getOrigin } from '../../../utils/navigation';

const t = getTexts();

const GroupMembersList = styled.div`
    padding: 10px 25px;
`;

const StyledText = styled(Text)`
    font-size: 16px;
    color: #404757;
    margin-bottom: 8px;
    text-align: center;
`;

const LinkContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 346px;
    max-width: 100%;
    height: 60px;
    background-color: #eceff5;
    border-radius: 30px;
    margin: 0 auto 30px;
`;

const TextLink = styled(Text)`
    font-size: 18px;
    color: #333;
    margin-left: 28px;
    overflow: hidden;
`;

const CopyLinkButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 89px;
    height: 60px;
    border-radius: 30px;
    background-color: #20ac68;
    right: 0;
`;

const BalanceContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
`;

const Balance = styled.p`
    margin: 0;
    font-size: 53px;
    font-weight: bold;
    color: #404757;
    text-align: center;
`;

const BalanceText = styled.p`
    margin: 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
`;

const MemberContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
`;

const Border = styled.div`
    height: 1px;
    background-color: #dcdcdc;
    width: 100%;
    margin: 12px 0;
`;

const MainInfo = styled.div`
    display: flex;
    align-items: center;
`;

const MemberNameContainer = styled.div`
    padding: 0 0 0 30px;
    align-self: center;
`;

const MemberTitle = styled.p`
    margin: 0;
    font-size: 14px;
    color: #333;
    font-weight: bold;
`;

const AvatarText = styled.p`
    margin: 0;
    color: #fff;
    font-size: 32px;
`;

const Avatar = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #05a358;
    font-weight: bold;
    min-width: 64px;
    position: relative;
`;

const MemberSubscription = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

const MemberName = styled.p`
    margin: 0;
    color: #333;
    font-size: 22px;
    font-weight: bold;
`;

const BalanceInfo = styled.div`
    margin: 0;
    font-size: 22px;
    color: #333;
    font-weight: bold;
    text-align: right;
    min-width: 60px;
`;

const HeaderWrapper = styled(BetBuddyBar)`
    height: 180px;
`;

const HeaderContainer = styled.div`
    text-align: center;
    width: 100%;
`;

const HeaderTitle = styled.h2`
    font-size: 46px;
    color: #fff;
    font-weight: bold;
    margin: 0;
`;

const HeaderText = styled.p`
    font-size: 22px;
    text-align: center;
    color: #fff;
    opacity: 0.6;
    margin: 0;
`;
//TODO: should be moved to component related to shareLink
const LinkCopiedContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LinkCopied = styled.div`
    margin: 0;
    width: 290px;
    height: 72px;
    border-radius: 40px;
    background-color: #2e2e2eb3;
    box-shadow: 0 0 25px #00000076;
    text-align: center;
`;

const LinkCopiedText = styled.p`
    color: #fff;
    font-size: 23px;
    line-height: 27px;
    text-transform: uppercase;
`;

const copyToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

interface GroupInfoScreenProps {
    pool: Pool;
    groupMembers: GroupMember[];
}

const GroupInfoScreen = ({
    pool = createNullPool(),
    groupMembers = [],
}: GroupInfoScreenProps) => {
    const auth = useAuth();

    const url = `${getOrigin()}/SPK/${pool.internalPool.hash}`;

    const [copySuccess, setCopySuccess] = useState(false);

    const copyUrl = () => {
        copyToClipboard(url);

        setCopySuccess(true);

        setTimeout(() => setCopySuccess(false), 2000);
    };

    const groupInfo = useSelector(
        (state: any): Group => state.BetBuddy.groups.data[pool.groupId]
    );

    const isCaptain = () =>
        auth.user ? auth.user.id === groupInfo.ownerId : false;

    const showMember = (userId: number) => {
        if (isCaptain() || groupInfo.ownerId === userId) {
            return true;
        }
    };

    const sortedGroupMembers = () => {
        groupMembers.sort(
            (a, b) =>
                totalSharesForUser(pool.internalPool, a.userId) > 0
                    ? -1
                    : totalSharesForUser(pool.internalPool, b.userId) <= 0
                        ? 1
                        : 0
        );

        groupMembers.sort(
            (a, b) =>
                a.userId === groupInfo.ownerId
                    ? -1
                    : b.userId === groupInfo.ownerId
                        ? 1
                        : 0
        );

        return groupMembers;
    };

    return (
        <>
            <HeaderWrapper>
                <HeaderContainer>
                    <HeaderTitle>{t.groupOverview.mainTitle}</HeaderTitle>
                    <HeaderText>{t.groupOverview.description}</HeaderText>
                </HeaderContainer>
            </HeaderWrapper>
            <GroupMembersList>
                <StyledText>{t.groupOverview.secondaryTitle}</StyledText>
                <LinkContainer>
                    <TextLink>{url}</TextLink>
                    <CopyLinkButton onClick={() => copyUrl()}>
                        <Icon size="x1" src={copyLink} />
                    </CopyLinkButton>
                </LinkContainer>
                {copySuccess && (
                    <FadeIn style={{ marginTop: '10px' }}>
                        <LinkCopiedContainer>
                            <LinkCopied>
                                <LinkCopiedText>
                                    {t.bb.sharedLink.linkCopied}
                                </LinkCopiedText>
                            </LinkCopied>
                        </LinkCopiedContainer>
                    </FadeIn>
                )}
                <BalanceContainer>
                    <Balance>{pool.internalPool.totalAmount} kr.</Balance>
                    <BalanceText>{t.groupOverview.saldo}</BalanceText>
                </BalanceContainer>

                {sortedGroupMembers().map((user, index) => (
                    <Fragment key={index}>
                        <FadeIn>
                            <MemberContainer>
                                <MainInfo>
                                    <Avatar>
                                        <AvatarText>
                                            {user.firstName.substr(0, 1)}
                                            {user.lastName.substr(0, 1)}
                                        </AvatarText>
                                        {user.subscribedToGroup && (
                                            <MemberSubscription>
                                                <Icon
                                                    size="x05"
                                                    src={starIcon}
                                                />
                                            </MemberSubscription>
                                        )}
                                    </Avatar>

                                    <MemberNameContainer>
                                        {(isCaptain() ||
                                            groupInfo.ownerId ===
                                                user.userId) && (
                                            <>
                                                <MemberName>
                                                    {
                                                        user.firstName.split(
                                                            ' '
                                                        )[0]
                                                    }{' '}
                                                    {user.lastName.substr(0, 1)}
                                                </MemberName>
                                                {groupInfo.ownerId ===
                                                user.userId ? (
                                                    <MemberTitle>
                                                        {
                                                            t.groupOverview
                                                                .captain
                                                        }
                                                    </MemberTitle>
                                                ) : null}
                                            </>
                                        )}
                                    </MemberNameContainer>
                                </MainInfo>
                                <BalanceInfo>
                                    {totalSharesForUser(
                                        pool.internalPool,
                                        user.userId
                                    )}{' '}
                                    kr.
                                </BalanceInfo>
                            </MemberContainer>
                            {groupMembers.length !== index + 1 ? (
                                <Border />
                            ) : null}
                        </FadeIn>
                    </Fragment>
                ))}
            </GroupMembersList>
        </>
    );
};

export default GroupInfoScreen;
