import React from 'react';
import {
    BatchBetStatusPopupWrapper,
    DesktopPopupCloseButton,
    DesktopPopupCloseButtonArea,
    StatusCloseButton,
} from '../components/styled';
import Wrapper from 'ui/Wrapper';
import Popup from 'ui/Popup';
import useModals from 'common/hooks/useModals';
import Status from '../components/Status';
import { useHistory } from 'react-router';
import { BetStatus } from '../types';
import useAuthCallback from 'common/hooks/useAuthCallback';

const StatusPopup = () => {
    const { hide, show, getData } = useModals();
    const data = getData('BATCH_BETTING_STATUS');
    const history = useHistory();

    const closeHandler = () => {
        hide('BATCH_BETTING_STATUS');
        if (data.betStatus !== BetStatus.failed) {
            history.push('/konto/activebets');
        } else {
            show('BATCH_BETTING');
        }
    };

    const PopupWrapper = ({ children }: any) => (
        <BatchBetStatusPopupWrapper
            status={data.betStatus}
            children={children}
        />
    );

    return (
        <Popup
            onClose={() => hide('BATCH_BETTING_STATUS')}
            //@ts-ignore
            PopupWrapperComponent={PopupWrapper}
            CloseButtonAreaComponent={DesktopPopupCloseButtonArea}
            CloseButtonComponent={DesktopPopupCloseButton}
        >
            <Wrapper padding="25px 50px">
                <Status betStatus={data.betStatus} />
            </Wrapper>
            <StatusCloseButton onClick={useAuthCallback(closeHandler)}>
                {data.betStatus === BetStatus.failed
                    ? 'OK'
                    : 'Gå til mine spil'}
            </StatusCloseButton>
        </Popup>
    );
};

export default StatusPopup;
