import { useMemo } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import moment, { Moment } from 'moment';

import { addDays, SERVER_DATE_FORMAT, subDays, today, toServerDate } from 'utils/date';

const initialState = hookstate(toServerDate(today()));

interface UseCalendarDate {
    date: string;
    setDate: (date: string) => void;
    isPreviousDateAvailable: boolean;
    isNextDateAvailable: boolean;
    mDate: Moment;
    selectNextDate: () => void;
    selectPrevDate: () => void;
    isToday: boolean;
}

const useCalendarDate = (): UseCalendarDate => {
    const dateState = useHookstate(initialState);

    const setDate = (date: string) => dateState.set(date);

    const mDate = useMemo(() => moment(dateState.get(), SERVER_DATE_FORMAT), [dateState]);

    const isPreviousDateAvailable = mDate.isAfter(moment(), 'day');

    const isNextDateAvailable = mDate.isBefore(moment().add(5, 'days'));

    const date = dateState.get();

    const selectNextDate = () => {
        setDate(addDays(date, 1, SERVER_DATE_FORMAT));
    };
    const selectPrevDate = () => {
        setDate(subDays(date, 1, SERVER_DATE_FORMAT));
    };

    const isToday = toServerDate(today()) === date;

    return {
        date: dateState.get(),
        setDate,
        isPreviousDateAvailable,
        isNextDateAvailable,
        mDate,
        selectNextDate,
        selectPrevDate,
        isToday,
    };
};

export default useCalendarDate;
