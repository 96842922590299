import React, { ComponentType, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { NEW_TRACK_PAGE } from 'configs/main';

// Define props interface for the higher-order component
export interface WithFixedOnScrollProps {
    backgroundColor: string;
    withOffsetTop: boolean;
    padding?: string;
    zIndex?: number;
    additionalStyles?: React.CSSProperties;
    offsetTopCorrection?: number;
}

const Container = styled.div`
    width: 100%;
    overflow: auto;
`;

const Placeholder = styled.div<{ isFixed: boolean; height: number | undefined }>`
    height: ${({ isFixed, height }) => (isFixed ? (height ? `${height}px` : '100px') : 'auto')};
`;

const FixedContainer = styled.div<{
    isFixed: boolean;
    backgroundColor?: string;
    padding?: string;
    zIndex?: number;
    offsetTopCorrection?: number;
    offsetTop?: number | string;
}>`
    position: ${({ isFixed }) => (isFixed ? 'fixed' : 'static')};
    top: ${({ isFixed, offsetTop, offsetTopCorrection }) =>
        isFixed ? `calc(${offsetTop}px - ${offsetTopCorrection || 0}px)` : '0'};

    ${({ isFixed, padding, backgroundColor, zIndex }) =>
        isFixed &&
        css`
            ${padding && `padding: ${padding}`};
            ${backgroundColor && `background-color: ${backgroundColor}`};
            z-index: ${zIndex || 999};
        `}
`;

const withFixedOnScroll = <P extends object>(WrappedComponent: ComponentType<P>) => {
    return (props: WithFixedOnScrollProps) => {
        const [isFixed, setIsFixed] = useState(false);
        const [topBarHeight, setTopBarHeight] = useState(0);
        const containerRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const appNavigation = window.document.getElementById('app-navigation');
            const productBar = window.document.getElementById('product-transition-bar');
            // @ts-ignore
            const overallHeight = appNavigation?.clientHeight + productBar?.clientHeight;
            setTopBarHeight(overallHeight || 140);
        }, []);

        useEffect(() => {
            const handleScroll = () => {
                if (containerRef.current) {
                    const { top } = containerRef.current.getBoundingClientRect();
                    // Update isFixed state based on scroll position
                    setIsFixed(
                        top <= (props.withOffsetTop ? containerRef.current?.offsetTop || 0 : 0)
                    );
                }
            };

            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, [props]);

        return (
            <Container ref={containerRef}>
                {/* Placeholder for maintaining layout */}
                <Placeholder isFixed={isFixed} height={containerRef.current?.offsetHeight} />
                <FixedContainer
                    style={props.additionalStyles}
                    isFixed={isFixed}
                    backgroundColor={props.backgroundColor}
                    padding={props.padding}
                    zIndex={props.zIndex}
                    offsetTop={!NEW_TRACK_PAGE ? topBarHeight : containerRef.current?.offsetTop}
                    offsetTopCorrection={props.offsetTopCorrection}
                >
                    <WrappedComponent {...(props as P)} />
                </FixedContainer>
            </Container>
        );
    };
};

export default withFixedOnScroll;
