import React from 'react';
import styled from 'styled-components';
import SlickSlider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { extractVideoId } from 'features/HorseTips/helpers';
import PlayableThumbnail from 'ui/YouTubeVideo/PlayableThumbnail.tsx';
import useModals from 'common/hooks/useModals.ts';
import Fa from 'ui/Fa';
import './slick-customizations.css';
import { byPlatform, forDesktop } from 'utils/platforms';

const settings = {
    vertical: false,
    infinite: false,
    dots: false,
    speed: 300,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    draggable: false,
    touchThreshold: 10,
    initialSlide: 0,
    lazyLoad: true,
};

const DesktopContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;
`;

const VideoItemContainer = styled.div`
    z-index: 10;
    position: relative;
    border-radius: 5px;
    overflow: hidden;

    ${forDesktop(`box-shadow: 0 5px 20px #DFE6FA;`)};
    ${forDesktop(`align-self: stretch;`)};
    ${(props) => forDesktop(getFlexBasis(props.itemsTotal, props.itemNumber))};
`;

const getFlexBasis = (itemsTotal, itemNumber) => {
    const percentForEven = 48;
    const percentForOdd = 31;

    const isEvenTotal = itemsTotal % 2 === 0;

    const flexBasis =
        isEvenTotal || itemsTotal === 1 || (!isEvenTotal && itemNumber > 3)
            ? percentForEven
            : percentForOdd;

    return `flex-basis: ${flexBasis}%`;
};

const PlayIcon = styled.div`
    position: absolute;
    border-radius: 45px;
    height: 45px;
    width: 45px;
    background: #000;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 45px / 2);
    left: calc(50% - 45px / 2);
    & > i {
        position: relative;
        left: 2px;
    }
`;

const VideoDescription = styled.div`
    padding: 25px 20px;
    color: #333;
    font-size: 20px;
    font-weight: 900;
    background: #fff;
    box-sizing: border-box;
    height: 100%;
`;

const HorseVideosSlider = ({ videos }) => {
    const { show } = useModals();

    const playVideo = (videoId, videoTitle, videoDescription) =>
        show(byPlatform('YOUTUBE_PLAYER_POPUP', 'YOUTUBE_PLAYER_POPUP_2'), 0, {
            videoId,
            videoTitle,
            videoDescription,
        });

    // videos = [
    //     {
    //         videoLink: 'FKh3JrsOf9Y',
    //         videoTitle:
    //             'Info fra V75: Mogen far sin forste sejre i en giftig V75',
    //         videoDescription:
    //             'Jimmi Ehlers har taktiken klar med 5 Ceboruco (V64-6/DD-2). - Jag laddar för ledningen. Han är mycket snabb från start och jag vill köra i spets, säger Ehlers.',
    //     },
    //     {
    //         videoLink: 'FKh3JrsOf9Y',
    //         videoTitle:
    //             'Info fra V75: Mogen far sin forste sejre i en giftig V75',
    //         videoDescription:
    //             'Jimmi Ehlers har taktiken klar med 5 Ceboruco (V64-6/DD-2). - Jag laddar för ledningen. Han är mycket snabb från start och jag vill köra i spets, säger Ehlers.',
    //     },
    //     {
    //         videoLink: 'FKh3JrsOf9Y',
    //         videoTitle:
    //             'Info fra V75: Mogen far sin forste sejre i en giftig V75',
    //         videoDescription:
    //             'Jimmi Ehlers har taktiken klar med 5 Ceboruco (V64-6/DD-2). - Jag laddar för ledningen. Han är mycket snabb från start och jag vill köra i spets, säger Ehlers.',
    //     },
    // ];

    return (
        <Container>
            {videos.map(({ videoLink, videoTitle, description }, i) => (
                <VideoSliderItem
                    key={i}
                    itemsTotal={videos.length}
                    playVideo={playVideo}
                    videoLink={videoLink}
                    videoTitle={videoTitle}
                    description={description}
                    itemNumber={i + 1}
                />
            ))}
        </Container>
    );
};

const Container = ({ children }) =>
    byPlatform(
        children.length === 1 ? (
            <div className="spiltips-video-block-box-shadow">{children}</div>
        ) : (
            <div className="slider-wrapper spiltips-videos-slider-wrapper">
                <SlickSlider {...settings}>{children}</SlickSlider>
            </div>
        ),
        <DesktopContainer>{children}</DesktopContainer>
    );

const VideoSliderItem = ({
    itemsTotal,
    playVideo,
    videoLink,
    videoTitle,
    description,
    itemNumber,
}) => (
    <VideoItemContainer
        onClick={() => playVideo(extractVideoId(videoLink), videoTitle, description)}
        itemsTotal={itemsTotal}
        itemNumber={itemNumber}
    >
        <VideoThumbnail videoLink={videoLink} />
        <VideoDescription>{description}</VideoDescription>
    </VideoItemContainer>
);

const VideoThumbnail = ({ videoLink }) => (
    <PlayableThumbnail
        videoId={extractVideoId(videoLink)}
        originalSize={'mqdefault'}
        size={'100%'}
        playButtonStyles={{
            Circle: {
                background: '#000',
                opacity: '0.7',
            },
        }}
        renderPlayIcon={() => (
            <PlayIcon>
                <Fa color={'white'} size={'x1'} section={'solid'} svgName={'play'} />
            </PlayIcon>
        )}
    />
);

export default HorseVideosSlider;
