import React from 'react';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useRacePicks, { BetPickModifier } from 'features/TrackPage/hooks/useRacePicks';
import { AnyRace, Horse } from 'features/TrackPage/model/Race';

import { PRODUCT_IDS } from 'configs/products';
import { BetPickBtn, PickButtonsContainer, ReserveNumber } from './PickButtons.styled';

import NotificationConductor from 'common/conductors/NotificationConductor';
import { byPlatform } from '../../../../../utils/platforms';

interface PickButtonsProps {
    horse: Horse;
    race: AnyRace;
    reservesMode: boolean;
}

const showNotificationScratchedHorse = () => {
    NotificationConductor.info('Hesten er udgået');
};

const showNotificationRaceStarted = () => {
    NotificationConductor.info('Det er ikke muligt at spille på dette løb, da det er gået igang');
};

const PickButtons = ({ horse, race, reservesMode }: PickButtonsProps) => {
    const { productId } = useTrackPage();

    const {
        pickStart,
        unpickStart,
        addReserve,
        removeReserve,
        isPicked,
        picked,
        reserved,
        reserveNumber,
    } = useRacePicks({ startNr: horse.startNr, race });

    const raceFinished = !race.saleOpen || race.poolClosed;
    const scratched = horse.vpPool.scratched;

    const handleClick = (e: Event, betPickModifier?: BetPickModifier) => {
        e.stopPropagation();

        if (raceFinished) {
            return;
        }

        //@TODO: check if we still need it and handle properly
        // if (this.props.raceStarted) {
        //     return showNotificationRaceStarted();
        // }

        const picked = isPicked(betPickModifier);

        if (scratched && !picked) {
            return showNotificationScratchedHorse();
        }

        if (reservesMode) {
            reserved ? removeReserve() : addReserve();
        } else {
            picked ? unpickStart(betPickModifier) : pickStart(betPickModifier);
        }
    };

    if (productId === PRODUCT_IDS.TV) {
        return (
            <PickButtonsContainer center>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e)}
                    active={picked}
                    scratched={scratched}
                    disallowed={scratched && !picked}
                >
                    {horse.startNr}
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 'U')}
                    active={isPicked('U')}
                    scratched={scratched}
                    disallowed={scratched && !isPicked('U')}
                >
                    U
                </BetPickBtn>
            </PickButtonsContainer>
        );
    }

    if (productId === PRODUCT_IDS.T) {
        return (
            <PickButtonsContainer>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 1)}
                    active={isPicked(1)}
                    scratched={scratched}
                    disallowed={scratched && !isPicked(1)}
                >
                    1
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 2)}
                    active={isPicked(2)}
                    scratched={scratched}
                    disallowed={scratched && !isPicked(2)}
                >
                    2
                </BetPickBtn>
                <BetPickBtn
                    onClick={(e: Event) => handleClick(e, 3)}
                    active={isPicked(3)}
                    disallowed={scratched && !isPicked(3)}
                >
                    3
                </BetPickBtn>
            </PickButtonsContainer>
        );
    }

    return (
        <BetPickBtn
            onClick={(e: Event) => handleClick(e)}
            active={picked}
            disallowed={scratched && !picked}
            reserved={reserved}
        >
            {horse.startNr}
            {reserved && <ReserveNumber>{reserveNumber}</ReserveNumber>}
        </BetPickBtn>
    );
};

export default PickButtons;
