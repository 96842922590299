import Horse from 'common/DataObjects/Horse';
import Driver from 'common/DataObjects/Driver';
import { PRODUCT_IDS, V_GAMES } from 'configs/products';
import get from 'lodash/get';

export default class Starts {
    driver = null;
    driverChanged = false;
    driverColour = false;
    gallopStartInfo = null;
    horse = null;
    outsideTote = null;
    /**
     * @type {number|null}
     */
    startNr = null;
    startPoint = null;
    trainer = null;
    trotStartInfo = null;
    horseStat = null;
    winnerOdds = null;
    platsOdds = null;
    VPercentage = null;
    vPercentTrend = null;
    scratched = false;
    forecastPercent = 0;
    forecastPossibleValue = null;

    constructor() {
        return this;
    }

    static unserialize(
        data,
        trackPool,
        raceNumber,
        raceIndex,
        productId,
        hostTrack
    ) {
        const starts = new Starts();

        try {
            starts.driver = Driver.unserialize(data.driver);
            starts.driverChanged = data.driverChanged;
            starts.driverColour = data.driverColour;
            starts.gallopStartInfo = data.gallopStartInfo;
            starts.horse = Horse.unserialize(data.horse);
            starts.outsideTote = data.outsideTote;
            starts.startNr = data.startNr;
            starts.startPoint = data.startPoint;
            starts.trainer = data.trainer;
            starts.trotStartInfo = data.trotStartInfo;
            starts.horseStat = data.horseStat;

            const isMultitrack = Boolean(trackPool.multiTrackVpPoolInfo);
            const [firstVpPoolItem] = trackPool[PRODUCT_IDS.V].vpPoolInfo;
            const mainTrackCode = firstVpPoolItem.track.code;

            const useCoupleTrack = Starts.shouldUseCoupleTrack(
                isMultitrack,
                hostTrack,
                mainTrackCode
            );

            const vpPoolSourcePath = useCoupleTrack
                ? 'multiTrackVpPoolInfo'
                : [PRODUCT_IDS.V, 'vpPoolInfo'];

            const oddsItemPath = useCoupleTrack
                ? 'vpOdds'
                : ['vpOdds', 'vpOdds'];

            const vinderOddsKey = useCoupleTrack ? 'vOdds' : 'vinnarOdds';

            const vpPool = get(trackPool, vpPoolSourcePath);

            if (vpPool) {
                const racePool = vpPool.find(
                    (vpPoolInfo) => vpPoolInfo.raceNumber === raceNumber
                );
                if (!racePool) {
                    console.error(
                        `No Vinder race pool found.
                         Race number: ${raceNumber},
                         race index: ${raceIndex},
                         product: ${productId}`
                    );
                    return starts;
                }

                const oddsUnit = get(racePool, oddsItemPath).find((vpOdds) => {
                    return vpOdds.startNumber === starts.startNr;
                });
                if (!oddsUnit) {
                    // No V and P odds found
                    return starts;
                }

                starts.winnerOdds = oddsUnit[vinderOddsKey].odds;
                starts.platsOdds = oddsUnit.platsOdds;
                starts.scratched = oddsUnit.scratched;
            }

            if (!trackPool[productId]) {
                return starts;
            }

            try {
                const isVProduct = V_GAMES.includes(productId);
                const isNotDouble = ![PRODUCT_IDS.LD, PRODUCT_IDS.DD].includes(
                    productId
                );

                starts
                    .addVPercentage(trackPool, productId, raceIndex)
                    .addVPercentTrend(trackPool, productId, raceIndex);

                if (isVProduct && isNotDouble && !isMultitrack) {
                    // Simple products, LD/DD (both MT and none-MT) and all multitrack V products
                    // use pool vpPoolInfo for main track legs
                    // and multiTrackVpPoolInfo for another track legs.
                    // So the following lines add data only for none-multitrack V products (except DD).
                    // Pool data of others is implemented a bit higher in this class.
                    starts
                        .addScratched(trackPool, productId, raceIndex)
                        .addForecast(trackPool, productId, raceIndex);
                }
            } catch (error) {
                console.error(error);
            }
        } catch (error) {
            console.error(error);
            //@TODO Logger of errors
        }

        return starts;
    }

    static shouldUseCoupleTrack = (isMultitrack, hostTrack, mainTrackCode) => {
        if (!hostTrack) {
            return false;
        }
        return isMultitrack && hostTrack?.code !== mainTrackCode;
    };

    getHorseMarksProperty = (
        trackPool,
        productId,
        raceIndex,
        propertyName,
        defaultValue = null
    ) => {
        if (!trackPool[productId]) {
            return defaultValue;
        }

        return trackPool[productId].hasOwnProperty('markingBetLegs') &&
            trackPool[productId].markingBetLegs.markingBetLeg[raceIndex]
                .horseMarks.markInfos[this.startNr - 1]
            ? trackPool[productId].markingBetLegs.markingBetLeg[raceIndex]
                  .horseMarks.markInfos[this.startNr - 1][propertyName]
            : defaultValue;
    };

    addVPercentage = (trackPool, productId, raceIndex) => {
        this.VPercentage = this.getHorseMarksProperty(
            trackPool,
            productId,
            raceIndex,
            'stakeDistributionPercent',
            null
        );

        return this;
    };

    addVPercentTrend = (trackPool, productId, raceIndex) => {
        this.vPercentTrend = this.getHorseMarksProperty(
            trackPool,
            productId,
            raceIndex,
            'vPercentTrend',
            null
        );

        return this;
    };

    addScratched = (trackPool, productId, raceIndex) => {
        this.scratched = this.getHorseMarksProperty(
            trackPool,
            productId,
            raceIndex,
            'scratched',
            false
        );

        return this;
    };

    addForecast = (trackPool, productId, raceIndex) => {
        this.forecastPossibleValue = this.getHorseMarksProperty(
            trackPool,
            productId,
            raceIndex,
            'possibleValue',
            null
        );

        this.forecastPercent = this.getHorseMarksProperty(
            trackPool,
            productId,
            raceIndex,
            'percent',
            null
        );

        return this;
    };

    getPlatsOdds = () => {
        const minOdds = parseFloat(
            parseFloat(this.platsOdds.minOdds.odds).toFixed(2)
        );

        const maxOdds = parseFloat(
            parseFloat(this.platsOdds.maxOdds.odds).toFixed(2)
        );

        return this.platsOdds
            ? minOdds !== maxOdds
                ? `${minOdds}-${maxOdds}`
                : maxOdds
            : 1;
    };

    getWinnerOdds = () => {
        return this.winnerOdds ? this.winnerOdds : 1;
    };

    getVPOdds = () => `${this.getWinnerOdds()}-${this.getPlatsOdds()}`;

    getOdds = (product) => {
        return product.id === PRODUCT_IDS.P
            ? this.getPlatsOdds()
            : product.id === PRODUCT_IDS.VP
                ? this.getVPOdds()
                : this.getWinnerOdds();
    };

    markAsScratched = () => {
        this.scratched = true;
    };

    isScratched = () => {
        return this.scratched;
    };

    /**
     * a = autostart (you also have "l" = linestart that happened some times a
     * year when the car with the wings is broken or if the track is loose
     * that the wheels just spin. for example.
     * If its only "M" or "K" the record is in volstart.
     *
     * M = medelrace 2140 meters,
     * K = kort 1609 or 1640 meters,
     * L = lång 2640 meters or longer
     *
     * The record time shown is the second and tenth of the time object. The minute figure is not shown.
     *
     * The record shown is the best record for the starting method to be used
     * in the race, regardless of the distance in the race.
     *
     * example result:
     * 13,7aM
     *
     * @return {string}
     */
    getRecord = () => {
        const records = this.trotStartInfo.records.records;

        if (records.length === 0) {
            return '-';
        }

        const record = records.reduce((accumulatorRec, currentRec) => {
            return this.getRecordValue(currentRec) >
                this.getRecordValue(accumulatorRec)
                ? currentRec
                : accumulatorRec;
        }, records[0]);

        const time = record.time.substring(6, 11).replace('.', ',');
        const recordCode = record.recType.code.replace('A', 'a');

        return `${time}${recordCode}`;
    };

    getRecordValue = (record) => {
        return (
            record.distance /
            parseFloat(record.time.substring(4, 8).replace(':', '.'))
        );
    };

    fill = (attributes) => {
        return Object.assign(this, attributes);
    };
}
