import React from 'react';

const TrioMassSelectionHOC = (WrappedComponent) => {
    return (props) => {
        const { unmarkTrioRow, markTrioRow, trioMarksIndicator } = props;

        const toggleTrioRow = (row) =>
            trioMarksIndicator[row - 1] ? unmarkTrioRow(row) : markTrioRow(row);

        const toggleTrio1stRow = () => toggleTrioRow(1);
        const toggleTrio2ndRow = () => toggleTrioRow(2);
        const toggleTrio3rdRow = () => toggleTrioRow(3);
        const [
            trio1stRowSelected,
            trio2ndRowSelected,
            trio3rdRowSelected,
        ] = trioMarksIndicator;

        return (
            <WrappedComponent
                {...props}
                trio1stRowSelected={trio1stRowSelected}
                trio2ndRowSelected={trio2ndRowSelected}
                trio3rdRowSelected={trio3rdRowSelected}
                toggleTrio1stRow={toggleTrio1stRow}
                toggleTrio2ndRow={toggleTrio2ndRow}
                toggleTrio3rdRow={toggleTrio3rdRow}
            />
        );
    };
};

export default TrioMassSelectionHOC;
