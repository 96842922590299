import React, { Component, Fragment } from 'react';
import { Select, SelectArrow } from './styled';

//import { byPlatform } from 'utils/platforms';

class TrioOddsDropdown extends Component {
    state = {
        start1: 0,
        start2: 0,
        start3: 0,
    };

    selectStart = (e, place) => {
        const { onTrioSelected } = this.props;
        const startNumber = parseInt(e.target.value, 10);
        this.setState({ [`start${place}`]: startNumber }, () => {
            const { start1, start2, start3 } = this.state;

            onTrioSelected &&
                this.areAllPlacesTaken() &&
                onTrioSelected(start1, start2, start3);
        });
    };

    areAllPlacesTaken = () => {
        const { start1, start2, start3 } = this.state;
        return start1 !== 0 && start2 !== 0 && start3 !== 0;
    };

    isStartSelected = (startNr, place) => {
        const { start1, start2, start3 } = this.state;
        switch (place) {
            case 1:
                return startNr === start2 || startNr === start3;
            case 2:
                return startNr === start1 || startNr === start3;
            case 3:
                return startNr === start1 || startNr === start2;
            default:
                return false;
        }
    };

    renderSelect = (place) => {
        const { race } = this.props;

        return (
            <Fragment>
                <Select
                    value={this.state[`start${place}`]}
                    onChange={(e) => this.selectStart(e, place)}
                >
                    <option value={0} disabled>
                        Nr ...
                    </option>
                    {race.starts.map(
                        (start) =>
                            this.isStartSelected(
                                start.startNr,
                                place
                            ) ? null : (
                                <option
                                    value={start.startNr}
                                    key={start.startNr}
                                >
                                    Nr. {start.startNr}
                                </option>
                            )
                    )}
                </Select>
                <SelectArrow>▾</SelectArrow>
            </Fragment>
        );
    };

    render() {
        return (
            <Fragment>
                {this.renderSelect(1)}
                {this.renderSelect(2)}
                {this.renderSelect(3)}
            </Fragment>
        );
    }
}

export default TrioOddsDropdown;
