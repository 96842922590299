import styled from 'styled-components';
import Wrapper from 'ui/Wrapper';

const FlexWrapper = styled(Wrapper)`
    display: flex;
    flex-flow: ${(props) => (props.direction ? props.direction : 'column')};
    justify-content: ${(props) => (props.align ? props.align : 'center')};
    height: ${(props) => (props.height ? props.height : '100%')};
    position: relative;
    width: ${(props) => (props.width ? props.width : '100%')};
    align-self: ${(props) => props.alignSelf || 'auto'};
    align-items: ${(props) => props.alignItems || 'unset'};
    ${({ wrap }) => wrap && `flex-wrap: ${wrap}`}
`;

export default FlexWrapper;
