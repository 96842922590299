import React from 'react';
import {
    ProductLogoContainerDef,
    BatchBettingSummaryContainerDef,
    BatchBettingProductLogoDef,
    ReplaceUploadedFileButton,
    TrashIcon,
} from './styled';
import Text from 'ui/Text';
import { PRODUCTS_IMAGES } from 'configs/products';
import Hr from 'ui/Hr';
import SystemDataTable from './SystemDataTable';
import { CustomComponents } from '../types';
import FadeIn from 'ui/animations/FadeIn';
import { ParseXMLDataResponse } from '../server';

interface SystemSummaryProps {
    fileData: ParseXMLDataResponse;
    fileName: string;
    openFileBrowser(): void;
    removeFile(): void;
}

const SystemSummary = ({
    fileData,
    fileName,
    openFileBrowser,
    removeFile,
}: SystemSummaryProps & CustomComponents) => {
    return (
        <FadeIn>
            <ProductLogoContainerDef>
                <TrashIcon onClick={removeFile} />
                <BatchBettingProductLogoDef
                    //@ts-ignore
                    src={PRODUCTS_IMAGES[fileData.total.product]}
                >
                    <ReplaceUploadedFileButton onClick={openFileBrowser}>
                        Erstat fil
                    </ReplaceUploadedFileButton>
                </BatchBettingProductLogoDef>
            </ProductLogoContainerDef>

            <BatchBettingSummaryContainerDef>
                <Text center black>
                    {fileName}
                </Text>
                <Hr />
                <SystemDataTable fileData={fileData} />
            </BatchBettingSummaryContainerDef>
        </FadeIn>
    );
};

export default SystemSummary;
