import styled from 'styled-components';
import { COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';

import PageContainerDef from 'ui/PageContainer';
import DefaultScrollBar from 'ui/ScrollBar';
import { forMobile } from 'utils/platforms';

const grid = {
    labelWidth: '90px',
    rowGap: '20px',
};

export const pagePadding = byPlatform('20px', '30px');

const columns = byPlatform(`1fr`, `${grid.labelWidth} 1fr`);

export const PageContainer = styled(PageContainerDef).attrs({
    padding: pagePadding,
})`
    overflow: visible;
`;

export const Navigation = styled.div`
    ${forMobile(`margin: 0 -${pagePadding};`)} // 
    display: grid;
    grid-template-columns: ${columns};
    grid-row-gap: ${grid.rowGap};
`;

export const Label = styled.span`
    align-self: center;
    color: ${COLORS.primaryText};
    font-weight: 900;
    font-size: 14px;
`;

export const ScrollBar = styled(DefaultScrollBar)`
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 0 ${byPlatform(pagePadding, 0)};
    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
`;

export const Row = styled.div`
    padding: ${grid.rowGap} 0;
`;

export const FixedContainer = styled.div`
    padding-right: 5px;
    width: auto;
    position: fixed;
    display: flex;
    left: 50%;
    margin: 0;
    bottom: 65px;
    justify-content: center;
    z-index: 1;
`;

export const BetslipNotifierContainer = styled.div`
    position: relative;
    left: -50%;
`;
