import Betslip from 'common/DataObjects/Betslip';
import raceDaySelector from 'common/selectors/raceDaySelector';
import { getTrackCode, getTrackFromRacingCard } from 'common/selectors/trackSelector';
import racesSelector, { getHorseCountsInRaces } from 'common/selectors/racesSelector';
import { excludeScratched, getIds } from 'common/selectors/raceStartsSelector';
import productSelector from 'common/selectors/productSelector';
import { get, uniq } from 'lodash';

/**
 * @param  {Object}  state
 * @return {Betslip}
 */
export default function betSlipSelector(state) {
    if (isSpilklubSelectionsMode(state)) {
        return new Betslip(state.BetSlip.betBuddySelections);
    }
    return new Betslip(state.BetSlip.betsByDates);
}

/**
 * @param  {Object}   state
 * @return {boolean}
 */
export function checkMinimumPicks(state) {
    const betSlip = betSlipSelector(state);

    return betSlip.checkMinimumPicks({
        date: raceDaySelector(state).date,
        trackCode: getTrackFromRacingCard(state).code,
        productId: state.AISDataProvider.selectedProduct.id,
        races: state.AISDataProvider.racingCardData.races || [],
    });
}

/**
 * @param  {Object}   state
 * @return {boolean}
 */
export function checkMaximumPicks(state) {
    const betSlip = betSlipSelector(state);

    return betSlip.checkMaximumPicks({
        date: raceDaySelector(state).date,
        trackCode: getTrackFromRacingCard(state).code,
        productId: state.AISDataProvider.selectedProduct.id,
        races: racesSelector(state),
    });
}

export function getTrioMarksIndicator(state) {
    const pr = productSelector(state);
    if (!pr.isTrio()) {
        return [false, false, false];
    }
    const { date, track } = raceDaySelector(state);
    const startIds = getIds(excludeScratched(state));
    const raceIndex = state.AISDataProvider.raceIndex;
    const horseCounts = getHorseCountsInRaces(state);

    const input = {
        date,
        trackCode: track.code,
        productId: pr.id,
        raceIndex,
        horseCounts,
    };
    const marks = betSlipSelector(state).getRaceSelection(input);

    return [
        startIds.length === getMarkedStartsForRow(marks, 1).length,
        startIds.length === getMarkedStartsForRow(marks, 2).length,
        startIds.length === getMarkedStartsForRow(marks, 3).length,
    ];
}

const getMarkedStartsForRow = (marks, row) =>
    Object.entries(marks)
        .map((m) => {
            const [startNr, marks] = m;
            return marks.includes(row) ? startNr : null;
        })
        .filter((m) => m !== null);

export const reservesPath = (date, trackCode, productId, raceIdx, betBuddy = false) => {
    const path = [
        'BetSlip',
        betBuddy ? 'betBuddyReserves' : 'reservesByDates',
        date,
        trackCode,
        productId,
    ];

    if (raceIdx !== undefined) {
        path.push(raceIdx);
    }

    return path.join('.');
};

export const getReserves = (state) => {
    const raceDay = raceDaySelector(state);
    const trackCode = getTrackCode(state);
    const product = productSelector(state);

    const reserves = get(
        state,
        reservesPath(
            raceDay.date,
            trackCode,
            product.id,
            undefined,
            isSpilklubSelectionsMode(state)
        ),
        {}
    );

    return Object.fromEntries(
        Object.entries(reserves).map(([key, reserves]) => [key, uniq(reserves)])
    );
};

/**
 * @param  {Object} state
 * @param  {{ raceDay: RaceDay, track: Track, product: Product }}
 * @return {{[raceIndex: string]: number[]}}
 */
export const getReservesBy = (state, { raceDay, product, track }) => {
    return get(state, reservesPath(raceDay.date, track.code, product.id), []);
};

export const getCurrentRaceReserves = (state) => {
    const raceDay = raceDaySelector(state);
    const product = productSelector(state);
    const trackCode = getTrackCode(state);
    const raceIdx = state.AISDataProvider.raceIndex;

    return get(
        state,
        reservesPath(raceDay.date, trackCode, product.id, raceIdx, isSpilklubSelectionsMode(state)),
        []
    );
};

export const getSelectedRaceReserves = (state, ownProps) => {
    const raceIdx = ownProps.race.index;
    const raceDay = raceDaySelector(state);
    const trackCode = getTrackCode(state);
    const product = productSelector(state);
    return get(
        state,
        reservesPath(raceDay.date, trackCode, product.id, raceIdx, isSpilklubSelectionsMode(state)),
        []
    );
};

export const isSpilklubSelectionsMode = (state) => state.BetSlip.betBuddySelectionsMode.status;

export const getSpilklubSelectionsModeTarget = (state) =>
    state.BetSlip.betBuddySelectionsMode.target;
