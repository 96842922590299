import { get } from 'lodash';
import userConductor from 'common/conductors/UserConductor';
import UserSessionAdapter from 'common/adapters/UserSession';
import persistentStorage from 'common/storage';
import { PIN_ATTEMPTS } from 'configs/main';
import { intercomReboot } from 'utils/trackers';
import { HIDE_STREAM } from 'features/StreamVideo/actions';
import { cleanBetBuddyReducer } from 'features/Spilklub/actions.ts';
import { showModal } from 'common/actions/uiActions';
import notifyAboutSelfExclusion from 'features/UserArea/SelfExclusion/notifyAboutSelfExclusion';
import { trackEvent } from 'utils/tracking';
import { isMobile } from 'utils/platforms';
import { showPinPopup } from 'utils/navigation';

export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_ERROR = 'GET_USER_ERROR';
export const SESSION_SUCCESS = 'SESSION_SUCCESS';
export const SESSION_PENDING = 'SESSION_PENDING';
export const SESSION_ERROR = 'SESSION_ERROR';
export const AUTH_PENDING = 'AUTH_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CLEAN_ERROR_MESSAGE = 'CLEAN_ERROR_MESSAGE';
export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGOUT = 'LOGOUT';
export const SET_PIN_SUCCESS = 'SET_PIN_SUCCESS';
export const SET_PIN_PENDING = 'SET_PIN_PENDING';
export const SET_PIN_ERROR = 'SET_PIN_ERROR';
export const PIN_LOGIN_SUCCESS = 'PIN_LOGIN_SUCCESS';
export const PIN_LOGIN_PENDING = 'PIN_LOGIN_PENDING';
export const PIN_LOGIN_REDUCE_ATTEMPTS = 'PIN_LOGIN_REDUCE_ATTEMPTS';
export const PIN_LOGIN_ERROR = 'PIN_LOGIN_ERROR';
export const TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_PENDING = 'TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_PENDING';
export const TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_ERROR = 'TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_ERROR';
export const TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_SUCCESS = 'TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_SUCCESS';
export const TOGGLE_FORCED_PERMISSION_SUBSCRIPTIONS_PENDING =
    'TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_PENDING';
export const TOGGLE_FORCED_PERMISSION_SUBSCRIPTIONS_ERROR =
    'TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_ERROR';
export const TOGGLE_FORCED_PERMISSION_SUBSCRIPTIONS_SUCCESS =
    'TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_SUCCESS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const RESET_PIN_PENDING = 'RESET_PIN_PENDING';
export const RESET_PIN_SUCCESS = 'RESET_PIN_SUCCESS';
export const RESET_PIN_ERROR = 'RESET_PIN_ERROR';
export const SET_TEMP_USER_STATUS = 'SET_TEMP_USER_STATUS';

export const SET_RETAIL_PIN = 'SET_RETAIL_PIN';

const createLoginErrorAction = errorMessage => ({
    type: LOGIN_ERROR,
    payload: errorMessage,
});

const createSetPinSuccessAction = (hash, userName) => ({
    type: SET_PIN_SUCCESS,
    payload: {
        hash,
        userName,
        pending: false,
        pinAttemptsLeft: PIN_ATTEMPTS,
    },
});

export const createSetTempUserAction = (status = true) => ({
    type: SET_TEMP_USER_STATUS,
    payload: status,
});

const savePinToStorage = (hash, userName) =>
    persistentStorage.saveLS({
        pinHash: hash,
        pinUserName: userName,
        pinAttemptsLeft: PIN_ATTEMPTS,
    });

export function cleanErrorMessage() {
    return dispatch => {
        dispatch({
            type: CLEAN_ERROR_MESSAGE,
            payload: {},
        });
    };
}

export function getUser() {
    return dispatch => {
        dispatch({ type: GET_USER_PENDING, payload: true });

        return userConductor
            .getUser()
            .then(User => {
                dispatch({ type: GET_USER_SUCCESS, payload: User });
                return User;
            })
            .catch(err => {
                dispatch({ type: GET_USER_ERROR, payload: err });
            });
    };
}

export function login(userName, password, setLoading, onSuccess = () => {}, onError = () => {}) {
    setLoading(true);
    return (dispatch, getState) => {
        dispatch({
            type: LOGIN_PENDING,
            payload: true,
        });

        userConductor
            .login(userName, password)
            // "catch" before "then" is supposed to handle only login method
            // response error.
            .catch(error => {
                setLoading(false);
                const errorMessage = onError(error);
                if (errorMessage) {
                    dispatch(createLoginErrorAction(errorMessage));
                    return Promise.reject(errorMessage);
                } else {
                    dispatch(showModal('FORGOT_PASSWORD', 2));
                }
            })
            .then(User => {
                onSuccess();
                setLoading(false);
                trackEvent({ event: 'Login', userId: User.id }, true);

                if (isMobile) {
                    dispatch(showPinPopup());
                }

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: User,
                });

                if (User.isSelfExcluded()) {
                    notifyAboutSelfExclusion();
                }

                return userConductor.actualizeHash().catch(error => {
                    dispatch({ type: SET_PIN_ERROR, payload: error });
                    return Promise.reject(error);
                });
            })
            .then(pinHash => {
                dispatch(createSetPinSuccessAction(pinHash, userName));
                savePinToStorage(pinHash, userName);
            })
            // catch at the end will handle all the js (not server) errors
            .catch(error => createLoginErrorAction(error.message));
    };
}

export function terminalLogin(UserSessionAdapter) {
    return dispatch => {
        dispatch({
            type: LOGIN_PENDING,
            payload: true,
        });

        UserSessionAdapter.getUser()
            .then(authData => {
                if (!authData.error && authData.user) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                } else {
                    console.log('TS. Restoring from "then" terminalLogin', authData);

                    let message = 'GettingSessionNetworkError';
                    if (authData.error === 'SESSION IKKE FUNDET') {
                        message = authData.error;
                    }

                    dispatch({
                        type: LOGIN_ERROR,
                        payload: message, //authData.error,
                    });
                }
            })
            .catch(error => {
                console.log('TS. Restoring from "catch" terminalLogin', error);

                dispatch({
                    type: LOGIN_ERROR,
                    payload: 'GettingSessionNetworkError', //error.message,
                });
            });
    };
}

export function updateUserInfo(authData) {
    return dispatch => {
        const user = get(authData, 'user', null);
        if (user && user.balance === 0) {
            UserSessionAdapter.checkout();
            UserSessionAdapter.eraseSession();

            dispatch({
                type: LOGOUT,
                payload: true,
            });
            dispatch({
                type: cleanBetBuddyReducer().type,
                payload: true,
            });
        } else if (user) {
            dispatch({
                type: SESSION_SUCCESS,
                payload: user,
            });
        } else {
            dispatch({
                type: SESSION_ERROR,
                payload: authData.error,
            });
        }
    };
}

export function logout() {
    return dispatch => {
        dispatch({
            type: LOGOUT,
            payload: true,
        });
        dispatch({
            type: cleanBetBuddyReducer().type,
            payload: true,
        });
        dispatch({
            type: HIDE_STREAM,
            payload: false,
        });

        intercomReboot();
        userConductor.logout();
    };
}

export function isUserLoggedIn({ preventSideEffects } = {}) {
    return (dispatch, getState) => {
        dispatch({
            type: AUTH_PENDING,
            payload: true,
        });

        return userConductor
            .isUserLoggedIn()
            .then(User => {
                if (User) {
                    dispatch({
                        type: SESSION_SUCCESS,
                        payload: User,
                    });

                    return User;
                } else {
                    dispatch({
                        type: AUTH_PENDING,
                        payload: false,
                    });

                    if (getState().auth.user && !preventSideEffects) {
                        dispatch({
                            type: LOGOUT,
                            payload: true,
                        });
                        dispatch({
                            type: cleanBetBuddyReducer().type,
                            payload: true,
                        });
                        dispatch({
                            type: HIDE_STREAM,
                            payload: false,
                        });

                        intercomReboot();

                        dispatch(showModal('SESSION_EXPIRED'));
                    }

                    return false;
                }
            })
            .catch(() => {
                dispatch({
                    type: AUTH_PENDING,
                    payload: false,
                });
            });
    };
}

export function setRetailPin(pin) {
    return { type: SET_RETAIL_PIN, payload: pin };
}

export function refreshBalance() {
    return isUserLoggedIn();
}

export function setUser(user) {
    return dispatch => {
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: user,
        });
    };
}

export function setPin(pin) {
    return (dispatch, getState) => {
        const {
            auth: {
                user: { userName },
            },
        } = getState();

        dispatch({ type: SET_PIN_PENDING });

        return userConductor
            .setPin(pin)
            .then(hash => {
                savePinToStorage(hash, userName);
                return dispatch(createSetPinSuccessAction(hash, userName));
            })
            .catch(error => {
                dispatch({
                    type: SET_PIN_ERROR,
                    payload: { error, pending: false },
                });
            });
    };
}

export function disablePin() {
    return dispatch => {
        dispatch({ type: RESET_PIN_PENDING });

        userConductor
            .resetPin()
            .then(() => {
                dispatch({ type: RESET_PIN_SUCCESS });
                persistentStorage.removeLS(['pinHash', 'pinUserName', 'pinAttemptsLeft']);
            })
            .catch(errorMessage => dispatch({ type: RESET_PIN_ERROR, payload: errorMessage }));
    };
}

export function pinLogin(pin) {
    return (dispatch, getState) => {
        const {
            auth: {
                pin: { attemptsLeft, hash, userName },
            },
        } = getState();

        dispatch({ type: PIN_LOGIN_PENDING });

        return userConductor
            .pinLogin(pin, hash, userName)
            .then(User => {
                persistentStorage.saveLS({ pinAttemptsLeft: attemptsLeft });

                trackEvent({ event: 'Login', userId: User.id }, true);

                dispatch({
                    type: PIN_LOGIN_SUCCESS,
                    payload: { attemptsLeft: PIN_ATTEMPTS, error: null },
                });

                if (User.isSelfExcluded()) {
                    notifyAboutSelfExclusion();
                }

                return User;
            })
            .then(User => {
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: User,
                });

                return User;
            })
            .catch(error => {
                // const message = format(t.pinLogin.error, [attemptsLeft - 1]);

                dispatch({
                    type: PIN_LOGIN_ERROR,
                    payload: error,
                });
                dispatch(reducePinAttempts(attemptsLeft));

                return Promise.reject(error);
            });
    };
}

export function reducePinAttempts(currentAttempts) {
    const attemptsReached = currentAttempts === 0;
    const updatedAttempts = attemptsReached ? currentAttempts : currentAttempts - 1;

    persistentStorage.saveLS({ pinAttemptsLeft: updatedAttempts });

    return {
        type: PIN_LOGIN_REDUCE_ATTEMPTS,
        payload: {
            attemptsLeft: updatedAttempts,
        },
    };
}

export function toggleNewsletterSubscription() {
    return dispatch => {
        dispatch({ type: TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_PENDING });

        return userConductor
            .toggleNewsletterSubscription()
            .then(() => {
                dispatch({ type: TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_SUCCESS });
            })
            .catch(() => {
                dispatch({ type: TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_ERROR });
            });
    };
}
