import React from 'react';

import { RaceCardWrapper, TableHeader, TableHeaderCol, TableRow, TableCol } from './styled';

import { AnyRace, VHorse } from 'features/TrackPage/model/Race';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';

import { RaceCardPreloader } from '../common/Preloader';
import PickButtons from '../common/PickButtons';
import { PRODUCT_IDS } from 'configs/products';
import RaceCardHeader from './RaceCardHeader';

const { T, TV } = PRODUCT_IDS;

interface RaceCardProps {
    race: AnyRace;
}

const nrColText = { [T]: 'Placering', [TV]: 'Tvilling' };

const RaceCard = ({ race }: RaceCardProps) => {
    const {
        raceDay,
        racesLoading,
        isVStrictProduct: isVProduct,
        productId,
        product,
    } = useTrackPage();

    const { reservesMode, toggleReservesMode } = useReservesMode();

    if (racesLoading) {
        return <RaceCardPreloader />;
    }

    return (
        <RaceCardWrapper>
            <RaceCardHeader
                raceDay={raceDay}
                race={race}
                reservesMode={reservesMode}
                toggleReservesMode={toggleReservesMode}
                isVProduct={isVProduct}
            />

            <>
                {/* Table Header */}
                <TableHeader isVProduct={isVProduct} productId={productId}>
                    <TableHeaderCol align={'center'}>
                        {nrColText[productId] ?? 'Nr.'}
                    </TableHeaderCol>
                    <TableHeaderCol>Hest/Kusk</TableHeaderCol>
                    {isVProduct && <TableHeaderCol>{product.name}%</TableHeaderCol>}
                    <TableHeaderCol>V-Odds</TableHeaderCol>
                </TableHeader>

                {/* Content */}
                {race.horses.map((horse, idx) => {
                    const scratched = horse.vpPool.scratched;
                    const allowed = !scratched;
                    const VPercentage = (horse as VHorse).VPool?.stakeDistributionPercent;
                    return (
                        <TableRow
                            key={idx}
                            isVProduct={isVProduct}
                            productId={productId}
                            data-test-id={horse.vpPool.scratched ? 'scratched' : 'outright'}
                        >
                            <TableCol align={'center'} bold>
                                <PickButtons
                                    horse={horse}
                                    race={race}
                                    reservesMode={reservesMode}
                                />
                            </TableCol>
                            <TableCol capitalize bold short strike={scratched}>
                                {horse.horse.horseNameAndNationality?.toLowerCase() ?? '--'}
                            </TableCol>
                            {isVProduct && (
                                <TableCol bold>
                                    {allowed && VPercentage ? VPercentage + '%' : '-'}
                                </TableCol>
                            )}
                            <TableCol bold>{allowed ? horse.vpPool.vinnarOdds.odds : '-'}</TableCol>
                        </TableRow>
                    );
                })}
            </>
        </RaceCardWrapper>
    );
};

export default RaceCard;
