import DatePickerReducer from 'ui/DatePicker/reducer';
import AISDataReducer from 'features/AISDataProvider/reducer';
import BetSlipReducer from 'features/BetSlip/state/reducer';
import StreamVideoReducer from 'features/StreamVideo/reducer';
import ScanModalReducer from 'features/ScanLogicTerminal/reducer';
import ScreenKeyboardReducer from 'ui/ScreenKeyboard/reducer';
import ShopConfig from 'configs/ShopConfig/reducer';
import MobileBetReducer from 'features/MobileBet/state/reducer';
import BetBuddyReducer from 'features/Spilklub/reducer';
import BatchBettingReducer from 'features/BatchBetting/reducer';
import { IS_TERMINAL } from 'utils/platforms';

const FeaturesReducer = {
    DatePicker: DatePickerReducer,
    AISDataProvider: AISDataReducer,
    BetSlip: BetSlipReducer,
    StreamVideo: StreamVideoReducer,
    MobileBet: MobileBetReducer,
    BetBuddy: BetBuddyReducer,
    BatchBetting: BatchBettingReducer,
};

if (IS_TERMINAL) {
    FeaturesReducer.ScanModal = ScanModalReducer;
    FeaturesReducer.ScreenKeyboard = ScreenKeyboardReducer;
    FeaturesReducer.ShopConfig = ShopConfig;
}

export { FeaturesReducer };
