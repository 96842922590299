import React from 'react';
import styled, { css } from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { Campaign, SectionName } from './useCampaignsQuery';

const mobileBreakpoint = '460px';

export const SectionTitle = styled.h3`
    font-weight: 900;
    font-size: 36px;
    color: ${COLORS.black};
    @media (max-width: ${mobileBreakpoint}) {
        font-size: 24px;
        &:first-of-type {
            margin: 42px 0 24px;
        }
    }
`;

export const Delimiter = styled.div`
    width: 100%;
    height: 1px;
    background: ${COLORS.lightGrey};
`;

export const Grid = styled.div`
    display: grid;
    gap: 15px;
    margin-bottom: 24px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 700px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: ${mobileBreakpoint}) {
        margin-bottom: 25px;
    }
`;

export const PreviewContainer = styled.div`
    border: 1px solid ${COLORS.lightGrey};
    border-radius: 5px;
    background: ${BACKGROUNDS.white};
    cursor: pointer;

    img {
        width: 100%;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        aspect-ratio: 16/5 auto;
    }

    transition: box-shadow 400ms ease-in-out;

    &:hover {
        box-shadow: 0 0 25px ${COLORS.blockShadow};
    }
`;

export const PreviewContent = styled.div`
    padding: 16px 36px 24px 36px;
`;

export const PreviewTitle = styled.p`
    font-weight: 900;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: ${COLORS.black};
`;

const resetCMSStyles = css`
    p,
    span {
        margin: 0;
        padding: 0;
        /** Override styles that applied from the CMS editor **/
        font-family: 'Roboto Condensed', sans-serif !important;
    }
`;

export const PreviewTeaser = styled.p`
    font-size: 16px;
    line-height: 21px;
    margin: 12px 0;
    font-family: 'Roboto Condensed', sans-serif !important;
    @media (max-width: ${mobileBreakpoint}) {
        font-size: 14px;
    }
    ${resetCMSStyles};
    /** Override styles that applied from the CMS editor **/
    p,
    span {
        font-size: 16px !important;
        line-height: 21px !important;
    }
`;

export const PreviewText = styled.div`
    font-size: 14px;
    line-height: 21px;
    color: #8c94a0;
    margin: 12px 0;
    @media (max-width: ${mobileBreakpoint}) {
        font-size: 12px;
    }
    ${resetCMSStyles};
    /** Override styles that applied from the CMS editor **/
    p,
    span {
        font-size: 14px !important;
        line-height: 21px !important;
    }
`;

const Badge = styled.span`
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 10px;
    padding: 4px 16px;
    margin-bottom: 8px;
`;

export const GreenBadge = styled(Badge)`
    background: #eafeed;
    color: #008615;
`;

export const CampaignBadge = ({ campaign }: { campaign: Campaign }) => {
    if (campaign.section_name === SectionName.news) {
        return <GreenBadge>Nyhed</GreenBadge>;
    }

    return null;
};

/** Single Campaign styled components **/

export const CampaignTopImage = styled.img`
    width: 100%;
`;

export const CampaignTitle = styled.h1`
    font-weight: 900;
    font-size: 40px;
    color: ${COLORS.black};
    @media (max-width: ${mobileBreakpoint}) {
        margin-top: 0;
        font-size: 32px;
    }
`;

export const CampaignContainer = styled.div`
    max-width: 630px;
    margin: 0 auto;
    a {
        text-decoration: none;
    }
`;

export const CampaignContent = styled.div`
    color: #4f555e;
    font-size: 18px;
    line-height: 28px;
    @media (max-width: ${mobileBreakpoint}) {
        font-size: 16px;
        line-height: 24px;
    }
`;

export const CampaignRules = styled.div`
    color: #4f555e;
    margin-top: 24px;
    padding: 24px;
    border: 1px solid #dfe5ed;
    background: #f0f4fa;
    border-radius: 4px;
    font-size: 14px;
    line-height: 21px;
`;

export const CampaignRulesHeadline = styled.p`
    font-weight: 900;
    @media (max-width: ${mobileBreakpoint}) {
        margin-top: 0;
    }
`;

export const CampaignVideo = styled.section`
    padding-top: 24px;
`;

/** Other Campaigns **/

export const OtherCampaignsContainer = styled.section`
    max-width: 944px;
    margin: 0 auto;
    @media (max-width: ${mobileBreakpoint}) {
        /** This hack is supposed to make this block wider than its parent **/
        position: relative;
        left: -32px;
        width: calc(100vw - 32px);
        padding: 16px;
    }
`;

export const OtherCampaignsTitle = styled.h3`
    font-weight: 900;
    font-size: 32px;
    margin: 42px 0 24px;
    color: ${COLORS.black};
    @media (max-width: ${mobileBreakpoint}) {
        &:first-of-type {
            margin: 32px 0;
        }
    }
`;
