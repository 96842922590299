import React, { useRef } from 'react';
import { useDidUpdate } from 'rooks';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useURLParameters from 'features/TrackPage/hooks/useURLParameters';

import { history } from 'utils/navigation';
import { byPlatform } from 'utils/platforms';

import { PreloaderButtons, RaceLineNavigationButton } from 'ui/RaceNavigation';
import Text from 'ui/Text';
import { ScrollBar } from '../styled';
import { NEW_TRACK_PAGE } from 'configs/main';

const RaceButtons = ({
    raceLineItems = [],
    legacyRacesData = [],
    legacySetRace = (raceOrderNumber: number, idx: number) => {},
    legacySelectedRace = 0,
}) => {
    const { race: selectedRace, generateURL } = useURLParameters();
    const { isVProduct, calendarLoading, betType, races, multitrackSetup, raceDay } =
        useTrackPage();

    const raceLineRef = useRef();

    const racesData = NEW_TRACK_PAGE ? races : legacyRacesData;

    const raceNumbers = !NEW_TRACK_PAGE
        ? raceLineItems
        : multitrackSetup
        ? multitrackSetup.bettingRaceNumbers
        : betType.races;

    const onSelectRace = (raceOrderNumber: number, idx: number) => {
        if (!NEW_TRACK_PAGE) {
            legacySetRace(raceOrderNumber, idx);
        } else {
            history.push(generateURL({ race: raceOrderNumber }));
        }
    };

    useDidUpdate(() => {
        if (!NEW_TRACK_PAGE) {
            history.push(generateURL({ race: legacySelectedRace }));
        }
    }, [legacySelectedRace]);

    const rowProps = { padding: '0', size: 'x1', weight: '900', color: 'inherit' };

    return calendarLoading ? (
        <PreloaderButtons count={byPlatform(6, 9)} width={'45px'} height={'50px'} />
    ) : (
        <ScrollBar data-test-id="races" ref={byPlatform(raceLineRef, null)}>
            {raceNumbers.map((raceNumber, idx) => {
                const raceOrderNumber = idx + 1;
                //@ts-ignore
                const saleOpen = racesData[idx]?.saleOpen;

                return (
                    <RaceLineNavigationButton
                        key={idx}
                        onClick={() => onSelectRace(raceOrderNumber, idx)}
                        active={
                            (NEW_TRACK_PAGE ? Number(selectedRace) : legacySelectedRace) ===
                            raceOrderNumber
                        }
                        accent={!saleOpen && !isVProduct}
                    >
                        <Text {...rowProps}>{isVProduct ? 'Afd.' : 'Løb'}</Text>
                        <Text {...rowProps}>{isVProduct ? raceOrderNumber : raceNumber}</Text>
                    </RaceLineNavigationButton>
                );
            })}
        </ScrollBar>
    );
};

export default RaceButtons;
