import React from 'react';

import { RaceDay, getRaceDays } from 'features/EventBoard/server/calendar';

import DateSelection from 'features/EventBoard/components/DateSelection';
import TrackSelection from 'features/EventBoard/components/TrackSelection';

import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import useCalendarDate from 'features/EventBoard/hooks/useCalendarDate';
import useRaceDayState from 'features/EventBoard/hooks/useRaceDayState';

import { SelectionScreenContainer } from './styled';

import { NEW_TRACK_PAGE } from 'configs/main';
import { history } from 'utils/navigation';

const SelectionScreen = () => {
    const { date, isToday } = useCalendarDate();
    const { data, isSuccess, isLoading } = useCalendarQuery({ date, useRefetchInterval: isToday });

    const { setRaceDay } = useRaceDayState();
    const onRaceDaySelected = (raceDay: RaceDay) => {
        if (NEW_TRACK_PAGE) {
            return history.push(
                `/spil/${date}/Vinder/${raceDay.trackName}/${raceDay?.nextSaleOpeRaceNumber || 1}`
            );
        }
        setRaceDay(raceDay);
    };

    const raceDays = isSuccess ? getRaceDays(data) : [];

    return (
        <>
            <SelectionScreenContainer>
                <DateSelection />

                <TrackSelection
                    onRaceDaySelected={onRaceDaySelected}
                    onProductSelected={() => {}}
                    isLoading={isLoading}
                    raceDays={raceDays}
                />
            </SelectionScreenContainer>
        </>
    );
};

export default SelectionScreen;
