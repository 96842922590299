import styled from 'styled-components';
import { byPlatform, forDesktop, forTerminal } from 'utils/platforms';

const Select = styled.select`
    font-weight: 800;
    font-size: 14px;
    padding: 8px 8px 8px 15px;
    background: #ebeff6;
    border-radius: 10px;
    border: 0;
    color: #8c9aae;
    -webkit-appearance: none;
    width: 90px;
    cursor: pointer;
    &:first-of-type {
        margin-left: 15px;
    }
    ${byPlatform(
        null,
        `
        height: 50px;
        width: 240px;
        background: #F5F7FA;
        border: 1px solid #E3E8EF;
        border-radius: 18px;
    `,
        `
        height: 50px;
        width: 240px;
        background: #F5F7FA;
        border: 1px solid #E3E8EF;
        border-radius: 18px;
    `
    )};
    ${forTerminal(`
        height: 50px;
        width: 240px;
        background: #F5F7FA;
        border: 1px solid #E3E8EF;
        border-radius: 18px;
    `)};
`;

const SelectArrow = styled.span`
    position: relative;
    left: -28px;
    font-size: 20px;
    line-height: 32px;
    color: #8c9aae;
    transform: scaleX(1.2);
    ${forDesktop(`
        line-height: 48px;
        transform: scaleX(0.9) scaleY(1.4);
    `)};
    ${forTerminal(`
        line-height: 48px;
        transform: scaleX(0.9) scaleY(1.4);
        left: -40px;
    `)};
`;

export { Select, SelectArrow };
