import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Spinner from 'ui/Spinner';
import Button from 'ui/controls/Button';
import BettingHistoryItem from './BettingHistoryItem';

import getTexts from 'utils/localization';
const t = getTexts();

export default (props) => {
    return (
        <Wrapper>
            {props.data && props.data.length > 0 ? (
                props.data.map((item, index) => (
                    <BettingHistoryItem
                        key={index}
                        data={item}
                        mode={props.mode}
                        showModal={props.showModal}
                    />
                ))
            ) : !props.isPending ? (
                <Text align="center">{t.gamesNotFound}</Text>
            ) : null}

            {props.isPending ? (
                <Spinner size="x2" />
            ) : props.data.length > 0 &&
            props.dataCounter > props.pageNamber * props.pageSize ? (
                <Button
                    secondary
                    size="x2"
                    onClick={props.loadMore}
                    block
                    width="200px"
                >
                    {t.loadMore}
                </Button>
            ) : null}
        </Wrapper>
    );
};
