import React, { useEffect, useState } from 'react';
import {
    PopupWrapper,
    PopupNoteWrapper,
    PopupNoteButtonGroup,
} from '../Mobile/styled';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import { useDispatch } from 'react-redux';
import NotificationConductor from '../../../common/conductors/NotificationConductor';
import { CloseButton } from './styled';
import { TransparentDesktopModalLayer } from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';
import { byPlatform } from '../../../utils/platforms';
import Spinner from 'ui/Spinner';
import { leaveGroup } from '../server';
import getTexts from '../../../utils/localization';
import { clearGroupsReducer, fetchGroupsAsync } from '../actions';
import { useHistory } from 'react-router-dom';
import useGroups from '../hooks/useGroups';

interface LeaveGroupPopupProps {
    setBackground(bg: string): void;
    modalData: {
        groupData: {
            userId: number;
            groupId: number;
        };
        onClose(): void;
        onConfirm(): void;
    };
}

const t = getTexts();

const LeaveGroupPopup = ({
    modalData,
    setBackground,
}: LeaveGroupPopupProps) => {
    const { groupData, onClose, onConfirm } = modalData;
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const history = useHistory();
    const { fetchMemberGroups } = useGroups();
    const leaveGroupAction = () => {
        setPending(true);
        leaveGroup(groupData.groupId, groupData.userId)
            .then((response) => {
                setPending(false);
                if (response.success) {
                    history.push('/spil-klub25'); //clean up url
                    dispatch(clearGroupsReducer());
                    fetchMemberGroups();
                    NotificationConductor.success(
                        t.bb.group.leaveGroupNotification
                    );
                    onConfirm();
                } else {
                    NotificationConductor.error(response.errorMessage);
                    onClose();
                }
            })
            .catch((err) => {
                NotificationConductor.error('ERROR');
                console.log(err);
            });
    };

    useEffect(() => {
        setBackground('transparent');
    }, []);

    return (
        <TransparentDesktopModalLayer>
            <FlexWrapper padding="0">
                <PopupWrapper>
                    <CloseButton
                        style={{
                            position: 'absolute',
                            top: byPlatform(0, '45px'),
                            left: 0,
                        }}
                        onClick={() => {
                            onClose();
                        }}
                    />

                    <PopupNoteWrapper>
                        <Text size="x3" color="black" bold center>
                            {t.bb.group.leaveGroupPopupText}
                        </Text>
                        <PopupNoteButtonGroup>
                            <Button
                                block
                                margin="20px auto"
                                rounded="20px"
                                accent
                                size="x3"
                                width="80%"
                                onClick={onClose}
                            >
                                {t.bb.group.leaveGroupDiscard}
                            </Button>
                            <Button
                                width="80%"
                                block
                                red
                                rounded="20px"
                                size="x3"
                                onClick={leaveGroupAction}
                            >
                                {pending ? (
                                    <Spinner grey />
                                ) : (
                                    t.bb.group.leaveGroupConfirm
                                )}
                            </Button>
                        </PopupNoteButtonGroup>
                    </PopupNoteWrapper>
                </PopupWrapper>
            </FlexWrapper>
        </TransparentDesktopModalLayer>
    );
};

export default LeaveGroupPopup;
