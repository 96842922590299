import React from 'react';
import { CloseButton, QPCard, QPWrapper } from 'features/QuickPick/styled';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';
import { withRouter } from 'react-router-dom';
import { PRODUCT_BACKGROUNDS } from 'features/QuickPick/index';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { isUserLoggedIn } from 'common/actions/authActions';
import { IS_TERMINAL } from 'utils/platforms';
import QuickPickReceipt from './QuickPickReceipt';
import getTexts from 'utils/localization';

const t = getTexts();

class QuickPickReceiptModal extends React.Component {
    onCancelHandler = () => {
        this.props.hideModal('QUICK_PICK_RECEIPT');
    };

    goToHistory = () => {
        this.props.history.push('/mine-spil');
        this.props.hideModal('QUICK_PICK_RECEIPT');
    };

    componentDidMount() {
        if (this.props.useDesktopModal === undefined) {
            this.props.enableCloseButton(<Text white>Lynspil</Text>);
        } else {
            this.props.useDesktopModal();
            this.props.setBackground('rgba(0,0,0,0.7)');
        }
        this.props.isUserLoggedIn();
    }

    render() {
        const { product, track, date } = this.props.modalData.modalAmountData.item;

        let { receipt } = this.props.modalData;

        let legs = this.props.modalData.receipt.coupon.legs;

        if (IS_TERMINAL) {
            try {
                legs = JSON.parse(this.props.modalData.receipt.coupon.receipt).coupon.legs;
            } catch (e) {
                console.log('legs', e);
            }

            try {
                receipt = JSON.parse(this.props.modalData.receipt.coupon.receipt);
            } catch (e) {
                console.log('legs', e);
            }
        }

        return (
            <QPWrapper
                bg={
                    PRODUCT_BACKGROUNDS[product.id]
                        ? `url("${PRODUCT_BACKGROUNDS[product.id]}")`
                        : product.color
                }
                radius={this.props.useDesktopModal !== undefined ? 10 : 0}
            >
                <Scrollbars universal={true}>
                    <CloseButton onClick={this.onCancelHandler}>
                        <span className="triangle" />
                        {t.QuickPick.label}
                    </CloseButton>
                    <Wrapper height="auto" padding="15% 10%">
                        <QPCard white>
                            <QuickPickReceipt
                                product={product}
                                track={track}
                                date={date}
                                legs={legs}
                                receipt={receipt}
                            />
                        </QPCard>
                    </Wrapper>
                </Scrollbars>
            </QPWrapper>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
});

export default withRouter(connect(() => {}, mapDispatchToProps)(QuickPickReceiptModal));
