import React, { useRef } from 'react';
import SlickSlider from 'react-slick';

import RaceCard from 'features/TrackPage/components/RaceCard/Mobile/RaceCard';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useURLParameters from 'features/TrackPage/hooks/useURLParameters';

import { history } from 'utils/navigation';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { SlickCustomizations } from './styled';

const RaceCardSlider = () => {
    const { generateURL } = useURLParameters();
    const { races, productId, race } = useTrackPage();

    const slickRef = useRef(null);

    const onSelectRace = (raceIndex: number) => {
        history.push(generateURL({ race: raceIndex + 1 }));
    };

    const syncSliderWithURL = (newIndex: number) => {
        const sliderRemounted = newIndex !== -1;

        if (sliderRemounted) {
            setTimeout(() => onSelectRace(newIndex), 200);
        }
    };

    const settings = {
        vertical: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex: number, newIndex: number) => {
            syncSliderWithURL(newIndex);
        },
        draggable: false,
        swipeToSlide: true,
        touchThreshold: 10,
        initialSlide: race.index,
        dots: false,
        arrows: false,
    };

    return (
        <SlickCustomizations>
            <SlickSlider
                ref={slickRef}
                key={race.index + productId}
                {...settings}
                lazyLoad={'progressive'}
            >
                {races.map((race, i) => (
                    <RaceCard race={race} key={i} />
                ))}
            </SlickSlider>
        </SlickCustomizations>
    );
};

export default RaceCardSlider;
