import React from 'react';
import classNames from 'classnames';

import RaceCardHeader from './RaceCardHeader';
import RaceCardActionsRow from './RaceCardActionsRow';

import { VHorse } from 'features/TrackPage/model/Race';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';

import { RaceCardWrapper, TableHeader, TableCol, TableHeaderCol, TableRow } from './styled';
import { PRODUCT_IDS } from 'configs/products';

import PickButtons from '../common/PickButtons';
import { RaceCardPreloader } from '../common/Preloader';
import Odds from '../common/Odds';
import WeightOrShoeCol from './WeightOrShoeCol';
import EarningCol from './EarningCol';
import KrStartCol from './KrStartCol';
import StarterCol from './StarterCol';

import InlineError from 'ui/InlineError';
import getTexts from 'utils/localization';

const t = getTexts();

const { T, TV, K, P, VP } = PRODUCT_IDS;

const nrColTexts = {
    [T]: 'Placering',
    [TV]: 'Tvilling',
    [K]: 'Plac.',
};

const oddsTexts = {
    [P]: 'P-Odds',
    [VP]: 'V-Odds/P-Odds',
};

const RaceCardDesktop = () => {
    const { race, productId, product, isVProduct, racesLoading, racesError } = useTrackPage();

    const { reservesMode } = useReservesMode();

    const isGallop = race?.trotRaceInfo?.startMethod === null;
    const isTrio = productId === T;
    const nrColText = nrColTexts[productId] ?? 'Nr.';
    const oddsColText = oddsTexts[productId] ?? 'V-Odds';

    //@TODO: Remove !race.horses check when navigation to track page without race number is fixed (see SelectionScreen.onRaceDaySelected handler)
    if (racesLoading || !race.horses) {
        return <RaceCardPreloader />;
    }

    if (racesError) {
        return <InlineError>{t.RacingCard.error}</InlineError>;
    }

    return (
        <RaceCardWrapper white>
            <RaceCardHeader />
            <RaceCardActionsRow />
            {/* Race card table */}
            <TableHeader gallop={isGallop} productId={productId} isVProduct={isVProduct}>
                {/* Nr./Placering/Tvilling */}
                <TableHeaderCol center={!isTrio} className={classNames({ 'pl-20': isTrio })}>
                    {nrColText}
                </TableHeaderCol>

                <TableHeaderCol>Hest/Kusk</TableHeaderCol>
                {isVProduct && <TableHeaderCol center>{product.name}%</TableHeaderCol>}
                <TableHeaderCol center>{oddsColText}</TableHeaderCol>
                <TableHeaderCol lg>Træner</TableHeaderCol>
                {isGallop && <TableHeaderCol>Formtal</TableHeaderCol>}
                <TableHeaderCol>{isGallop ? 'Vægt' : 'Sko'}</TableHeaderCol>
                <TableHeaderCol>Penge</TableHeaderCol>
                <TableHeaderCol>Kr/Start</TableHeaderCol>
                <TableHeaderCol md>Starter Livs</TableHeaderCol>
            </TableHeader>

            {race.horses.map((horse, idx) => {
                const scratched = horse.vpPool.scratched;
                const allowed = !scratched;
                const VPercentage = (horse as VHorse).VPool?.stakeDistributionPercent;

                return (
                    <TableRow
                        key={idx}
                        isVProduct={isVProduct}
                        productId={productId}
                        data-test-id={horse.vpPool.scratched ? 'scratched' : 'outright'}
                        gallop={isGallop}
                    >
                        <TableCol className={classNames({ 'pl-15': isTrio })} align={'center'} bold>
                            <PickButtons horse={horse} race={race} reservesMode={reservesMode} />
                        </TableCol>
                        <TableCol capitalize bold short strike={scratched}>
                            {horse.horse.horseNameAndNationality?.toLowerCase() ?? '--'}
                        </TableCol>
                        {isVProduct && (
                            <TableCol bold align={'center'}>
                                {allowed && VPercentage ? VPercentage + '%' : '0%'}
                            </TableCol>
                        )}
                        <TableCol bold align={'center'}>
                            {allowed ? <Odds horse={horse} productId={productId} /> : '-'}
                        </TableCol>
                        <TableCol solid lg>
                            {horse.trainer.name + ' ' + horse.trainer.surName}
                        </TableCol>
                        {isGallop && (
                            <TableCol solid>{horse.gallopStartInfo?.rating || '-'}</TableCol>
                        )}
                        <TableCol solid>
                            <WeightOrShoeCol horse={horse} gallop={isGallop} />
                        </TableCol>
                        <TableCol solid>
                            <EarningCol horse={horse} />
                        </TableCol>
                        <TableCol solid>
                            <KrStartCol horse={horse} />
                        </TableCol>
                        <TableCol solid md>
                            <StarterCol horse={horse} />
                        </TableCol>
                    </TableRow>
                );
            })}
        </RaceCardWrapper>
    );
};

export default RaceCardDesktop;
