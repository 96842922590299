import React, { useEffect } from 'react';
import TrackBar from 'features/EventBoard/components/TrackBar';
import ProductsRow from './components/Products/ProductsRow';

import useURLParameters from './hooks/useURLParameters';

import RacesRow from './components/RaceLine/RacesRow';
import { PageContainer, Navigation } from './components/styled';

import { byPlatform, forMobile } from 'utils/platforms';
import useTrackPage from './hooks/useTrackPage';
import RaceInfo from './components/RaceCard/Mobile/RaceInfo';
import RaceCardSlider from './components/RaceCard/Mobile';
import RaceCardDesktop from './components/RaceCard/Desktop';
import ProductTipsRow from './components/ProductTips/ProductTipsRow';
import BetslipButtonRow from './components/RaceCard/Mobile/BetslipButtonRow';
import RaceCardReplays from './components/RaceCardReplays';

import InlineError from 'ui/InlineError';

const TrackPage = () => {
    const { syncURLParameters } = useURLParameters();
    const { raceDay, calendarFetched, race, races, calendarError } = useTrackPage();

    useEffect(() => {
        syncURLParameters();
    }, [syncURLParameters]);

    //console.log(races);

    const hideNavigation = calendarFetched && raceDay.trackId === 0;

    if (calendarError) {
        return <InlineError>Could not display the race calendar.</InlineError>;
    }

    return (
        <div data-test-id="home-page-betting-root">
            <TrackBar />

            <PageContainer>
                {!hideNavigation && (
                    <Navigation>
                        <ProductsRow />
                        <RacesRow />
                    </Navigation>
                )}

                <ProductTipsRow />
                <RaceCardReplays />

                {forMobile(<RaceInfo race={race} />)}

                {byPlatform(<RaceCardSlider />, <RaceCardDesktop />)}

                {forMobile(<BetslipButtonRow />)}
            </PageContainer>
        </div>
    );
};

export default TrackPage;
