import {
    DEFAULT_SELECTED_PRODUCT,
    DERBY_LYN_GAMES,
    HORSE_MARKS_SPLIT_BY_PLACES,
    DEFAULT_COMBINATION_PRICES,
    PRODUCT_IDS,
    PRODUCTS_IMAGES,
    PRODUCTS_NAMES,
    PRODUCTS_COLORS,
    PRODUCTS_PRIORITIES,
    STRICT_V_GAMES,
    V_GAMES,
} from 'configs/products';

class ProductList {
    static data = null;
    static getAll = () => {
        if (!ProductList.data) {
            ProductList.data = ProductList.getProductsFromIds(
                Object.keys(PRODUCTS_PRIORITIES)
            ).sort((a, b) => a.priority > b.priority);
        }
        return ProductList.data;
    };

    static getProductsFromIds = (productIds) => {
        return productIds.map((productId) =>
            Object.assign(new Product(), {
                id: productId,
                name: PRODUCTS_NAMES[productId],
                priority: PRODUCTS_PRIORITIES[productId],
                color: PRODUCTS_IMAGES[productId],
            })
        );
    };

    static getById = (id) => ProductList.getAll().find((product) => product.id === id);
    static getByName = (name) =>
        ProductList.getAll().find((product) => product.name.toLowerCase() === name.toLowerCase());
    static getDefaultProduct = () =>
        ProductList.getAll().find((p) => p.id === DEFAULT_SELECTED_PRODUCT.id);
}

class Product {
    id = null;
    name = null;
    priority = null;
    color = null;
    isMultitrack = false;
    // disabled field means that current product isn't available for betting
    disabled = false;
    races = Promise.resolve([]);

    constructor() {
        return this;
    }

    setAll(id, name, priority, color, isMultitrack = false, raceDay = null) {
        const defaultProduct = ProductList.getAll().find((p) => p.id === id);

        this.id = id;
        this.name = name || (defaultProduct && defaultProduct.name);
        this.priority = priority || (defaultProduct && defaultProduct.priority);
        this.color = color || (defaultProduct && defaultProduct.color);
        this.isMultitrack = isMultitrack;
        this.raceDay = raceDay;

        return this;
    }

    getName({ strictBet }) {
        if (strictBet) {
            return this.id === PRODUCT_IDS.V64 || this.id === PRODUCT_IDS.V65
                ? 'V6'
                : this.id === PRODUCT_IDS.GS75
                ? 'GS7'
                : this.id;
        } else {
            return this.id;
        }
    }

    /**
     * Determines if the product is V-type
     * @return {boolean}
     */
    isVProduct() {
        return V_GAMES.some((vProductId) => vProductId === this.id);
    }

    isStrictVProduct() {
        return STRICT_V_GAMES.some((vProductId) => vProductId === this.id);
    }

    isVButNotDouble() {
        return this.isVProduct() && ![PRODUCT_IDS.LD, PRODUCT_IDS.DD].includes(this.id);
    }

    canManuallyReserve() {
        return this.isVProduct() && ![PRODUCT_IDS.LD, PRODUCT_IDS.DD].includes(this.id);
    }

    /**
     * Determines if the product is V-type
     * @return {boolean}
     */
    static IsVProduct(id) {
        return V_GAMES.some((vProductId) => vProductId === id);
    }

    /**
     * Determines if the product is avalible for Lynspil
     * @return {boolean}
     */
    isAvalibleForDerbyLyn() {
        return DERBY_LYN_GAMES.some((productId) => productId === this.id);
    }

    static isVProduct(productId) {
        return V_GAMES.some((vProductId) => vProductId === productId);
    }

    isTrio = () => this.id === PRODUCT_IDS.T;
    isTvilling = () => this.id === PRODUCT_IDS.TV;
    isKomb = () => this.id === PRODUCT_IDS.K;
    horseMarksSplitByPlaces = () => HORSE_MARKS_SPLIT_BY_PLACES.includes(this.id);

    raceDay = null;

    static unserialize(data, isMultitrack = false, raceDay = null) {
        const product = new Product();

        try {
            product.id = data.code;
            product.name = data.domesticText;
            product.priority = PRODUCTS_PRIORITIES[data.code];
            product.color = PRODUCTS_IMAGES[data.code];
            product.isMultitrack = isMultitrack;
            product.raceDay = raceDay;
        } catch (error) {
            //@TODO Logger of errors
            console.log(error);
        }

        return product;
    }

    getBasicPrice() {
        return DEFAULT_COMBINATION_PRICES[this.id] || 1;
    }

    getRaces = async () => {
        return this.races;
    };

    /**
     * Determines if a decimal bet amount allowed for the product.
     * @return {boolean}
     */
    decimalsAllowed = () => {
        const decimalsAllowedFor = [PRODUCT_IDS.V75, PRODUCT_IDS.V86];
        return decimalsAllowedFor.indexOf(this.id) !== -1;
    };

    static fill(attributes) {
        return Object.assign(new Product(), attributes);
    }
}

const createById = (productId, isMultitrack = false) =>
    new Product().setAll(
        productId,
        PRODUCTS_NAMES[productId],
        PRODUCTS_PRIORITIES[productId],
        PRODUCTS_COLORS[productId],
        isMultitrack
    );

export default Product;

export { ProductList, createById };
