import { useEffect } from 'react';
import { getBetInfoByHash, joinGroup } from 'features/Spilklub/server';
import NotificationConductor from '../conductors/NotificationConductor';
import { getGroupAsync, getPoolAsync, setUIProps } from 'features/Spilklub/actions';
import { useDispatch, useSelector } from 'react-redux';
import { SCREENS } from 'features/Spilklub/config';
import { getPoolHash, getStatus } from 'features/Spilklub/selectors';
import useAuth from './useAuth';
import { saveLS } from 'common/storage/localStor';
import { getUrl, history, openLogin } from 'utils/navigation';

interface useJoinGroupProps {
    userId: number;
    prevUserId: number;
    sharedLink?: boolean;
    displayScreen(screen: string): void;
}

const useJoinGroup = ({
    userId = 0,
    prevUserId = 0,
    sharedLink = false,
    displayScreen = () => {},
}: useJoinGroupProps) => {
    const [betInfoPending, betInfoError, betInfoSuccess] = useSelector((state) =>
        getStatus(state, 'activeBetsInfo')
    );
    const dispatch = useDispatch();

    const auth = useAuth();

    const poolHash = useSelector(getPoolHash);

    useEffect(() => {
        if (
            (sharedLink && userId && !prevUserId) ||
            (sharedLink && userId !== prevUserId && userId !== 0)
        ) {
            getBetInfoByHash(poolHash).then((info) => {
                if (info.data) {
                    joinGroup(info.data.pool.poolId, userId).then((res) => {
                        if ((res.data && res.success) || res.statusCode === 201) {
                            console.log(res);
                            dispatch(getPoolAsync.request(info.data.pool.poolId));
                            dispatch(
                                getGroupAsync.request(info.data.pool.poolTemplate.poolTemplateId)
                            );
                            dispatch(
                                setUIProps({
                                    activePoolId: info.data.pool.poolId,
                                })
                            );
                        } else {
                            NotificationConductor.error(res.errorMessage);
                        }
                    });
                } else {
                    NotificationConductor.error(info.errorMessage);
                }
            });
        }
    }, [userId]);

    useEffect(() => {
        if (sharedLink && !userId && !auth.pending && auth.login.initiated) {
            saveLS({ redirectAfterReg: history.location.pathname });

            dispatch(
                openLogin({
                    priority: 1,
                    redirectOnAuth: getUrl(history.location.pathname),
                })
            );
        }
    }, [userId, auth.pending, auth.login.initiated]);

    useEffect(() => {
        if (sharedLink && betInfoSuccess && !betInfoPending) {
            displayScreen && displayScreen(SCREENS.POOL_DASHBOARD);
        } else if (sharedLink && !betInfoSuccess && !betInfoPending && betInfoError) {
            NotificationConductor.error(betInfoError);
        }
    }, [userId, betInfoPending, betInfoSuccess]);
};

export default useJoinGroup;
