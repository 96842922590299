import React, { useRef } from 'react';
import InfiniteCalendar from 'react-infinite-calendar';

import { Section, SplitScreen } from './layouts/SplitScreen';
import ScrollBar from './ScrollBar';
import daLocale from 'date-fns/locale/da';
import Wrapper from './Wrapper';
import Button from './controls/Button';
import { DatePickerSection } from 'ui/DatePicker';
import { DATEPICKER_THEME_CONFIG } from 'utils/theme-helpers';

const defaultMinDate = new Date(); // default infinite scroll value
const defaultMaxDate = new Date(2050, 11, 31);

const DatePicker2 = ({
    date,
    onConfirm,
    minDate = defaultMinDate,
    maxDate = defaultMaxDate,
    width = 0,
    height = 0,
}) => {
    // using ref instead of state, since it will not be shown anywhere and used as a mediator value
    const dateRef = useRef(date);

    const onDateChosen = (chosenDate) => {
        dateRef.current = chosenDate;
    };
    const onDateSelected = () => onConfirm(dateRef.current);

    return (
        <SplitScreen>
            <DatePickerSection white height={'90%'} width={width || window.innerWidth}>
                <ScrollBar orientation="hidden">
                    <InfiniteCalendar
                        minDate={minDate}
                        maxDate={maxDate}
                        selected={date}
                        onSelect={onDateChosen}
                        theme={DATEPICKER_THEME_CONFIG}
                        width={width || window.innerWidth}
                        height={height || window.innerHeight - 205}
                        rowHeight={70}
                        locale={{
                            locale: daLocale,
                            blank: 'Vælg en dato ...',
                            headerFormat: 'ddd, MMM DD',
                            todayLabel: {
                                long: 'I dag',
                            },
                            months: 'januar, februar, marts, april, maj, juni, juli, august, september, oktober, november, december'.split(
                                ','
                            ),
                            monthsShort: 'jan,feb,mar2,apr2,maj,jun,jul,aug,sep,okt,nov,dec'.split(
                                ','
                            ),
                            weekdays: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'],
                            weekStartsOn: 0,
                        }}
                    />
                </ScrollBar>
            </DatePickerSection>
            <Section grey height="10" align="center">
                <Wrapper padding="0px" align="center">
                    <Button onClick={onDateSelected} shadow="10" size="x1" width="100px">
                        OK
                    </Button>
                </Wrapper>
            </Section>
        </SplitScreen>
    );
};

export default DatePicker2;
