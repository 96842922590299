import React, { Component } from 'react';
import * as StyledLogin from 'features/Login/styled';
import * as StyledPin from './styled';
import HeaderBanner from 'features/Login/HeaderBanner';
import Spinner from 'ui/Spinner';
import Keyboard from 'features/Login/Pin/Keyboard';
import Wrapper from 'ui/Wrapper';
import Bullets from './Bullets';

class Pin extends Component {
    state = { value: '', bulletsRenderingDelayPast: false };

    close = (e) => {
        e.stopPropagation();
        this.props.onClose && this.props.onClose();
    };

    componentDidMount() {
        this.props.enableCloseButton &&
            this.props.enableCloseButton(<StyledLogin.CloseButton right onClick={this.close} />);

        setTimeout(() => {
            this.setState({ bulletsRenderingDelayPast: true });
        }, 1000);
    }

    addPinNumber = (number) => {
        this.setState({ value: this.state.value + number }, () => {
            const { value } = this.state;
            const { pinLength, onPinFullfiled } = this.props;

            if (value.length === pinLength) {
                onPinFullfiled(value);
            }
        });
    };

    removePinNumber = () => {
        this.setState({ value: this.state.value.slice(0, -1) }, this.props.onPinNumberRemoved);
    };

    renderForgotPassword = () => (
        <StyledPin.ForgotPassword
            onClick={() => this.props.forceCommonLogin && this.props.forceCommonLogin()}
        >
            Glemt kodeord?
        </StyledPin.ForgotPassword>
    );

    render() {
        const { loading, showForgotPasswordButton, shownAfterSignup, pinLength } = this.props;
        return (
            <HeaderBanner>
                <StyledPin.BulletsWithTItle>
                    <StyledPin.Title>
                        {shownAfterSignup ? 'Opret en PIN kode' : 'Log ind med PIN kode'}
                    </StyledPin.Title>
                    {this.state.bulletsRenderingDelayPast && (
                        <StyledPin.Bullets>
                            <Bullets
                                pinLength={pinLength}
                                processingLength={this.state.value.length}
                            />
                        </StyledPin.Bullets>
                    )}
                </StyledPin.BulletsWithTItle>
                <Wrapper padding="0 30px 15px">
                    {showForgotPasswordButton && this.renderForgotPassword()}
                    {loading ? (
                        <Spinner />
                    ) : (
                        <Keyboard onAddValue={this.addPinNumber} onRemove={this.removePinNumber} />
                    )}
                </Wrapper>
            </HeaderBanner>
        );
    }
}

export default Pin;
