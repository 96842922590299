import React from 'react';
import { CloseButton } from './styled';
import { BetBuddyBar } from '../Mobile/styled';
import { byPlatform } from 'utils/platforms';
import { BackButton } from './styled';
import useAuth, { Auth } from 'common/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import { priority } from 'configs/layout';
//@ts-ignore
import { TopBar } from '@it25syv/25syv-ui';
import Text from 'ui/Text';
import FadeIn from 'ui/animations/FadeIn';

interface ToolbarProps {
    expanded: boolean;
    goBack(): void;
    close(): void;
    onBurgerMenuClick?(): void;
    mobileModalMode?: boolean;
    backButton?: boolean;
}

const Toolbar = ({ expanded, goBack, close, onBurgerMenuClick, mobileModalMode }: ToolbarProps) => {
    const auth: Auth = useAuth();

    const dispatch = useDispatch();

    const renderTopBar = () => (
        <TopBar
            background={'#00A556'}
            onBurgerMenuClickHandler={onBurgerMenuClick}
            userEmail={auth.user ? auth.user.email : ''}
            username={auth.user ? auth.user.userName : ''}
            balance={auth.user ? auth.user.balance : ''}
            secondColor={'#008444'}
            bankClickHandler={() => dispatch(showModal('DEPOSIT', 1))}
            showUserArea={() => dispatch(showModal('USER_AREA_MENU'))}
            signUpHandler={() => dispatch(showModal('SIGNUP'))}
            showLoginModal={() => dispatch(showModal('LOGIN'))}
            withoutSidebar={true}
        />
    );

    const renderTopBarWithCloseButton = () => (
        <>
            <CloseButton onClick={close} padding={'20px'} position={'absolute'} priority={10} />
            {renderTopBar()}
        </>
    );

    return (
        <BetBuddyBar poolMode={expanded}>
            {expanded ? (
                <>
                    <FadeIn>
                        <BackButton
                            onClick={goBack}
                            padding={'10px'}
                            style={{ zIndex: priority.topBar }}
                        />
                    </FadeIn>
                    <Text white style={{ paddingLeft: '20px' }}>
                        Tilbage
                    </Text>
                    {byPlatform(
                        null,
                        <FadeIn>
                            <CloseButton onClick={close} padding={'20px'} />
                        </FadeIn>
                    )}
                </>
            ) : (
                byPlatform(
                    mobileModalMode ? renderTopBarWithCloseButton() : renderTopBar(),
                    <CloseButton onClick={close} padding={'20px'} />
                )
            )}
        </BetBuddyBar>
    );
};

export default Toolbar;
