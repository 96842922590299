import zipWith from 'lodash/zipWith';
import omit from 'lodash/omit';

import { RaceCardData, Start } from './raceCard';
import { MarkInfo, MarkingBetLeg, TrackPool, VPOdds, VPool, VPPoolInfoItem } from './pool';

import { ProductId } from 'features/EventBoard/server/calendar';
import { Race } from './raceCard';
import {
    Horse,
    Race as RaceModel,
    RaceCustomAttributes,
    VHorse,
    VRace as VRaceModel,
} from '../model/Race';
import { isVProduct } from '../model/Product';

declare type IndexedRace = Race & Pick<RaceCustomAttributes, 'index'>;

export const deserializeRaces = (
    racesData: RaceCardData,
    poolData: TrackPool,
    productId: ProductId,
    mainTrackId: number,
    isMultitrack: boolean
): Array<RaceModel | VRaceModel> => {
    const sortedRaces = racesData.races.races.sort(sortRaces);

    const sortedPoolItems = poolData.V.vpPoolInfo.sort(sortByRaceNumber);

    const isV = isVProduct(productId);

    const races = isV ? sortedRaces : fixRacesInconsistency(sortedRaces, sortedPoolItems);

    const indexedRaces = races.map((race, index) => ({ ...race, index }));

    const withVPPoolData = mergeVPOdds(indexedRaces, poolData, isV, isMultitrack, mainTrackId);

    if (isV && poolData[productId]?.markingBetLegs?.markingBetLeg) {
        const markingBetLegs = poolData[productId].markingBetLegs.markingBetLeg;
        return mergeVOdds(withVPPoolData, markingBetLegs, poolData[productId]);
    }

    return withVPPoolData;
};

const mergeVPOdds = (
    races: IndexedRace[],
    poolData: TrackPool,
    isVProduct: boolean,
    isMultitrack: boolean,
    mainTrackId: number
) => {
    const vpPoolInfo = poolData.V.vpPoolInfo;

    let vpPoolItems = vpPoolInfo.sort(sortByRaceNumber);

    // V Product races also need VP pool items, here we leave only the pool items that are included in appropriate racing card
    if (isVProduct && !isMultitrack) {
        const raceNumbers = races.map(race => race.raceNumber);
        vpPoolItems = vpPoolItems.filter(poolItem => raceNumbers.includes(poolItem.raceNumber));
    }

    if (isVProduct && isMultitrack) {
        vpPoolItems = extractVPOddsForMultitrack(races, poolData, vpPoolItems, mainTrackId);
    }

    return zipWith<Race & Pick<RaceCustomAttributes, 'index'>, VPPoolInfoItem, RaceModel>(
        races,
        vpPoolItems,
        (race, racePool) => {
            const horses = extractHorsesWithVPPoolData(race, racePool);
            return omit({ ...race, ...racePool, horses }, ['starts', 'vpOdds']);
        }
    );
};

const mergeVOdds = (races: RaceModel[], markingBetLegs: MarkingBetLeg[], VPool: VPool) => {
    return zipWith<RaceModel, MarkingBetLeg, VRaceModel>(
        races,
        markingBetLegs,
        (race, markingBetLeg) => {
            const horses = extractHorsesWithVPoolData(race, markingBetLeg);
            return omit(
                {
                    ...race,
                    ...markingBetLeg,
                    saleOpen: VPool.saleOpen,
                    poolClosed: VPool.poolClosed,
                    horses,
                },
                ['horseMarks']
            );
        }
    );
};

const extractHorsesWithVPPoolData = (race: Race, vpPoolItem: VPPoolInfoItem): Horse[] => {
    // data in the response can be in the wrong order, so it's better to sort by start numbers
    const sortedHorses = race.starts.starts.sort(sortStarts);
    const sortedPoolAttributes = vpPoolItem.vpOdds.vpOdds.sort(sortStartsPoolAttrs);
    //const horses = fixHorsesInconsistency(sortedHorses, sortedPoolAttributes);

    return zipWith<Start, VPOdds, Horse>(
        sortedHorses,
        sortedPoolAttributes,
        (horse: Start, horsePoolAttrs: VPOdds): Horse => {
            return { ...horse, vpPool: horsePoolAttrs };
        }
    );
};

const extractHorsesWithVPoolData = (race: RaceModel, markingBetLeg: MarkingBetLeg) => {
    const sortedHorseMarks = markingBetLeg.horseMarks.markInfos;

    return zipWith<Horse, MarkInfo, VHorse>(race.horses, sortedHorseMarks, (horse, markInfo) => {
        return { ...horse, VPool: markInfo };
    });
};

const extractVPOddsForMultitrack = (
    races: Race[],
    poolData: TrackPool,
    vpPoolItems: VPPoolInfoItem[],
    mainTrackId: number
) => {
    const placeholderVPPoolItem = {} as VPPoolInfoItem;

    return races.map(race => {
        if (race.hostTrackId === mainTrackId) {
            return (
                vpPoolItems.find(vpPoolItem => vpPoolItem.raceNumber === race.raceNumber) ??
                placeholderVPPoolItem
            );
        }

        const coupleTrackVpPoolInfoItem = poolData.multiTrackVpPoolInfo.find(
            vpPoolItem => vpPoolItem.raceNumber === race.raceNumber
        );

        if (coupleTrackVpPoolInfoItem) {
            return {
                ...placeholderVPPoolItem,
                vpOdds: {
                    vpOdds: coupleTrackVpPoolInfoItem.vpOdds.map(vpOdds => ({
                        investmentPlats: null,
                        investmentVinnare: null,
                        scratched: vpOdds.scratched,
                        startNumber: vpOdds.startNumber,
                        // change property name to make it correspond appropriate field in regular VPPoolInfoItem
                        vinnarOdds: vpOdds.vOdds,
                        platsOdds: {
                            maxOdds: { odds: 0, scratched: false },
                            minOdds: { odds: 0, scratched: false },
                        },
                    })),
                },
            };
        }

        return placeholderVPPoolItem;
    });
};

/**
 * Adds placeholder races if some of them were missing in the racing card response
 * @param races
 * @param vpPoolItems
 */
const fixRacesInconsistency = (races: Race[], vpPoolItems: VPPoolInfoItem[]) => {
    if (races.length === vpPoolItems.length) {
        return races;
    }

    console.warn(
        `Races and VP pool items have different number of items!. Races: ${races.length}, VP pool: ${vpPoolItems.length}`
    );

    const raceNumbers = races.map(race => race.raceNumber);
    const poolRaceNumbers = vpPoolItems.map(poolItem => poolItem.raceNumber);

    if (raceNumbers.length > poolRaceNumbers.length) {
        // all races seem to be presented, but pool items missing
        return races;
    }

    const [firstExistingRace] = races;

    return poolRaceNumbers.map(raceNumber => {
        const existingRace = races.find(race => race.raceNumber === raceNumber);

        if (existingRace) return existingRace;

        return { ...firstExistingRace, raceNumber, horses: [] };
    });
};

const fixHorsesInconsistency = (horses: Start[], poolAttributes: VPOdds[]) => {
    if (horses.length === poolAttributes.length) {
        return horses;
    }

    console.warn(
        `Starts and appropriate VP pool items have different number of items!. Horses: ${horses.length}, VP pool: ${poolAttributes.length}`
    );
};

const sortByRaceNumber = (raceA: Race | VPPoolInfoItem, raceB: Race | VPPoolInfoItem) => {
    return raceA.raceNumber > raceB.raceNumber ? 1 : -1;
};

const sortRaces = (raceA: Race, raceB: Race) => {
    if (raceA.legNumber && raceB.legNumber) {
        return raceA.legNumber > raceB.legNumber ? 1 : -1;
    }
    return sortByRaceNumber(raceA, raceB);
};

const sortStarts = (horseA: Start, horseB: Start) => (horseA.startNr > horseB.startNr ? 1 : -1);

const sortStartsPoolAttrs = (horseA: VPOdds, horseB: VPOdds) =>
    horseA.startNumber > horseB.startNumber ? 1 : -1;
