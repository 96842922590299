import React, { useState } from 'react';
import get from 'lodash/get';

import { TableHeader, TableRow, TableCell, TableContent, HorseName, HorseTip } from './styled';

import useHorseTips from 'features/HorseTips/useHorseTips';

import { Table } from 'ui/Table';
import Text from 'ui/Text';
import Icon from 'ui/Icon';
// import { PRODUCTS_NAMES } from 'configs/products';
import { byPlatform } from 'utils/platforms';

const getHorseName = (race, horseNumber) =>
    get(race, ['starts', horseNumber - 1, 'horse', 'horseNameAndNationality']);
//
// const getTitle = (trackName, productId, raceIndex) =>
//     `${PRODUCTS_NAMES[productId]}-${raceIndex + 1} ${trackName} spiltips`;

const HorseTips = ({ raceIndex, race }) => {
    const [isOpen, setIsOpen] = useState(true);
    const { horseTips, videos, trackName, productId } = useHorseTips();
    const horseTipsByRace = horseTips[raceIndex] || [];

    // const videosByRace = videos.filter(video => Number(video.raceNumber) === raceIndex + 1);

    return (
        <>
            {horseTipsByRace.length > 0 && (
                <Table>
                    <TableHeader onClick={() => setIsOpen(!isOpen)}>
                        <Text bold white size="16px" padding={'0'}>
                            {trackName} spiltips
                        </Text>
                        <Icon
                            iarrowsmooth
                            rotation={isOpen ? '0' : '180'}
                            color={'white'}
                            size={'x025'}
                            margin={byPlatform('0 0 0 5px', '0 0 0 9px')}
                        />
                    </TableHeader>
                    <TableContent isOpen={isOpen}>
                        {horseTipsByRace.map(({ horseNumber, description }) => (
                            <TableRow>
                                <TableCell>
                                    <HorseName>{horseNumber}.</HorseName>
                                </TableCell>
                                <TableCell>
                                    <HorseName>{getHorseName(race, horseNumber)}: </HorseName>
                                    <HorseTip
                                        dangerouslySetInnerHTML={{
                                            __html: description,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableContent>
                </Table>
            )}

            {/*{videosByRace.length > 0 && (*/}
            {/*    <Wrapper margin="15px 0 0" padding="0">*/}
            {/*        <HorseVideosSlider videos={videosByRace} />*/}
            {/*    </Wrapper>*/}
            {/*)}*/}
        </>
    );
};

export default HorseTips;
