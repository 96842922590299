import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import env, { byWebEnv } from 'utils/env';
import global from 'window-or-global';
import { getLS } from '../common/storage/localStor';

export const VERSION = env('VERSION', '1.0.0');
global.version = VERSION;

export const APP_MODE_TERMINAL = !global.location
    ? false
    : new URLSearchParams(global.location.search).has('terminal')
    ? true
    : process.env.REACT_APP_TERMINAL_MODE
    ? JSON.parse(process.env.REACT_APP_TERMINAL_MODE)
    : false;

if (process.env.NODE_ENV !== 'test') {
    global.T = process.env.TRANSLATES;
}

export const APP_MODE_MOBILE = 'APP_MODE_MOBILE';
export const APP_MODE_DESKTOP = 'APP_MODE_DESKTOP';

global.APP_MODE = APP_MODE_TERMINAL
    ? APP_MODE_TERMINAL
    : isMobile || isTablet || isIPad13
    ? // from some of the latest versions IPad Safari is not detected correctly
      // by react-device-detect and needs additional isIPad13 checking
      APP_MODE_MOBILE
    : APP_MODE_DESKTOP;

export const DK_THEME = 'DK';
export const DE_THEME = 'DE';
export const THEME = process.env.THEME;
export const DEBUG = true;
export const LOCALE = DK_THEME;

export const PIN_ATTEMPTS = 3;
export const PIN_LENGTH = 4;

export const LENGTH_SHORT = 3000;
export const LENGTH_LONG = 30000;

export const SUPPORT_EMAIL = `support@bet25.dk`;

export const PAYMENT_SERVICE_ID = 'Derby';
export const DOMAIN_PATH = (() => {
    if (!global.location) {
        // node environment
        return 'dev.derby25.dk';
    }

    /* derby.retail.dev.25syv.dk - dev terminal link */
    /* mgr-derby.retail.dev.25syv.dk - dev manager betting */
    if (
        ['derby.retail.dev.25syv.dk', 'mgr-derby.retail.dev.25syv.dk'].includes(
            global.location.hostname
        )
    ) {
        return 'https://cms.retail.dev.25syv.dk';
    }

    return byWebEnv(
        'https://cms.bet25.dk',
        'https://cms-2.bet25dev.dk',
        'https://cms.bet25.dk',
        'https://cms-2.bet25dev.dk/'
    );
})();

export const HIDE_MOBILE_TOOLBAR_FOR_PATH = ['spil-klub25', 'spk'];

export const SET_FULL_WIDTH_FOR_PATH = [
    '/velkommen',
    '/afmeld-nyhedsbrev',
    '/live-casino',
    '/spil/',
    '/25-nyt/',
];

export const DEPOSIT_1KR_USERS = [157628, 75117];

// Will be used as a feature flag until new homepage UI is tested on prod.
//
// New homepage UI will use features/EventBoard hooks with react-query.
//
// If set to true, AISDataProvider will not fetch calendar, set default race day and fetch racing card for it.
// It means that new features/EventBoard hooks are used for it and new UI does not require to fetch some
// default race day automatically.
//@TODO: Replace it with BE feature flag when it's ready.
export const NEW_HOMEPAGE_UI = true;

export const NEW_TRACK_PAGE = Boolean(
    JSON.parse(getLS('NEW_TRACK_PAGE', value => ['true', 'false'].includes(value), false))
);
