import { request } from 'utils/server';
import { useQuery } from '@tanstack/react-query';
import useRaceDayState from '../../EventBoard/hooks/useRaceDayState';

const fetchClubs = async () => {
    const response = await request<any>('Spilklub', 'GET', '/focus/groups');

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data?.groups;
};

const fetchClubsByDate = async (date: string | undefined, trackId: number | undefined) => {
    const response = await request<any>('Spilklub', 'GET', '/promo/group/pools', { date, trackId });
    if (!response.success) {
        throw new Error(response.errorMessage);
    }
    //@ts-ignore
    return response.data.map(pool => unserializePool(pool));
};

const unserializePool = (data: any) => {
    return {
        poolTemplateId: data.groupId,
        name: data.groupName,
        avatarUrl: data.avatarUrl,
        activePools: [data.product],
        settings: {
            sharePrice: data.sharePrice,
            sharesLimit: data.sharesLimit,
        },
        trackId: data.trackId,
        trackName: data.trackName,
        availableSharesNumber: data.availableSharesNumber,
        boughtSharesNumber: data.boughtSharesNumber,
        totalAmount: data.totalAmount,
    };
};

interface UseHomepageClubsQueryProps {
    groupsByDate?: boolean;
    enabled?: boolean;
}

const useClubsQuery = ({
    groupsByDate = false,
    enabled = true,
}: UseHomepageClubsQueryProps = {}) => {
    const { raceDay } = useRaceDayState();
    const date = raceDay?.raceDayDate;
    const trackId = raceDay?.trackId;

    const queryKey =
        groupsByDate && date && trackId
            ? ['spilklub.focusGroupsByDate', date, trackId]
            : ['spilklub.focusGroups'];

    const { status, data, error, isSuccess, isLoading } = useQuery({
        queryKey,
        queryFn: () => (groupsByDate ? fetchClubsByDate(date, trackId) : fetchClubs()),
        staleTime: 60 * 10 * 1000,
        enabled: enabled && (!groupsByDate || !!(date && trackId)),
    });

    return { data: data ?? [], status, isSuccess, isLoading, error };
};

export default useClubsQuery;
