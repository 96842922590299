import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRaceDayState from 'features/EventBoard/hooks/useRaceDayState';
import { SET_RACE_DAY_SELECTED } from 'features/AISDataProvider/actions';
import TrackDTO from 'common/DataObjects/Track';

import { findRaceDay, getRaceDays } from 'features/EventBoard/server/calendar';
import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import useShortcutState from 'features/EventBoard/hooks/useShortcutState';
import useCalendarDate from 'features/EventBoard/hooks/useCalendarDate';

import { history, isDirectEventLink } from 'utils/navigation';
import usePrevious from 'common/hooks/usePrevious';
import { getRaceDay } from 'common/selectors/raceDaySelector';
import { setDate as legacySetDate } from 'ui/DatePicker/actions';

interface UseLegacyAdoptionsProps {
    enabled?: boolean;
}

const useLegacyAdaptions = ({ enabled = false }: UseLegacyAdoptionsProps = {}) => {
    const { raceDayFound, setRaceDay, raceDay } = useRaceDayState();
    const { date, setDate } = useCalendarDate();

    const dispatch = useDispatch();

    const raceDayDate = raceDay?.raceDayDate;
    const raceDayTrackId = raceDay?.trackId;

    const { shortcut } = useShortcutState();

    // Start AISDataProvider when race day is selected in the new UI

    useEffect(() => {
        if (enabled && raceDayFound && !isDirectEventLink() && !shortcut) {
            dispatch({ type: SET_RACE_DAY_SELECTED, payload: true });
            history.push(
                `/spil/${date}/Vinder/${TrackDTO.getSlug(raceDay?.trackName)}/${
                    raceDay?.nextSaleOpeRaceNumber || 1
                }`
            );
        }
        //  eslint-disable-next-line
    }, [raceDayDate, raceDayTrackId, date, enabled, raceDayFound, shortcut, dispatch]);

    // Product shortcuts

    const shortcutDate = shortcut?.raceDate || '';
    const shortcutProductId = shortcut?.product;
    const shortcutTrackId = shortcut?.trackId;

    const { data: shortcutCalendarData, isSuccess: shortcutCalendarReady } = useCalendarQuery({
        date: shortcutDate,
        enabled: Boolean(shortcut),
    });

    const shortcutRaceDays = shortcutCalendarReady ? getRaceDays(shortcutCalendarData) : [];

    useEffect(() => {
        const shouldSetProductShortcut =
            shortcutDate && shortcutProductId && shortcutTrackId && shortcutCalendarReady;

        if (shouldSetProductShortcut && enabled) {
            const raceDay = findRaceDay(shortcutRaceDays, shortcutDate, shortcutTrackId);

            if (!raceDay) return;
            // needed for TrackBar
            setRaceDay(raceDay);

            dispatch({ type: SET_RACE_DAY_SELECTED, payload: true });

            dispatch(legacySetDate(shortcutDate));

            history.push(
                `/spil/${shortcutDate}/${shortcut?.product}/${TrackDTO.getSlug(
                    raceDay.trackName
                )}/1`
            );
        }
        //  eslint-disable-next-line
    }, [shortcutDate, shortcutProductId, shortcutTrackId, shortcutCalendarReady, enabled]);

    const directLink = isDirectEventLink();
    const legacyRaceDay = useSelector(getRaceDay);

    useEffect(() => {
        if (directLink && !raceDayFound && Boolean(legacyRaceDay?.trackId)) {
            setRaceDay(legacyRaceDay);

            setDate(legacyRaceDay?.date);
        }
        //  eslint-disable-next-line
    }, [directLink, raceDayFound, raceDay, legacyRaceDay, setDate, setRaceDay]);

    // User goes back using browser "back" button. In this case we should reset 'raceDay' state
    const prevPathname = usePrevious(history.location.pathname);

    useEffect(() => {
        if (isDirectEventLink(prevPathname) && !isDirectEventLink(history.location.pathname)) {
            // Not a direct link anymore, resetting defaults so the homepage will render tracklist again
            setRaceDay(null);
            dispatch({ type: SET_RACE_DAY_SELECTED, payload: false });
        }
        //  eslint-disable-next-line
    }, [history.location.pathname, prevPathname]);
};

export default useLegacyAdaptions;
