import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Wrapper from 'ui/Wrapper';
import { hideScanModal } from './actions';
import moment from 'moment';
import { saveJSONparsing } from 'utils/formatters';
import get from 'lodash/get';
import {
    CloseButton,
    Divider,
    Button,
    Row,
    Title,
    Terms,
    SystemInfo,
    BetslipList,
    BetInfo,
    DerbyBetsTable,
    DerbyBetslipContainer,
    GeneralRaceInfo,
    BetTableHead,
    BetTableBody,
} from './uiComponents';
import getTexts from 'utils/localization';
import { Reserve, ReserveNumber } from 'features/BetSlip/components/BetPick/styled';
import { parseInt } from 'lodash';

const t = getTexts();

const getDerbyAmount = (saleInfo) => {
    let amount = 0;
    if (saleInfo.maxTotalBetAmount) {
        amount = parseFloat(saleInfo.maxTotalBetAmount);
    }
    if (saleInfo.combinationPrice) {
        amount = parseFloat(saleInfo.combinationPrice) * parseFloat(saleInfo.combinationNumber);
    }
    return amount;
};

const trioBets = (payload) => {
    return [payload.marks1St, payload.marks2Nd, payload.marks3Rd];
};

const shouldDisplayRow = (row) => {
    const count = [...row].length;
    let display;
    for (let i = 0; i < count; i++) {
        if ([...row][i] === '1') {
            display = true;
            break;
        }
    }
    return display;
};

const getHorseNumber = (num, customClass = '') => {
    return [...num].map((el, index) => {
        if (el === '1') {
            return (
                <span key={index} className={`circle green ${customClass}`}>
                    {index + 1}
                </span>
            );
        }
    });
};

const DerbyBetslip = (props) => {
    const { payload } = props || {};

    return (
        <DerbyBetslipContainer>
            <GeneralRaceInfo>
                <p>
                    {payload.trackInfo && payload.trackInfo.name ? payload.trackInfo.name : ''}
                    {payload.trackInfo &&
                    payload.trackInfo.name &&
                    payload.productInfo &&
                    payload.productInfo.name
                        ? ':'
                        : ''}
                    {payload.productInfo && payload.productInfo.name
                        ? ` ${payload.productInfo.name}`
                        : ''}
                </p>
                <p>
                    {payload.postTime
                        ? `Start: ${moment(payload.postTime).format('DD/MM HH:mm')}`
                        : null}
                    {payload.combinationPrice || payload.maxTotalBetAmount
                        ? ` - Indskud ${getDerbyAmount(payload)} Kr.`
                        : null}
                </p>
            </GeneralRaceInfo>
            <Divider />
            <DerbyBetsTable>{props.children}</DerbyBetsTable>
        </DerbyBetslipContainer>
    );
};

const vContent = (payload) => (
    <Fragment>
        <BetTableHead>
            <span>{t.Terminal.PlayAndPay.betTable.num}</span>
            <span>{t.Terminal.PlayAndPay.betTable.horseNum}</span>
            <span>{t.Terminal.PlayAndPay.betTable.winner}</span>
        </BetTableHead>
        <BetTableBody>
            {payload.legs.map((leg, index) => {
                if (
                    (leg.selected15Horses || leg.marks) &&
                    shouldDisplayRow(leg.selected15Horses || leg.marks)
                ) {
                    const firstReserve = parseInt(leg.firstReserve, 10);
                    const secondReserve = parseInt(leg.secondReserve, 10);

                    return (
                        <li key={index}>
                            <p>
                                {leg.selected15Horses
                                    ? getHorseNumber([...leg.selected15Horses])
                                    : getHorseNumber([...leg.marks])}
                            </p>
                            {firstReserve > 1 && ' '}
                            {firstReserve > 1 && (
                                <Reserve>
                                    {firstReserve}
                                    <ReserveNumber>{1}</ReserveNumber>
                                </Reserve>
                            )}
                            {secondReserve > 1 && (
                                <Reserve>
                                    {secondReserve}
                                    <ReserveNumber>{2}</ReserveNumber>
                                </Reserve>
                            )}
                            <p>-</p>
                        </li>
                    );
                }
            })}
        </BetTableBody>
        <Divider />
    </Fragment>
);

const trioContent = (payload) => (
    <Fragment>
        <BetTableHead>
            <span>{t.Terminal.PlayAndPay.betTable.place}</span>
            <span>{t.Terminal.PlayAndPay.betTable.horseNum}</span>
        </BetTableHead>
        <BetTableBody>
            {trioBets(payload).map((leg, index) => (
                <li key={index}>
                    <p>{getHorseNumber(leg)}</p>
                    <p />
                </li>
            ))}
        </BetTableBody>
        <Divider />
    </Fragment>
);

const vinderAndPladsContent = (payload) => (
    <Fragment>
        <BetTableHead>
            <span>{t.Terminal.PlayAndPay.betTable.place}</span>
            <span>{t.Terminal.PlayAndPay.betTable.horseNum}</span>
        </BetTableHead>
        <BetTableBody start={payload.raceNumber}>
            <li>
                <p>{getHorseNumber(payload.marks)}</p>
                <p />
            </li>
        </BetTableBody>
        <Divider />
    </Fragment>
);

const ldAndDdContent = (payload) => (
    <Fragment>
        <BetTableHead>
            <span>{t.Terminal.PlayAndPay.betTable.place}</span>
            <span>{t.Terminal.PlayAndPay.betTable.horseNum}</span>
        </BetTableHead>
        <BetTableBody>
            {[payload.marks1, payload.marks2].map((leg, index) => (
                <li key={index}>
                    <p>{getHorseNumber(leg)}</p>
                    <p />
                </li>
            ))}
        </BetTableBody>
        <Divider />
    </Fragment>
);

const tvillingContent = (payload) => (
    <Fragment>
        <BetTableHead>
            <span>{t.Terminal.PlayAndPay.betTable.place}</span>
            <span>{t.Terminal.PlayAndPay.betTable.horseNum}</span>
        </BetTableHead>
        <BetTableBody>
            <li
                style={{
                    listStyle: 'none',
                    textIndent: '-5px',
                }}
            >
                <p>
                    1. / 2.
                    {getHorseNumber(payload.marks, 'tvilling')}
                </p>
                <p />
            </li>
            <li
                style={{
                    listStyle: 'none',
                    textIndent: '-5px',
                }}
            >
                <p>
                    U:
                    {getHorseNumber(payload.standoutMarks, 'tvilling')}
                </p>
                <p />
            </li>
        </BetTableBody>
        <Divider />
    </Fragment>
);

const kombContent = (payload) => (
    <Fragment>
        <BetTableHead>
            <span>{t.Terminal.PlayAndPay.betTable.place}</span>
            <span>{t.Terminal.PlayAndPay.betTable.horseNum}</span>
        </BetTableHead>
        <BetTableBody>
            {[payload.marks1St, payload.marks2Nd].map((leg, index) => (
                <li key={index}>
                    <p>{getHorseNumber(leg)}</p>
                    <p />
                </li>
            ))}
        </BetTableBody>
        <Divider />
    </Fragment>
);

class UnpaidSale extends React.Component {
    getBetAmount(payload) {
        let amount =
            payload && payload.betslipSale && payload.betslipSale.totalStake
                ? parseInt(payload.betslipSale.totalStake, 10)
                : null;

        if (typeof amount === 'number') {
            amount /= 100;
        }

        return amount;
    }

    isTrio(payload) {
        return !!(
            payload.productInfo &&
            payload.productInfo.id &&
            payload.productInfo.id === 'T' &&
            payload.marks1St &&
            payload.marks2Nd &&
            payload.marks3Rd
        );
    }

    isTvilling(payload) {
        return !!(
            payload.productInfo &&
            payload.productInfo.id &&
            payload.productInfo.id === 'TV' &&
            payload.marks &&
            payload.standoutMarks
        );
    }

    isLdOrDD(payload) {
        return !!(
            payload.productInfo &&
            payload.productInfo.id &&
            (payload.productInfo.id === 'LD' || payload.productInfo.id === 'DD') &&
            payload.marks1 &&
            payload.marks2
        );
    }

    isVinderOrPlads(payload) {
        return !!(
            payload.productInfo &&
            payload.productInfo.id &&
            (payload.productInfo.id === 'V' || payload.productInfo.id === 'P') &&
            payload.marks
        );
    }

    isKomb(payload) {
        return !!(
            payload.productInfo &&
            payload.productInfo.id &&
            payload.productInfo.id === 'K' &&
            payload.marks1St &&
            payload.marks2Nd
        );
    }

    getNameByBetText(eventName, betText) {
        const eventItems = eventName.split('-');
        switch (betText.toLowerCase()) {
            case '1':
                return eventItems[0];
            case '2':
                return eventItems[1];
            case 'x':
                return 'Uafgjort';
            case 'o':
                return 'Over';
            default:
                return betText;
        }
    }

    derbyContent(data) {
        let payload = saveJSONparsing(data.payload);

        if (payload.legs) {
            return vContent(payload);
        } else if (this.isTrio(payload)) {
            return trioContent(payload);
        } else if (this.isVinderOrPlads(payload)) {
            return vinderAndPladsContent(payload);
        } else if (this.isLdOrDD(payload)) {
            return ldAndDdContent(payload);
        } else if (this.isTvilling(payload)) {
            return tvillingContent(payload);
        } else if (this.isKomb(payload)) {
            return kombContent(payload);
        }
    }

    bet25Content(data) {
        const payload = saveJSONparsing(data.payload);
        const betList = get(payload, 'betslip.betList', []);
        const singlePrize = get(payload, 'betslip.singlePrize', '0');
        const totalPrize = get(payload, 'betslip.system.totalPrize', '0');
        const possibleWin = parseFloat(singlePrize !== '0' ? singlePrize : totalPrize).toFixed(2);

        return (
            <React.Fragment>
                <BetslipList>
                    {betList.map((bet, index) => (
                        <React.Fragment key={index}>
                            <li>
                                <div className="event-info-group">
                                    <p>
                                        {bet.eventName && bet.betText
                                            ? this.getNameByBetText(bet.eventName, bet.betText)
                                            : ''}
                                    </p>
                                    <p>{bet.marketName}</p>
                                    <p>{bet.eventName || ''}</p>
                                    <p>
                                        {bet.event ? bet.event.sport : ''}{' '}
                                        {bet.event ? `/ ${bet.event.competition}` : ''}
                                    </p>
                                </div>
                                <span className="event-odds-info">
                                    {t.Terminal.PlayAndPay.betTable.odds}: {bet.odds || ''}
                                </span>
                            </li>
                            <Divider />
                        </React.Fragment>
                    ))}
                </BetslipList>
                <BetInfo>
                    {this.getBetAmount(payload) && (
                        <p>
                            {t.Terminal.PlayAndPay.deposit}: {this.getBetAmount(payload)} Kr.
                        </p>
                    )}
                    {possibleWin !== '0' && (
                        <p>
                            {t.Terminal.PlayAndPay.possibleWin}: {possibleWin} Kr.
                        </p>
                    )}
                    {data.status && (
                        <p>
                            {t.Terminal.PlayAndPay.status}: {data.status}
                        </p>
                    )}
                </BetInfo>
            </React.Fragment>
        );
    }

    closeClickHandler = () => {
        this.props.hideScanModal();
    };

    showContent = () => {
        const { unpaid_sale } = this.props.data;
        const payload = saveJSONparsing(unpaid_sale.payload);
        let content = null;

        switch (unpaid_sale.type) {
            case 'bet25':
            case 'bet25-virtual':
                content = <React.Fragment>{this.bet25Content(unpaid_sale)}</React.Fragment>;
                break;
            case 'derby25':
                content = (
                    <React.Fragment>
                        <DerbyBetslip payload={payload}>
                            {this.derbyContent(unpaid_sale)}
                            {payload && payload.lyn ? (
                                payload.legs ? (
                                    <h3>{t.Terminal.PlayAndPay.smartLyn}</h3>
                                ) : (
                                    <h3>{t.Terminal.PlayAndPay.derbyLyn}</h3>
                                )
                            ) : (
                                ''
                            )}
                        </DerbyBetslip>
                    </React.Fragment>
                );
                break;
            default:
                content = '';
        }

        return content;
    };

    render() {
        const { unpaid_sale } = this.props.data;

        return (
            <Wrapper overflow="hidden">
                <CloseButton onClick={this.props.hideScanModal}>x</CloseButton>
                <Title condensed bold color="black" align="center">
                    {t.Terminal.PlayAndPay.title}
                </Title>

                <Wrapper>{this.showContent()}</Wrapper>

                <Terms>{t.Terminal.PlayAndPay.terms}</Terms>

                <Row>
                    <Button accent bold onClick={this.closeClickHandler} className="uppercase">
                        {t.Terminal.CouponStatus.close}
                    </Button>
                </Row>
                <SystemInfo>
                    {unpaid_sale.id ? `ID: ${unpaid_sale.id}` : null}
                    {unpaid_sale.created_at
                        ? ` - ${moment(unpaid_sale.created_at).format('Do MMMM, H:mm')}`
                        : null}
                </SystemInfo>
            </Wrapper>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideScanModal: () => {
            dispatch(hideScanModal());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UnpaidSale);
