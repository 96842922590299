import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import raceDaySelector from 'common/selectors/raceDaySelector';
import productSelector from 'common/selectors/productSelector';
import CMSAPI from 'common/api/CMSAPI';
import { PRODUCTS_SYS_NAMES } from 'configs/products.ts';
import { normalize } from 'utils/object-helper';
import useHorseVideos from '../HorseVideos/useHorseVideos';

const serialize = (raceDay, product) => ({
    track_code: raceDay.track.code,
    track_real_id: raceDay.track.id,
    product: PRODUCTS_SYS_NAMES[product.id],
    race_date: raceDay.date,
});

const parseHorseTips = data =>
    normalize(data).map(raceTips =>
        raceTips.sort((a, b) => (a.serialNumber > b.serialNumber ? 1 : -1))
    );

const useHorseTips = () => {
    const raceDay = useSelector(
        raceDaySelector,
        (prev, curr) =>
            prev.date === curr.date &&
            prev.track.id === curr.track.id &&
            prev.track.code === curr.track.code
    );

    const product = useSelector(productSelector, (prev, curr) => prev.id === curr.id);

    const [horseTips, setHorseTips] = useState([]);

    const { videos } = useHorseVideos(serialize(raceDay, product));

    useEffect(() => {
        const getHorseTips = async () => {
            try {
                const response = await CMSAPI.getHorseTips(serialize(raceDay, product));

                setHorseTips(parseHorseTips(response?.data?.races || []));
            } catch (e) {
                console.log(e);
            }
        };

        getHorseTips();
    }, [raceDay.date, raceDay.track.id, product.id, raceDay, product]);

    return {
        horseTips,
        videos,
        trackName: raceDay.track.name,
        productId: product.id,
    };
};

export default useHorseTips;
