import { useDispatch } from 'react-redux';
import { refreshBalance } from '../actions/authActions';

const useRefreshBalance = () => {
    const dispatch = useDispatch();

    return () => dispatch(refreshBalance());
};

export default useRefreshBalance;
