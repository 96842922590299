import React from 'react';

import Popup from 'ui/Popup';
import useModals from 'common/hooks/useModals';
import BatchBetting from './index';
import withTransparentBackground from 'ui/hoc/withTransparentBackground';
import Wrapper from 'ui/Wrapper';
import {
    BatchBettingPopupWrapper,
    DesktopPopupCloseButton,
    DesktopPopupCloseButtonArea,
} from './components/styled';
import { history } from 'utils/navigation';
import { ModalProps } from 'ui/types';

const Desktop = ({ modalData }: ModalProps<{ openedByInitialURL: boolean }>) => {
    const { hide } = useModals();

    return (
        <Popup
            onClose={() => {
                hide('BATCH_BETTING');
                if (modalData?.openedByInitialURL) {
                    history.push('/');
                }
            }}
            PopupWrapperComponent={BatchBettingPopupWrapper}
            CloseButtonAreaComponent={DesktopPopupCloseButtonArea}
            CloseButtonComponent={DesktopPopupCloseButton}
        >
            <Wrapper padding="25px 50px">
                <BatchBetting />
            </Wrapper>
        </Popup>
    );
};

export default withTransparentBackground(Desktop);
