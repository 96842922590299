import React, { DragEvent, useState } from 'react';
import Text from 'ui/Text';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { CustomComponents, UploadHandler } from '../types';
import Spinner from 'ui/Spinner';
import { UploadAreaContainerDef, UploadIcon } from './styled';
import Overlay from 'ui/Overlay';
import uploadIcon from 'images/icons/batch-betting/square_and_arrow_up.svg';

export interface UploadAreaProps {
    openFileBrowser(): void;
    pending: boolean;
    onUpload: UploadHandler;
    error?: string;
}

const UploadArea = ({
    openFileBrowser,
    pending,
    onUpload,
    UploadAreaContainer = UploadAreaContainerDef,
}: UploadAreaProps & CustomComponents) => {
    const [targetDragged, setTargetDragged] = useState(false);
    const [overlayDragged, setOverlayDragged] = useState(false);

    const highlighted = overlayDragged || targetDragged;

    // clicking on any area in the browser and dragging over is considered
    // also as drag events by the browser. So we have to check whether
    // is there a file or not.
    // Also we should check if some file is already being uploaded
    const isMeaningfulDrag = (e: DragEvent) => {
        return e.dataTransfer !== null && !pending;
    };

    const onDragStart = (e: DragEvent) => {
        e.preventDefault();
        // Prevent dragging the upload area itself
        // Like when clicking and dragging without any file
        e.dataTransfer.effectAllowed = 'move';
    };

    const onFileDrop = (e: DragEvent) => {
        if (!isMeaningfulDrag(e)) {
            return;
        }
        e.preventDefault();

        setTargetDragged(false);
        setOverlayDragged(false);

        if (e.dataTransfer.files.length > 1) {
            NotificationConductor.error('Only 1 file allowed.');
        } else {
            if (e.dataTransfer.files) {
                onUpload(e.dataTransfer.files);
            }
        }
    };

    const preventDragDefault = (e: DragEvent) => {
        if (!isMeaningfulDrag(e)) {
            return;
        }
        e.preventDefault();
    };

    const onTargetDragEnter = (e: DragEvent) => {
        if (!isMeaningfulDrag(e)) {
            return;
        }

        e.preventDefault();
        setTargetDragged(true);
    };

    const onTargetDragLeave = (e: DragEvent) => {
        if (!isMeaningfulDrag(e)) {
            return;
        }
        e.preventDefault();

        setTargetDragged(false);
    };

    const onOverlayDragEnter = (e: DragEvent) => {
        if (!isMeaningfulDrag(e)) {
            return;
        }
        e.preventDefault();

        setOverlayDragged(true);
    };

    const onOverlayDragLeave = (e: DragEvent) => {
        if (!isMeaningfulDrag(e)) {
            return;
        }
        e.preventDefault();

        setOverlayDragged(false);
    };

    // @ts-ignore
    return (
        <>
            <Overlay
                //faded={highlighted}
                onOverlayDragStqrt={onDragStart}
                onOverlayDragEnter={onOverlayDragEnter}
                onOverlayDragLeave={onOverlayDragLeave}
                onOverlayDrop={onFileDrop}
            />
            <UploadAreaContainer
                onClick={openFileBrowser}
                draggable={true}
                onDragStart={onDragStart}
                onDragOver={preventDragDefault}
                onDragEnter={onTargetDragEnter}
                onDragLeave={onTargetDragLeave}
                onDrop={onFileDrop}
                highlighted={highlighted}
            >
                {pending ? (
                    <Spinner size={'x3'} />
                ) : (
                    <>
                        <UploadIcon src={uploadIcon} color={'#9198AA'} />
                        <Text
                            weight={'700'}
                            black
                            size={'x1.5'}
                            padding={'5px 0'}
                            style={{
                                // needed for overlapping the overlay. onClick handlers won't work without it
                                position: 'relative',
                            }}
                        >
                            <>
                                Drag & drop eller <span style={{ color: '#2767F5' }}>Vælg fil</span>
                            </>
                        </Text>
                        <Text
                            padding={'0'}
                            color={'#9198AA'}
                            size={'x1.5'}
                            style={{
                                // needed for overlapping the overlay. onClick handlers won't work without it
                                position: 'relative',
                            }}
                        >
                            Kun .xml filer
                        </Text>
                    </>
                )}
            </UploadAreaContainer>
        </>
    );
};

export default UploadArea;
