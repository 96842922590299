import React from 'react';
import DownloadButton from './DownloadButton';
import getTexts from 'utils/localization';
import usePdfDownload from './usePdfDownload';
import useATGPdfDownload from './useATGPdfDownload';
import Link from 'ui/Link';
const t = getTexts();

const PdfDownload = () => {
    const { fileAvailable: tipsPDFAvailable, downloadPdf: downloadTipsPDF } = usePdfDownload({
        type: 'tips',
    });
    const { fileAvailable: playlistPDFAvailable, downloadPdf: downloadPlaylistPDF } =
        usePdfDownload({
            type: 'play-list',
        });

    const { pdfAvailable: ATGPageWithPDFAvailable, PDFLink: ATGLink } = useATGPdfDownload();

    return (
        <div className="flex pr-10">
            {tipsPDFAvailable && (
                <DownloadButton onClick={downloadTipsPDF}>{t.PdfDownload.tips}</DownloadButton>
            )}
            {playlistPDFAvailable && (
                <DownloadButton onClick={downloadPlaylistPDF}>
                    {t.PdfDownload.playList}
                </DownloadButton>
            )}
            {ATGPageWithPDFAvailable && (
                <Link href={ATGLink}>
                    <DownloadButton>{t.PdfDownload.courseProgram}</DownloadButton>
                </Link>
            )}
        </div>
    );
};

export default PdfDownload;
