import HistoryContainer from './HistoryContainer';

class FinishedBets extends HistoryContainer {
    pageSize = 10;

    componentDidMount() {
        this.setDataHandlerName('getFinishedBets');
        this.setMode('finished');
        super.componentDidMount();
    }
}

export default FinishedBets;
