import { TrioOddsWrapper, TrioOddsWrapperBlock } from 'features/RaceCard/styled';
import TrioOddsDropdown from 'features/RaceCard/TrioOddsDropdown';
import { byPlatform, forMobile } from 'utils/platforms';
import React, { Fragment } from 'react';
import Text from 'ui/Text';
import classNames from 'classnames';
import styled from 'styled-components';
import { COLORS } from 'themes';

const Odds = styled(Text)`
    font-size: 14px;
    text-align: center;
    color: ${COLORS.primaryText};
    width: 150px;
    line-height: 30px;
`;

const TrioOdds = ({ selectedProduct, race, trioOdds, commitTrioCombination, trioCombination }) => {
    return (
        <Fragment>
            {forMobile(
                <Text className={classNames(['text-center', 'uppercase', 'text-bold'])}>
                    Beregn trio
                </Text>
            )}
            <TrioOddsWrapperBlock>
                <TrioOddsWrapper>
                    <TrioOddsDropdown
                        race={race}
                        onTrioSelected={commitTrioCombination}
                        odds={trioOdds}
                    />
                    {byPlatform(
                        null,
                        <Odds condensed className="text-bold uppercase">
                            {trioCombination.length === 3 && `= Odds ${trioOdds.odds}`}
                        </Odds>,
                        <Odds condensed className="text-bold uppercase">
                            {trioCombination.length === 3 && `= Odds ${trioOdds.odds}`}
                        </Odds>
                    )}
                </TrioOddsWrapper>
                {forMobile(
                    <Odds condensed className="text-bold uppercase" style={{ margin: '0 auto' }}>
                        {trioCombination.length === 3 && `ODDS ${trioOdds.odds}`}
                    </Odds>
                )}
            </TrioOddsWrapperBlock>
        </Fragment>
    );
};

export default TrioOdds;
