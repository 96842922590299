import React from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import { BET_API_MARKS_STRING_LENGTH, PRODUCTS_FOR_SPLITTING_BY_LEGS } from 'configs/products';
import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';
import Button from 'ui/controls/Button';
import playIcon from '../../../images/icons/play.png';
import Icon from 'ui/Icon';

const t = getTexts();

const getBgColorOfItem = (props) => {
    const { resultNumber } = props;

    if (!props.isReserv) {
        if (props.lost) {
            return BACKGROUNDS.red;
        } else {
            if (resultNumber) {
                if (
                    resultNumber.find(
                        (res) => res.startNumber.toString() === props.children.toString()
                    )
                ) {
                    return BACKGROUNDS.primary;
                } else {
                    return BACKGROUNDS.lightGrey;
                }
            } else {
                return BACKGROUNDS.primary;
            }
        }
    } else {
        return BACKGROUNDS.blue;
    }
};

const getColorOfItem = (props) => {
    if (!props.isReserv) {
        if (props.resultNumber) {
            return props.resultNumber.find(
                (res) => res.startNumber.toString() === props.children.toString()
            )
                ? COLORS.white
                : COLORS.primaryText;
        } else {
            return COLORS.white;
        }
    } else {
        return COLORS.white;
    }
};

const SelectionView = styled.div`
    position: relative;
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    color: ${(props) => getColorOfItem(props)};
    background: ${(props) => getBgColorOfItem(props)};
    border-radius: 15px;
    font-weight: bold;
    text-align: center;
    margin: 5px 1px 0px;
    display: inline-block;
    ${(props) =>
        props.scratched
            ? `background: ${COLORS.lightGrey}; text-decoration: line-through; color: ${COLORS.white}`
            : ''};
`;

const getListItems = (resultInfo, data, group, product, isReserv) => {
    const itemsView = [];

    if (data) {
        const dataStr = data.toString();

        for (let i = 0; i < dataStr.length; i++) {
            if (dataStr[i] !== '0') {
                itemsView.push(<SelectionView key={i}>{i + 1}</SelectionView>);
            }
        }
    } else if (group) {
        if (group.length > 1) {
            if (BET_API_MARKS_STRING_LENGTH[product] !== group.length) {
                group.forEach((item, index) => {
                    itemsView.push(
                        <>
                            <SelectionView
                                isReserv={isReserv}
                                scratched={item.scratched}
                                key={index}
                                resultNumber={resultInfo ? resultInfo : false}
                            >
                                {item.startNumber}
                            </SelectionView>
                        </>
                    );
                });
            } else {
                itemsView.push(
                    <Text
                        size="x05"
                        key={1}
                        padding="9px 5px"
                        short
                        grey
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                        }}
                    >
                        <b>{t.betSlip.selectedAll}</b>
                    </Text>
                );
            }
        } else {
            itemsView.push(
                <SelectionView
                    resultNumber={resultInfo ? resultInfo : false}
                    key={group[0].startNumber}
                    scratched={group[0].scratched}
                    isReserv={isReserv}
                >
                    {group[0].startNumber}
                </SelectionView>
            );

            itemsView.push(
                <Text
                    size="x05"
                    key={group[0].startNumber + 100}
                    padding="5px"
                    short
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        textDecoration: !group[0].scratched ? 'none' : 'line-through',
                        maxWidth: '70%',
                    }}
                >
                    <b>{group[0].horseName || '--'}</b> <br />
                    {(group[0].driverName || '--') + ' ' + (group[0].driverSurname || '--')}
                </Text>
            );
        }
    }

    return (
        <Wrapper align="left" margin="0px" padding="0px" display="block">
            {itemsView}
        </Wrapper>
    );
};

const SelectionRow = (props) => {
    const isSelectionLost = (resultInfo) => {
        let scratches = 0;
        let selections = props.group
            .filter((item) => {
                item.scratched && scratches++;
                return !item.scratched;
            })
            .map((el) => el.startNumber);

        if (scratches > 0 && props.reserv && props.reserv[0]) {
            // reserve horses might also be scratched
            !props.reserv[0].scratched && selections.push(props.reserv[0].startNumber);

            if (scratches > 1 && props.reserv[1]) {
                !props.reserv[1].scratched && selections.push(props.reserv[1].startNumber);
            }
        }

        return selections.indexOf(resultInfo.startNumber) === -1;
    };

    const getRaceNumber = () => {
        if (props.productDTO.isTvilling() && props.index === 0) {
            return '1./2.';
        }

        if (props.productDTO.isTvilling() && props.index === 1) {
            return 'U';
        }

        const { group = [] } = props;

        const isLegProduct = PRODUCTS_FOR_SPLITTING_BY_LEGS.indexOf(props.productDTO.id) > -1;
        const isMultitrackProduct = props.productDTO.isVProduct() || props.productDTO.isMultitrack;

        return isLegProduct && group[0]
            ? group[0].legNumber
            : isMultitrackProduct || !group[0]
            ? props.index + 1
            : group[0].raceNumber;
    };

    const replaysIcon = (res, key = '') => (
        <Button
            key={key}
            onClick={() => {
                props.onReplayClick(res.raceNumber, res.trackId);
            }}
            size="x1"
            rounded={5}
            padding="10px"
            margin="0"
            width={byPlatform('73px', '90px')}
            accent
            bg={'#ECEFF6'}
            bold
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                }}
            >
                {'Afspil'}{' '}
                <Icon
                    src={playIcon}
                    color="black"
                    style={{
                        width: '14px',
                        height: '14px',
                        cursor: 'pointer',
                        opacity: 0.7,
                        transition: 'opacity 500ms ease-in',
                    }}
                />
            </div>
        </Button>
    );

    return (
        <Wrapper padding="5px" data-test-id="selection-row">
            <Row>
                <Col xs={2} style={{ margin: '0px', padding: '0px' }}>
                    <Text bold="500" padding="9px 0px" align="center">
                        {getRaceNumber()}
                    </Text>
                </Col>
                <Col
                    xs={6}
                    style={{
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    {!props.isReserv
                        ? props.data
                            ? getListItems(props.resultInfo, props.data, null, props.product)
                            : getListItems(
                                  props.resultInfo,
                                  null,
                                  props.group,
                                  props.product,
                                  false
                              )
                        : getListItems(props.resultInfo, null, props.reserv, props.product, true)}
                </Col>
                <Col
                    xs={4}
                    style={{
                        margin: '0px',
                        padding: '0px',
                        textAlign: 'center',
                    }}
                >
                    {props.resultInfo &&
                    (!props.productDTO.isTvilling() ||
                        (props.productDTO.isTvilling() && props.index === 0)) ? (
                        props.resultInfo
                            .sort((a, b) => (a.place < b.place ? 1 : -1))
                            .map((res, index) => {
                                return props.showReplays ? (
                                    replaysIcon(res, index)
                                ) : (
                                    <SelectionView
                                        key={res.startNumber}
                                        lost={
                                            props.productDTO && props.productDTO.isTvilling()
                                                ? Number(props.winAmount) === 0
                                                : isSelectionLost(res, props.resultInfo)
                                        }
                                    >
                                        {res.startNumber}
                                    </SelectionView>
                                );
                            })
                    ) : (
                        <Text bold="500" padding="0px 0px" align="center">
                            {props.productDTO.isTvilling() ? '' : '-'}
                        </Text>
                    )}
                </Col>
            </Row>
        </Wrapper>
    );
};

export default SelectionRow;
export { SelectionView };
