import React, { ComponentType, FC, useEffect } from 'react';
import { ModalProps } from '../types';

const withTransparentBackground = <T extends ModalProps<D>, D>(
    WrappedComponent: ComponentType<T>
): FC<T> => (props: T) => {
    useEffect(() => props.setBackground('none'), []);

    return <WrappedComponent {...props} />;
};

export default withTransparentBackground;
