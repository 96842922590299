import { hookstate, useHookstate } from '@hookstate/core';
import { Shortcut } from 'features/EventBoard/server/calendar';

const initialState = hookstate(null);

const useShortcutState = () => {
    const shortcut = useHookstate<Shortcut | null>(initialState);

    const setShortcut = (value: Shortcut | null) => shortcut.set(value);

    return { setShortcut, shortcut: shortcut.get() };
};

export default useShortcutState;
