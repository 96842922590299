import React from 'react';
import styled from 'styled-components';

import { RaceDay } from 'features/EventBoard/server/calendar';
import RaceDayDTO from 'common/DataObjects/RaceDay';

import { countryCodeMap } from 'ui/RaceNavigation';
import Text from 'ui/Text';
import { byPlatform } from 'utils/platforms';

interface TrackInfoProps {
    raceDay: RaceDay | RaceDayDTO;
}

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 15%;
`;

export const CountryImage = styled.img`
    height: ${(props) => props.height || byPlatform('14px', '16px')};
    margin-right: 6px;
    border-radius: 2px;
`;

export const getCountryImage = (code: string) =>
    countryCodeMap[code as keyof typeof countryCodeMap] || countryCodeMap.Default;

const TrackNameWithCountry = ({ raceDay }: TrackInfoProps) => {
    return (
        <TitleWrapper>
            <CountryImage src={getCountryImage(raceDay?.country?.code)} />
            <Text size={byPlatform('x2', 'x3')} bold>
                {raceDay.trackName}
            </Text>
        </TitleWrapper>
    );
};

export default TrackNameWithCountry;
