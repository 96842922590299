import React from 'react';
import ShoeInfo from './ShoeInfo';
import { Horse } from 'features/TrackPage/model/Race';

interface WeightOrShoeProps {
    horse: Horse;
    gallop: boolean;
}

//@TODO: After shoeInfo is added to the racing card endpoint, take a shoe info from it and display shoe info
const WeightOrShoeCol = ({ horse, gallop }: WeightOrShoeProps) => {
    //@TODO: use real data
    const shoeInfo = { front: null, back: null };

    return gallop ? (
        <>{horse?.gallopStartInfo && horse?.gallopStartInfo?.weight?.cardWeight}</>
    ) : (
        <ShoeInfo
            frontShoe={shoeInfo ? shoeInfo.front : false}
            backShoe={shoeInfo ? shoeInfo.back : false}
        />
    );
};

export default WeightOrShoeCol;
