import React, { DragEvent } from 'react';

interface OverlayProps {
    faded?: boolean;
    onOverlayDragStqrt?(e: DragEvent): void;
    onOverlayDragEnter?(e: DragEvent): void;
    onOverlayDragLeave?(e: DragEvent): void;
    onOverlayDrop?(e: DragEvent): void;
}
//@TODO Fix: Overlay component overlaps everything that is not positioned
const Overlay = ({
    onOverlayDragStqrt,
    onOverlayDragEnter,
    onOverlayDragLeave,
    onOverlayDrop,
}: OverlayProps) => {
    const onDragEnter = (e: DragEvent) => {
        onOverlayDragEnter && onOverlayDragEnter(e);
    };

    const onDragLeave = (e: DragEvent) => {
        onOverlayDragLeave && onOverlayDragLeave(e);
    };

    return (
        <div
            onDragStart={onOverlayDragStqrt}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onOverlayDrop}
            style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                //background: faded ? 'rgba(0,0,0,0.7)' : 'rgba(0,0,0,0)',
                top: 0,
                left: 0,
                //zIndex: faded ? priority.overlay : 0,
                background: 'transparent',
            }}
        />
    );
};

export default Overlay;
