import { hookstate, useHookstate } from '@hookstate/core';

import { RaceDay } from 'features/EventBoard/server/calendar';

const initialRaceDayState = hookstate<RaceDay | null>(null);

const useRaceDayState = () => {
    const raceDayState = useHookstate(initialRaceDayState);

    const setRaceDay = (raceDay: RaceDay | null) => {
        raceDayState.set(raceDay);
    };

    return {
        setRaceDay,
        raceDay: raceDayState.get(),
        raceDayFound: raceDayState.get() !== null,
    };
};

export default useRaceDayState;
