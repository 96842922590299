import { ReactNode } from 'react';
import styled from 'styled-components';
import Text from 'ui/Text';
import Button, { ButtonProps } from 'ui/controls/Button';
import { BACKGROUNDS, COLORS } from 'themes';
import { CloseButtonArea, PopupWrapper } from 'ui/Popup';
import { ChildrenProps, ClickableComponent, DraggableComponent } from 'ui/types';
import { priority } from 'configs/layout';
import { CloseButton } from 'ui/Popup';
import Icon from 'ui/Icon';
import { BetStatus } from '../types';

export const BatchBettingPopupWrapper = styled(PopupWrapper)`
    border-radius: 30px;
`;

export const BatchBettingTitleDef = styled(Text).attrs<ChildrenProps>(() => ({
    size: 'x3',
    bold: true,
    black: true,
    center: true,
}));

export const BatchBettingTextDef = styled(Text).attrs(() => ({
    black: true,
}))`
    font-size: 12px;
`;

export const CenteredButton = styled(Button).attrs<
    ButtonProps & ClickableComponent<HTMLParagraphElement>
>(() => ({
    size: 'x1',
    uppercase: true,
    accent: true,
}))`
    font-weight: 800;
    width: 170px;
    height: 40px;
    font-size: 14px;
    padding: 0 40px;
    border-radius: 20px;
    display: block;
    margin: 0 auto;
    position: absolute;
    left: calc(50% - 85px);
    bottom: -20px;
    opacity: 1;
`;

export const Link = styled.a`
    color: ${COLORS.black};
    font-weight: 900;
`;

export const StatusCloseButton = styled(CenteredButton)`
    left: calc(50% - 100px);
    width: 200px;
`;

export interface UploadAreaContainerProps {
    highlighted: boolean;
    children?: ReactNode;
}

export const UploadAreaContainerDef = styled.div<
    UploadAreaContainerProps & DraggableComponent & ClickableComponent<HTMLDivElement>
>`
    border: 3px ${({ highlighted }) => (highlighted ? 'solid #2667F5' : 'dashed #C5CCDB')};
    background: ${({ highlighted }) => (highlighted ? '#E6EBF8' : '#F0F2F8')};
    width: 100%;
    height: auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer;
    padding: 65px 0;
    box-sizing: border-box;
    margin-bottom: 30px;
    // position: relative;
    // z-index: ${priority.overlapOverlay};
`;

export const BatchBettingSummaryContainerDef = styled.div`
    padding: 25px;
`;

export const BatchBettingProductLogoDef = styled.div<{ src: string }>`
    background: ${({ src }) => src};
    width: 100%;
    background-size: cover;
    height: 100%;
    background-position: center;
    border-radius: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 10;
`;

export const TrashIcon = styled(Icon).attrs(() => ({
    itrash: true,
}))`
    position: absolute;
    background: black;
    padding: 4px;
    z-index: 11;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    top: 11px;
    right: 33px;
    border-radius: 4px;
    cursor: pointer;
`;

export const FailIcon = styled(Icon).attrs<{ ifailcross: boolean }>({
    ifailcross: true,
    size: 'x15',
})``;

export const ProductLogoContainerDef = styled.div`
    border: 2px dashed #9198aa;
    background: ${BACKGROUNDS.lightGrey};
    width: 100%;
    height: 250px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    position: relative;
`;

export const ReplaceUploadedFileButton = styled(Button).attrs<ButtonProps>({
    bold: true,
    uppercase: true,
    accent: true,
})`
    position: absolute;
    bottom: 10px;
`;

export const DesktopPopupCloseButtonArea = styled(CloseButtonArea)`
    z-index: ${priority.overlapOverlay};
    top: -12px;
`;

export const DesktopPopupCloseButton = styled(CloseButton)`
    z-index: ${priority.overlapOverlay};
    box-shadow: 0px 0px 12px rgb(62, 45, 45, 1);
`;

export const UploadIcon = styled(Icon)`
    &::before {
        // needed for overlapping the overlay. onClick handlers won't work without it
        position: relative;
        z-index: ${priority.overlapOverlay};
    }
`;

export const BatchBetStatusPopupWrapper = styled(BatchBettingPopupWrapper)<{
    status: BetStatus;
}>`
    min-height: auto;
    border: ${({ status }) => {
        switch (status) {
            case BetStatus.failed: {
                return `2px solid ${COLORS.red}`;
            }
            case BetStatus.accepted: {
                return `2px solid green`;
            }
            case BetStatus.pending: {
                return `2px solid yellow`;
            }
            default: {
                return 'none';
            }
        }
    }};
`;

export const PlaceBetButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    gap: 20px;
    margin-top: 20px;
`;

export const FileNameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ListOfInfo = styled.div`
    display: block;
`;

export const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
`;
export const ProgressBar = styled.div`
    height: 10px;
    width: 100%;
    background: #1ca963;
    border-radius: 5px;
`;

export const UploadInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const UploadStatusWrapper = styled.div<{
    isSuccess: boolean | undefined;
}>`
    border: 1px solid ${({ isSuccess }) => (isSuccess ? '#c6ccdb' : '#E54C4C')};
    border-radius: 10px;
    padding: 15px 25px 10px;
    background-color: ${({ isSuccess }) => (isSuccess ? '#f0f2f8' : '#FBE4E4')};
    display: flex;
    align-items: center;
    gap: 17px;
`;

export const VideoWrapper = styled.div`
    display: flex;
    gap: 20px;
`;

export const VideoItemContainer = styled.div`
    z-index: 10;
    position: relative;
    box-shadow: 0 5px 20px #dfe6fa;
    flex: 1 1 auto;
    &:before {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(to top, rgba(0, 0, 0, 1) 10%, rgba(0, 0, 0, 0) 100%);
        z-index: 1;
    }
`;

export const VideoDescription = styled(Text)`
    padding: 30px;
    color: #fff;
    font-size: 32px;
    font-weight: 900;
    background: transparent;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    z-index: 1;
`;

export const PlayIcon = styled.div`
    position: absolute;
    border-radius: 45px;
    height: 45px;
    width: 45px;
    background: #000;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
    top: calc(50% - 45px / 2);
    left: calc(50% - 45px / 2);
    & > i {
        position: relative;
        left: 2px;
    }
`;
