import { useEffect } from 'react';
import moment from 'moment';
import { hookstate, useHookstate } from '@hookstate/core';

import useCalendarDate from './useCalendarDate';
import useCalendarQuery from './useCalendarQuery';
import { getRaceDays } from '../server/calendar';
import { today, toServerDate } from 'utils/date';
import { extractRaceSchedule } from '../utils/common';

const refetchTimeoutIdState = hookstate(null);

const useSideEffects = () => {
    const { date, selectNextDate, isNextDateAvailable } = useCalendarDate();
    const { data, isSuccess, status: calendarStatus, refetchCalendar } = useCalendarQuery({ date });
    const raceDays = isSuccess ? getRaceDays(data) : [];

    const allRacesFinished = isSuccess && raceDays.length === 0;

    useEffect(() => {
        const isToday = toServerDate(today()) === date;
        // isNextDateAvailable check prevents recursive updates if the next date is last available day in the calendar
        if (allRacesFinished && isNextDateAvailable && isToday) {
            selectNextDate();
        }
    }, [allRacesFinished, isNextDateAvailable, selectNextDate, date]);

    // updating time before the race starts

    const isToday = toServerDate(today()) === date;

    const racesSchedule = isToday ? extractRaceSchedule(raceDays) : [];

    const stringifiedSchedule = JSON.stringify(racesSchedule);
    // we need a timeout id in state, so we can clear timeout before setting a new one
    // it fixes multiple calendar re-fetching because the below effect will be called everytime the date is changed
    // + it's a global state, so it's value persist even if a component will was re-mounted
    // (e.g. user navigated to track page and back)
    const timeoutIdState = useHookstate<number | null>(refetchTimeoutIdState);

    useEffect(() => {
        // no need for updating not today's races
        if (!isToday || calendarStatus !== 'success') return;

        const [earliestRaceMoment] = racesSchedule;

        if (!earliestRaceMoment) return;

        const currentTimeoutId = timeoutIdState.get();

        if (currentTimeoutId) {
            clearTimeout(currentTimeoutId);
        }

        const newTimeoutId = setTimeout(refetchCalendar, earliestRaceMoment.diff(moment.utc()));

        timeoutIdState.set(newTimeoutId);
    }, [stringifiedSchedule, isToday, calendarStatus, refetchCalendar, raceDays.length]);
};

export default useSideEffects;
