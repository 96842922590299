import React from 'react';
import { isMobile } from 'react-device-detect';

import RaceButtons from 'features/TrackPage/components/RaceLine/RaceButtons';
import { Label } from 'features/TrackPage/components/styled';
import withFixedOnScroll, { WithFixedOnScrollProps } from 'ui/hoc/withFixedOnScroll';
import { byPlatform } from 'utils/platforms';
import { NEW_TRACK_PAGE } from 'configs/main';

const RaceButtonsWithFixedOnScroll = withFixedOnScroll(RaceButtons);

const settings: WithFixedOnScrollProps = {
    withOffsetTop: true,
    backgroundColor: '#f0f2f8',
    padding: '10px 0',
    offsetTopCorrection: NEW_TRACK_PAGE ? 10 : 0,
    zIndex: 3,
    additionalStyles: { overflow: 'auto', width: '100%' },
};

const RacesRow = ({
    raceLineItems = [],
    legacyRacesData = [],
    legacySetRace = () => {},
    legacySelectedRace = 0,
}) => {
    const showLabels = !isMobile;

    return (
        <>
            {showLabels && <Label>Løb/afd.</Label>}
            {byPlatform(
                <RaceButtonsWithFixedOnScroll
                    {...settings}
                    // @ts-ignore
                    raceLineItems={raceLineItems}
                    legacyRacesData={legacyRacesData}
                    legacySetRace={legacySetRace}
                    legacySelectedRace={legacySelectedRace}
                />,
                <RaceButtons
                    raceLineItems={raceLineItems}
                    legacyRacesData={legacyRacesData}
                    legacySetRace={legacySetRace}
                    legacySelectedRace={legacySelectedRace}
                />
            )}
        </>
    );
};

export default RacesRow;
