import React from 'react';
import { Location } from 'history';

import useSelections from 'features/BetSlip/state/useSelections';
import useAmount from 'features/BetSlip/state/useAmount';
import { ConfirmBtn } from './styled';
import Move from 'ui/animations/Move';
import FadeIn from 'ui/animations/FadeIn';
import BetSlip from '../components/BetSlip';
import CenteredLogoTopBar from 'features/Spilklub/components/CenteredLogoTopBar';
import useAuth from 'common/hooks/useAuth';
import { useDispatch } from 'react-redux';
import { openLogin, history } from 'utils/navigation';
import { byPlatform } from 'utils/platforms';

const BetSlipScreen = ({
    onConfirm = (combinationPrice: number, combinationsCount: number) => {},
}) => {
    const selections = useSelections();
    const dispatch = useDispatch();
    const auth = useAuth();

    const {
        combinationPrice,
        isCombinationPriceValid,
        isCouponAmountExceed,
        errorMessage,
        applyCombinationPrice,
    } = useAmount(0, selections);

    const validate = () =>
        isCombinationPriceValid && !isCouponAmountExceed && selections.maximumPicksValid;

    const isNotAuthenticated = !auth.user && !auth.pending;

    const locationState = history.location?.state as { background: Location };

    return (
        <>
            <CenteredLogoTopBar product={selections.product} showDraftInfo={true} />
            <FadeIn duration={800}>
                <BetSlip
                    combinationPrice={combinationPrice}
                    applyCombinationPrice={applyCombinationPrice}
                    isCombinationPriceValid={isCombinationPriceValid}
                    isCouponAmountExceed={isCouponAmountExceed}
                    errorMessage={errorMessage}
                    {...selections}
                    minimumPicksValid={true}
                />
            </FadeIn>
            <Move>
                <ConfirmBtn
                    accentAlt
                    disabled={!validate()}
                    grey={!validate()}
                    onClick={() =>
                        isNotAuthenticated
                            ? dispatch(
                                  openLogin(
                                      byPlatform({}, { background: locationState?.background })
                                  )
                              )
                            : onConfirm(combinationPrice, selections.combinationsCount)
                    }
                >
                    TILFØJ KRITERIER
                </ConfirmBtn>
            </Move>
        </>
    );
};

export default BetSlipScreen;
