import styled from 'styled-components';
import { getTextColorByProps, numberToPixel } from 'utils/theme-helpers';

const getFontSize = (size = 14) => {
    let s = size;

    switch (size) {
        case 'x04':
            s = 8;
            break;
        case 'x05':
            s = 10;
            break;
        case 'x07':
            s = 12;
            break;
        case 'x1':
            s = 14;
            break;
        case 'x1.5':
            s = 16;
            break;
        case 'x2':
            s = 18;
            break;
        case 'x2.5':
            s = 20;
        case 'x3':
            s = 22;
            break;
        default:
            s = size;
    }

    return s;
};
const Text = styled.p`
    flex: ${(props) => (props.flex ? props.flex : '1 1 auto')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    font-size: ${(props) => getFontSize(props.size)}px;
    ${(props) => (props.heightstrict ? 'line-height: ' + getFontSize(props.size) + 'px;' : '')};
    margin: 0;
    padding: ${(props) => (props.padding ? props.padding : '10px 0px')};
    color: ${(props) => props.color || getTextColorByProps(props)};
    text-align: ${(props) => (props.align ? props.align : 'left')};
    font-weight: ${(props) => {
        const weight = props.bold || props.weight;
        if (weight) {
            return parseInt(weight, 10) > 0 ? weight : '900';
        }
        return 'normal';
    }};
    ${(props) => (props.block ? 'display: block;' : '')} ${(props) =>
        props.short
            ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;'
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
    ${(props) => (props.uppercase ? `text-transform: uppercase;` : '')};
    ${(props) => (props.center ? `text-align: center` : '')};
    ${(props) => (props.opacity ? `opacity: ${props.opacity};` : '')};
    ${(props) => (props.height ? `height: ${props.height};` : '')};
    ${(props) => (props.overflow ? `overflow: ${props.overflow};` : '')};
    ${(props) => (props.fontStyle ? `font-style: ${props.fontStyle};` : '')};
    ${(props) => {
        if (props.condensed) {
            return 'font-family: Roboto Condensed';
        } else if (props.atg) {
            return 'font-family: ATG Text';
        }
    }};
    ${(props) => {
        if (props.lh) {
            return `line-height: ${props.lh}px`;
        }
    }};
    text-decoration: ${(props) => (props.strike ? 'line-through' : 'none')};
`;

export const TextFrame = styled.div`
    padding: ${({ pt, pr, pb, pl }) =>
        `${numberToPixel(pt, 5)} ${numberToPixel(pr, 30)} ${numberToPixel(pb, 5)} ${numberToPixel(
            pl,
            30
        )}`};
`;

export const TextLink = styled(Text).attrs({ as: 'span' })`
    color: #2b44af;
    text-decoration: underline;
    overflow: hidden;
    cursor: pointer;
`;

export default Text;
