import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import {
    resetProduct,
    resetRace,
    resetRaceDay,
    setDefaultRaceDay,
} from 'features/AISDataProvider/actions';
import { setDate } from 'ui/DatePicker/actions';
import { withRouter } from 'react-router-dom';
import { TopBar } from '@it25syv/25syv-ui';
import { getUrl, isNewsItemPage, openLogin, showPopup } from 'utils/navigation';
import { ProductsTransitionBar } from '@it25syv/25syv-ui';
import { BACKGROUNDS } from 'themes/index';
import rofusLogo from 'images/dk/rofus.png';
import betImage from 'images/bet.png';
import derbyImage from 'images/derby.png';
import rodImage from 'images/rod.png';
import { trackEvent } from 'utils/tracking';

class AppToolbar extends Component {
    onClickLogoHandler = () => {
        window.location.href = getUrl('/');
        // this.props.history.push('/');
        // this.props.setDate(new Date());
        // //@TODO Replace setTimeout with something smarter
        // // setTimeout here fixes the chain of side effects
        // // setDate action leads to setRaceDay and setProduct
        // // which is important for switching between dates to
        // // run all the needed queries (fetch everything).
        // // But for resetting state we need all the resetting
        // // actions to be run AFTER the setDate side effects.
        // setTimeout(() => {
        //     this.props.resetProduct();
        //     this.props.resetRaceDay();
        //     this.props.setDefaultRaceDay(false);
        //     this.props.resetRace();
        // }, 1000);
    };

    onBackButtonClickHandler = () => {
        if (isNewsItemPage()) {
            this.props.history.push('/25-nyt');
        }
    };

    openUserArea = () => {
        showPopup('USER_AREA_MOBILE');
    };

    openDeposit = () => {
        trackEvent({
            event: 'derby25.clickToPaymentSystem',
            userId: this.props.auth?.user?.id || 0,
        });
        this.openUserArea();
    };

    openSignUp = () => {
        this.props.showSignupModal();
    };

    render() {
        return (
            <>
                <ProductsTransitionBar
                    p={2}
                    rofusLogo={rofusLogo}
                    betImage={betImage}
                    derbyImage={derbyImage}
                    rodImage={rodImage}
                    lastLoginTime={this.props.currentLoginUTC}
                    showTimer={!!this.props.currentLoginUTC}
                    clockFormat={'HH:mm'}
                    timerFormat={'HH:mm'}
                    activeDerbyColor={BACKGROUNDS.navigationActiveElement}
                    activeProject={'derby'}
                    color={'#fff'}
                    rodLink={'/casino'}
                    betLink={'/sport'}
                    derbyLink={'/heste'}
                    logoOnClickHandler={() => {
                        this.props.history.push('/');
                        this.props.setDate(new Date());
                    }}
                    background={BACKGROUNDS.navigation}
                />
                <TopBar
                    showBackButton={isNewsItemPage()}
                    onBackButtonClickHandler={this.onBackButtonClickHandler}
                    background={BACKGROUNDS.navigation}
                    userEmail={this.props.auth.user?.email}
                    username={this.props.auth.user ? this.props.auth.user.userName : ''}
                    balance={this.props.auth.user ? this.props.auth.user.balance : ''}
                    secondColor={'#2CBF78'}
                    bankClickHandler={this.openDeposit}
                    showUserArea={this.openUserArea}
                    signUpHandler={this.openSignUp}
                    showLoginModal={this.props.showLoginModal}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentLoginUTC: state.auth.user ? state.auth.user.currentLoginUTC : null,
});

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginModal: () => {
            dispatch(openLogin());
        },
        showSignupModal: () => {
            dispatch(showModal('SIGNUP'));
        },
        showUserAreaModal: () => {
            dispatch(showModal('USER_AREA_MENU'));
        },
        showDepositModal: () => {
            dispatch(showModal('DEPOSIT', 1));
        },
        resetProduct: () => {
            dispatch(resetProduct());
        },
        setDate: (date) => dispatch(setDate(date)),
        resetRace: () => dispatch(resetRace()),
        resetRaceDay: () => {
            dispatch(resetRaceDay());
        },
        setDefaultRaceDay: (defaultRaceDay) => {
            dispatch(setDefaultRaceDay(defaultRaceDay));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppToolbar));
