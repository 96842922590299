import React from 'react';
import { isMobile } from 'react-device-detect';

import { history, generateEventPath } from 'utils/navigation';

import { ProductId } from 'features/EventBoard/server/calendar';

import ProductButtons from './ProductButtons';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useURLParameters from 'features/TrackPage/hooks/useURLParameters';
import { Label } from 'features/TrackPage/components/styled';
import { SAVE_RACE_NUMBER_BETWEEN_PRODUCTS } from 'configs/products';

const ProductsRow = () => {
    const { calendarLoading, racesLoading, productId: selectedProductId, raceDay } = useTrackPage();
    const { date, track, race } = useURLParameters();

    const showLabels = !isMobile;

    const onSelectProduct = (productId: ProductId) => {
        const shouldKeepRaceNumber =
            SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(selectedProductId) &&
            SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(productId);

        const newRaceValue = shouldKeepRaceNumber ? race : 1;

        history.push(generateEventPath({ productId, date, track, race: newRaceValue }));
    };

    return (
        <>
            {showLabels && <Label>Spiltype</Label>}

            <ProductButtons
                data={raceDay.fullProductList}
                loading={calendarLoading}
                selectedProductId={selectedProductId}
                onSelect={onSelectProduct}
                disabled={racesLoading}
            />
        </>
    );
};

export default ProductsRow;
