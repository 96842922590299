import React, { useEffect } from 'react';
import Text from 'ui/Text';
import Hr from 'ui/Hr';
import { COLORS } from 'themes';
import { ConfirmButton } from 'ui/controls/Button';

const LeadPopup = ({ hideModal, showModal, enableCloseButton }) => {
    useEffect(() => {
        enableCloseButton();
    }, []);

    const close = () => {
        hideModal('PIN_SETUP_LEAD');
    };

    const openPinSetup = () => {
        hideModal('PIN_SETUP_LEAD');
        showModal('PIN_SETTING', 3);
    };

    return (
        <div style={{ margin: '50px' }}>
            <Text
                size="x3"
                bold
                condensed
                className="text-center"
                color={COLORS.black}
            >
                Log hurtigere ind med en 4-cifret PIN kode
            </Text>
            <Text size="x2" condensed secondary className="text-center">
                Log hurtigere ind med en 4-cifret PIN kode
            </Text>
            <Hr />
            <ConfirmButton
                size="x3"
                className="uppercase"
                condensed
                margin="25px auto"
                onClick={openPinSetup}
            >
                Opret pin
            </ConfirmButton>
            <Text
                condensed
                secondary
                size="x2"
                className="uppercase text-center"
                onClick={close}
            >
                ikke nu
            </Text>
        </div>
    );
};

export default LeadPopup;
