import React from 'react';
import moment from 'moment';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Divider from 'ui/Divider';
import Button from 'ui/controls/Button';
import Accordion from 'ui/Accordion';
import { Col, Row } from 'react-grid-system';
import SelectionRow from 'features/History/SelectionRow';
import { PRODUCT_IDS, PRODUCTS_FOR_SPLITTING_BY_LEGS } from 'configs/products';
import { COLORS } from 'themes/';
import Product from 'common/DataObjects/Product';
import { IconWrapper, StatusBar } from './styled';
import Icon from 'ui/Icon';
import BatchBettingHistoryItem from 'features/History/BatchBettingHistoryItem';

import getTexts from 'utils/localization';
import { formatProductName, formatRaceNumber } from 'features/History/utils';
import DerbyVisionAPI from '../../common/api/DerbyVisionAPI';
import { priority } from '../../configs/layout';
import NotificationConductor from '../../common/conductors/NotificationConductor';
import { connect } from 'react-redux';
import { showModal } from '../../common/actions/uiActions';
import AISREST from 'features/AISDataProvider/AISREST';

const t = getTexts();
const availableCountryReplays = ['DK', 'SE'];

class BettingHistoryItem extends React.Component {
    constructor() {
        super();

        this.state = {
            expanded: false,
            reserv: false,
            isLoading: false,
            showReplaysState: false,
        };
    }

    setReservState = () => {
        this.setState({
            reserv: !this.state.reserv,
        });
    };

    showVReplays = async () => {
        this.setState({
            showReplaysState: !this.state.showReplaysState,
        });
    };

    onReplayClick = async (raceNumber = '', trackId = '') => {
        const { coupon, couponInfo, trackInfo } = this.props.data;

        let trackCode = '';

        if (trackInfo.code === 'Xa') {
            // needed for getting right trackCode for multitracks with trackCode 'Xa'
            /* Get trackCode */
            const trackData = await AISREST.getTrack(trackId || coupon.trackCode);
            trackCode = trackData?.data?.track?.code || trackInfo.code;
        } else {
            trackCode = trackInfo.code;
        }

        this.setState({ isLoading: true });

        const payload = {
            countryCode: trackInfo.country,
            date: coupon.raceDay,
            raceNumber: raceNumber || coupon?.raceNumber || couponInfo[0]?.raceNumber,
            trackCode: trackCode,
            trackId: trackId || coupon.trackCode,
        };

        DerbyVisionAPI.getReplayUrl(payload)
            .then((res) => {
                if (res?.data?.data?.length) {
                    this.props.showReplayPopup({
                        replayLink: res.data.data,
                        customPopup: true,
                        stopScrollToTop: true,
                    });
                } else {
                    NotificationConductor.error(res?.data.toString() || 'Request failed');
                }
                this.setState({ isLoading: false });
            })
            .catch((e) => {
                NotificationConductor.error(e.toString());
                this.setState({ isLoading: false });
            });
    };

    render() {
        const { trackInfo, coupon, couponInfo, winAmount, resultInfo, groupName, batchCount } =
            this.props.data;

        const groupedResults = {};
        const groupedCouponsMapList = {};
        const reservedCouponInfo = {};

        let isReservExist = false;

        const isReplayAvailable = availableCountryReplays.includes(trackInfo?.country);

        const product = new Product().setAll(coupon.product);

        if (batchCount > 0) {
            return <BatchBettingHistoryItem data={this.props.data} product={product} />;
        }

        couponInfo.forEach((info) => {
            const raceKey = PRODUCTS_FOR_SPLITTING_BY_LEGS.includes(coupon.product)
                ? info.legNumber
                : info.raceNumber;

            if (info.reserve === 0) {
                if (!groupedCouponsMapList[raceKey]) {
                    groupedCouponsMapList[raceKey] = [];
                }

                groupedCouponsMapList[raceKey].push(info);
            } else {
                if (!reservedCouponInfo[raceKey]) {
                    reservedCouponInfo[raceKey] = [];
                }

                reservedCouponInfo[raceKey].push(info);
                isReservExist = true;

                reservedCouponInfo[raceKey] = reservedCouponInfo[raceKey].sort((a, b) =>
                    a.reserve > b.reserve ? 1 : -1
                );
            }
        });

        resultInfo.forEach((result) => {
            if (!product.horseMarksSplitByPlaces()) {
                if (!groupedResults[result.legNumber]) {
                    groupedResults[result.legNumber] = [];
                }

                groupedResults[result.legNumber].push(result);
            } else {
                if (!groupedResults[result.place]) {
                    groupedResults[result.place] = [];
                }

                groupedResults[result.place].push(result);
            }
        });

        const betLabel = () => {
            let group = this.props.data;
            let label = null;

            if (group.groupName) {
                label = ` - ${t.spilklub}`;
            } else if (group.coupon.quickPick || group.coupon.smartLyn) {
                label = ` - ${t.QuickPick.label}`;
            }

            return label;
        };

        const formattedRaceDay = moment(this.props.data.coupon.raceDay).format('DD/MM/YY'),
            formattedTransactionDate = moment(this.props.data.receipt.transactionDate).format(
                'DD/MM/YY HH:mm'
            );

        const showIcon = () => {
            let group = this.props.data;

            if (group.groupName) {
                return { ispilklub: true };
            } else if (group.coupon.quickPick || group.coupon.smartLyn) {
                return { ilyn: true };
            } else if (group.coupon.retail) {
                return { iretail: true, size: 22 };
            }
        };

        return (
            <Wrapper data-test-id="BettingHistoryItem">
                <Accordion
                    head={
                        <div>
                            <Text size="x05" padding="0px">
                                {formattedRaceDay}
                            </Text>
                            <Text size="x07" bold="700" black padding="0px" flex={'2 1 auto'}>
                                {showIcon() && (
                                    <IconWrapper>
                                        <Icon size="x05" color="black" {...showIcon()} />
                                    </IconWrapper>
                                )}
                                {trackInfo.domesticText}:{' '}
                                {formatProductName(product.name, coupon.strictBet)}
                                {formatRaceNumber(coupon, couponInfo)}
                            </Text>
                        </div>
                    }
                    bg={COLORS.white}
                >
                    <Wrapper padding="0px">
                        <Wrapper padding="10px">
                            <Text size="x05" bold="700" black padding="0px">
                                {this.props.data.couponId}: {product.name}
                                {betLabel()
                                    ? betLabel()
                                    : formatRaceNumber(coupon, couponInfo)}{' '}
                                {groupName ? ' - ' + groupName : ''}
                            </Text>
                            <Text size="x05" bold="700" black padding="0 0 10px 0">
                                {formattedTransactionDate}
                            </Text>

                            <Divider lightGrey padding="5px 0px" />
                            <Row data-test-id="column-row">
                                <Col xs={2} style={{ margin: '0px', padding: '0px' }}>
                                    <Text bold="500" padding="8px 0px" align="center" size="x05">
                                        {product.isTrio() ||
                                        product.isTvilling() ||
                                        product.isKomb()
                                            ? t.betSlip.place
                                            : product.isVProduct()
                                            ? t.betSlip.department
                                            : t.betSlip.track}
                                    </Text>
                                </Col>
                                <Col xs={6} style={{ margin: '0px', padding: '0px' }}>
                                    <Text bold="500" padding="8px 0px" align="left" size="x05">
                                        {t.betSlip.nrAndHorse}
                                    </Text>
                                </Col>

                                <Col xs={4} style={{ margin: '0px', padding: '0px' }}>
                                    <Text bold="500" padding="8px 0px" align="center" size="x05">
                                        {this.state.showReplaysState ? 'Replays' : t.betSlip.winner}
                                    </Text>
                                </Col>
                            </Row>
                            {Object.values(groupedCouponsMapList).map((group, index) => {
                                return (
                                    <SelectionRow
                                        key={index}
                                        index={index}
                                        group={group}
                                        product={coupon.product}
                                        productDTO={product}
                                        resultInfo={
                                            product.id !== PRODUCT_IDS.TV
                                                ? groupedResults[group[0].legNumber]
                                                    ? groupedResults[group[0].legNumber]
                                                    : false
                                                : resultInfo
                                        }
                                        isReserv={this.state.reserv}
                                        reserv={
                                            reservedCouponInfo[
                                                PRODUCTS_FOR_SPLITTING_BY_LEGS.includes(
                                                    coupon.product
                                                )
                                                    ? group[0].legNumber
                                                    : group[0].raceNumber
                                            ]
                                        }
                                        winAmount={winAmount}
                                        showReplays={this.state.showReplaysState}
                                        onReplayClick={(raceNumber, trackId) => {
                                            this.onReplayClick(raceNumber, trackId);
                                        }}
                                    />
                                );
                            })}

                            <Row
                                style={{ margin: '0px', padding: '0px' }}
                                data-test-id="indsats-row"
                            >
                                <Col xs={6} style={{ margin: '0px', padding: '0px' }}>
                                    <Text bold={900} size="x1" padding="10px">
                                        {t.betSlip.sum}:{' '}
                                        {(this.props.data.receipt.totalCost / 100).toFixed(2)}{' '}
                                        {t.currency}
                                    </Text>
                                    {this.props.mode === 'finished' ? (
                                        <>
                                            <Text bold={900} size="x1" padding="10px">
                                                {t.History.winAmount}: {winAmount.toFixed(2)}{' '}
                                                {t.currency}
                                            </Text>
                                        </>
                                    ) : null}
                                </Col>
                                <Col
                                    xs={6}
                                    style={{
                                        margin: '0px',
                                        padding: '0px 0px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {isReservExist ? (
                                        <Button
                                            onClick={this.setReservState}
                                            size="x1"
                                            rounded={5}
                                            padding="10px 20px"
                                            width="120px"
                                            accent
                                            bg={'#ECEFF6'}
                                            bold
                                        >
                                            {!this.state.reserv
                                                ? t.History.Reserv.show
                                                : t.History.Reserv.hide}
                                        </Button>
                                    ) : (
                                        <Col style={{ height: '34px' }} />
                                    )}
                                    {this.props.mode === 'finished' && isReplayAvailable ? (
                                        <Button
                                            onClick={() => {
                                                Product.isVProduct(coupon.product)
                                                    ? this.showVReplays()
                                                    : this.onReplayClick();
                                            }}
                                            size="x1"
                                            rounded={5}
                                            padding="10px 20px"
                                            width="120px"
                                            accent={!this.state.showReplaysState}
                                            red={this.state.showReplaysState}
                                            bg={this.state.showReplaysState ? '#2CB16F' : '#ECEFF6'}
                                            bold
                                        >
                                            {this.state.isLoading ? 'Loading...' : 'Se replay'}
                                        </Button>
                                    ) : null}
                                </Col>
                            </Row>
                        </Wrapper>

                        {this.props.mode === 'finished' ? (
                            <StatusBar {...(winAmount ? { primary: 'primary' } : { red: 'red' })}>
                                {winAmount ? t.betSlip.win : t.betSlip.lose}
                            </StatusBar>
                        ) : null}
                    </Wrapper>
                </Accordion>
            </Wrapper>
        );
    }
}

export default connect(null, (dispatch) => ({
    showReplayPopup: (data) => dispatch(showModal('REPLAY', priority.replays, data)),
}))(BettingHistoryItem);
