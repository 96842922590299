import React from 'react';
import classNames from 'classnames';

import {
    RaceCardActionsContainer,
    RaceCardActionsLeftSide,
    ActionButton,
    ReserveButton,
} from './styled';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';

import TrioPickColumnButtons from '../common/TrioPickColumnButtons';
import PDFSection from './PdfDownload';

import { PRODUCT_IDS } from 'configs/products';
import getTexts from 'utils/localization';
const t = getTexts();

const RaceCardActionsRow = () => {
    const { race, productId, racesFetched, isVProduct } = useTrackPage();
    const isTrio = productId === PRODUCT_IDS.T;
    const showTrioButtons = (race.saleOpen || !racesFetched) && isTrio;

    const { toggleReservesMode, reservesMode } = useReservesMode();

    return (
        <RaceCardActionsContainer>
            <RaceCardActionsLeftSide showTrioButtons={showTrioButtons}>
                {showTrioButtons && <TrioPickColumnButtons />}

                <div className={classNames('flex gap-5', { 'pl-10': !showTrioButtons })}>
                    <ActionButton>{t.RacingCard.controlArea.selectAll}</ActionButton>
                    <ActionButton>{t.RacingCard.controlArea.viewStats}</ActionButton>
                    {isVProduct && (
                        <ReserveButton onClick={toggleReservesMode} reservesMode={reservesMode}>
                            {t.RacingCard.controlArea.reserve}
                        </ReserveButton>
                    )}
                </div>
            </RaceCardActionsLeftSide>

            <PDFSection />
        </RaceCardActionsContainer>
    );
};

export default RaceCardActionsRow;
