import styled, { css } from 'styled-components';
import { COLORS } from 'themes';
import { CloseButton as CloseButtonDef } from 'features/Spilklub/components/styled';

import Button from 'ui/controls/Button';
import { fadeInMixin } from 'ui/animations/FadeIn2';

interface BackgroundCSSProps {
    bg: string;
}

export const backgroundCSS = css<BackgroundCSSProps>`
    background: ${props => props.bg};
`;

export const Container = styled.section<BackgroundCSSProps>`
    ${backgroundCSS};
    ${fadeInMixin};
    border-radius: 5px;
    color: ${COLORS.white};
    padding: 10px 20px;
    position: relative;
`;

export const ShowMoreButton = styled(Button)<BackgroundCSSProps>`
    ${backgroundCSS};
    width: 150px;
    font-weight: bold;
    margin: 0;
    height: 35px;
`;

export const CloseButton = styled(CloseButtonDef)`
    padding: 10px;
    width: 10px;
    height: 10px;
    margin: 0;
`;

export const CloseButtonArea = styled.div<BackgroundCSSProps>`
    ${backgroundCSS};
    display: inline-block;
    border-radius: 5px;
    position: absolute;
    right: 10px;
`;

export const ProductTitle = styled.h3`
    font-size: 18px;
    font-family: 'ATG Text';
    margin: 5px 0;
`;

export const ShortText = styled.div`
    line-height: 20px;
    color: #ffffff;
    font-weight: 600;
`;

export const RemoveProductTipsH3 = styled.h3`
    font-weight: 900;
    font-size: 21px;
`;

export const RemoveProductTipsP = styled.p`
    font-size: 15px;
`;
