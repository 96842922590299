import Bet from 'common/DataObjects/Bet';
import BetSlip from 'common/DataObjects/Betslip';
import racesSelector from 'common/selectors/racesSelector';
import { isSpilklubSelectionsMode, reservesPath } from 'common/selectors/betSlipSelector';
import get from 'lodash/get';

const defaultBet = new Bet({});

/**
 * @param  {Object} state
 * @param  {number} raceNumber         Race number value from server.
 * @param  {string} trackCode          Selected track code,
 *                                     considering multi track pools
 * @return {Bet}
 */
const betTableSelector = (state) => {
    const { date, trackCode } = state.AISDataProvider.racingCardData;
    const product = state.AISDataProvider.selectedProduct;
    const raceIndex = state.AISDataProvider.raceIndex;
    return getRacingCardPick(state, { date, trackCode, product, raceIndex });
};

/**
 *
 * @param  {Object} state
 * @return {Map<number, Bet>}        the keys are the race numbers
 *                         and the values are the Bet objects
 *
 * NOTE: DOES NOT work properly with MULTITRACK
 */
const betPickSelector = (state) => {
    const { date, trackCode } = state.AISDataProvider.racingCardData;
    const product = state.AISDataProvider.selectedProduct;

    const keyValues = racesSelector(state).map((race, raceIndex) => {
        return [
            raceIndex,
            getRacingCardPick(state, {
                date,
                trackCode,
                product,
                raceIndex,
            }),
        ];
    });

    return new Map(keyValues);
};

/**
 *
 * @param  {Object} state
 * @return {Array<[Race, Bet]>}
 *
 * Works properly with MULTITRACK
 */
const betPickSelectorArray = (state) => {
    const { date } = state.AISDataProvider.racingCardData;
    const product = state.AISDataProvider.selectedProduct;
    const trackCode = state.AISDataProvider.racingCardData.trackCode;

    return racesSelector(state).map((race, i) => {
        return [
            race,
            getRacingCardPick(state, {
                date,
                trackCode,
                product,
                raceNumber: race.raceNumber,
                raceIndex: i,
            }),
        ];
    });
};

/**
 * @param  {Object} state
 * @param  {{ date: string, trackCode: string, product: Product, raceIndex: number }}
 * @return {Bet}
 */
export const getRacingCardPick = (state, { date, trackCode, product, raceIndex }) => {
    const betsByDates = isSpilklubSelectionsMode(state)
        ? state.BetSlip.betBuddySelections
        : state.BetSlip.betsByDates;

    if (
        BetSlip.isExistingCombination(betsByDates, {
            date,
            trackCode,
            productId: product.id,
            raceIndex,
        })
    ) {
        return new Bet(betsByDates[date][trackCode][product.id][raceIndex]);
    }
    return defaultBet;
};

/**
 * @param  {Object} state
 * @param  {{ date: string, trackCode: string, product: Product, raceIndex: number }}
 * @return {number[]}
 */
export const getRacingCardReserves = (state, { date, trackCode, product, raceIndex }) => {
    return get(
        state,
        reservesPath(date, trackCode, product.id, raceIndex, isSpilklubSelectionsMode(state)),
        []
    );
};

export default betTableSelector;

export { betPickSelector, betPickSelectorArray };
