import React from 'react';
import { COLORS } from 'themes';
import styled from 'styled-components';

const config = {
    x1: {
        box: 18,
        checkmarkLine: '3 8 7 13 13 5',
    },
    x2: {
        box: 22,
        checkmarkLine: '4 8 9 16 19 1',
    },
};

const getBoxSize = (size) => (config[size] ? config[size].box : config.x1.box);

const getCheckmarkLine = (size) =>
    config[size] ? config[size].checkmarkLine : config.x1.checkmarkLine;

const CheckboxContainer = styled.div`
    width: auto;
    float: right;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 55px;
    margin: 0 10px 0 0;
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};

    &::before {
        ${({ border }) => (border ? `content: ' '` : '')};
        display: block;
        left: 222px;
        height: 50%;
        top: 10px;
        position: absolute;
        width: 1px;
        background: #cccaca;
        z-index: 9;
    }

    input:checked + label svg {
        stroke: ${COLORS.white};
        background: #34be7a;
        border-radius: 5px;
        border: none;

        polyline {
            stroke-dashoffset: 42;
            transition: all 0.25s linear;
            transition-delay: 0.2s;
        }
    }
    span {
        color: ${({ labelColor }) =>
            labelColor ? labelColor : COLORS.primaryText};
        margin: 0;
        font-weight: 900;
        padding-right: 8px;
    }
    input[type='checkbox'] {
        display: none;
    }
    label {
        cursor: pointer;
        position: relative;
        width: ${({ size }) => getBoxSize(size)}px;
        height: ${({ size }) => getBoxSize(size)}px;
        display: flex;
        -webkit-tap-highlight-color: transparent;
        transform: translate3d(0, 0, 0);

        svg {
            position: relative;
            z-index: 1;
            fill: none;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke: none;
            stroke-width: 3;
            transform: translate3d(0, 0, 0);
            transition: all 0.2s ease;
            background: #eceff5;
            box-sizing: border-box;
            border: 1px solid #cbcccc;
            border-radius: 5px;

            polyline {
                stroke-dasharray: 22;
                stroke-dashoffset: 66;
            }
        }
    }
`;

const Checkbox = (props) => {
    return (
        <CheckboxContainer {...props} style={props.styles}>
            {props.label ? <span>{props.label}</span> : null}
            <input
                id={props.id}
                type="checkbox"
                checked={props.checked}
                onChange={props.changeHandler}
            />
            <label htmlFor={props.id} onClick={props.changeHandler}>
                <svg
                    width={`${getBoxSize(props.size)}px`}
                    height={`${getBoxSize(props.size)}px`}
                    viewBox={`0 0 ${getBoxSize(props.size)} ${getBoxSize(
                        props.size
                    )}`}
                >
                    <polyline points={getCheckmarkLine(props.size)} />
                </svg>
            </label>
        </CheckboxContainer>
    );
};

export default Checkbox;
