import React, { Fragment } from 'react';
import { AdaptiveText, Icon, Line, Right } from 'features/UserArea/UserAreaMenu/styled';
import Switcher from 'ui/controls/Switcher';
import Arrow from 'ui/Arrow';

import getTexts from 'utils/localization';
const t = getTexts();

const MenuList = (props) => {
    return (
        <Fragment>
            <Line>
                <Line>
                    <Icon alt={t.userArea.nav.pinLogin} ipin color="black" />
                    <AdaptiveText bold>{t.userArea.nav.pinLogin}</AdaptiveText>
                </Line>
                <Right>
                    <Switcher checked={props.isPinSet} onChange={props.togglePin} />
                </Right>
            </Line>
            <Line onClick={() => props.showModal('PROFILE', 2)}>
                <Icon
                    alt={t.userArea.nav.changePwd}
                    iprofile
                    className="text-right"
                    color="black"
                />
                <AdaptiveText bold>{t.userArea.nav.changePwd}</AdaptiveText>
                <Right>
                    <Arrow direction="right" />
                </Right>
            </Line>
            <Line onClick={() => props.showModal('TRANSACTIONS', 2)}>
                <Icon
                    alt={t.userArea.nav.transactions}
                    itransact
                    className="text-right"
                    color="black"
                />
                <AdaptiveText bold>{t.userArea.nav.transactions}</AdaptiveText>
                <Right>
                    <Arrow direction={'right'} />
                </Right>
            </Line>
            <Line
                onClick={() => {
                    props.showModal('SELF_EXCLUSION', 2);
                }}
            >
                <Icon
                    alt={t.userArea.nav.selfExclusion}
                    iselfexcl
                    className="text-right"
                    color="black"
                />
                <AdaptiveText bold>{t.userArea.nav.selfExclusion}</AdaptiveText>
                <Right>
                    <Arrow direction={'right'} />
                </Right>
            </Line>
            <Line onClick={() => props.showModal('DEPOSIT_LIMITS', 2)}>
                <Icon
                    alt={t.userArea.nav.depositLimits}
                    ilimits
                    className="text-right"
                    color="black"
                />
                <AdaptiveText bold>{t.userArea.nav.depositLimits}</AdaptiveText>
                <Right>
                    <Arrow direction={'right'} />
                </Right>
            </Line>
            <Line onClick={() => props.showModal('VOUCHER', 2)}>
                <Icon alt={t.userArea.nav.voucher} ivoucher className="text-right" color="black" />
                <AdaptiveText bold>{t.userArea.nav.voucher}</AdaptiveText>
                <Right>
                    <Arrow direction={'right'} />
                </Right>
            </Line>
            <Line onClick={() => props.showModal('NEWSLETTER', 2)}>
                <Icon
                    alt={t.userArea.nav.subscriptions}
                    isubscr
                    className="text-right"
                    color="black"
                />
                <AdaptiveText bold>{t.userArea.nav.subscriptions}</AdaptiveText>
                <Right>
                    <Arrow direction={'right'} />
                </Right>
            </Line>
            <Line onClick={props.logout}>
                <Icon alt={t.userArea.nav.logOut} ilogout className="align-right" color="black" />
                <AdaptiveText bold>{t.userArea.nav.logOut}</AdaptiveText>
            </Line>
        </Fragment>
    );
};

export default MenuList;
