import { useEffect } from 'react';
import { hookstate, useHookstate } from '@hookstate/core';
import { useParams } from 'react-router-dom';
import { generateEventPath } from 'utils/navigation';
import { extractProductId } from 'features/EventBoard/utils/common';

const initialDate = hookstate('');
const initialProduct = hookstate('');
const initialTrack = hookstate('');
const initialRace = hookstate('');

export interface URLParameters {
    date: string;
    product: string;
    track: string;
    race: string;
}

/**
 * React router useParams() works only when calling from the child component of <Router> (the root page component)
 * This component uses additional global state in order to make URL parameters reachable across the UI
 */
const useURLParameters = () => {
    const { date, product, track, race } = useParams<URLParameters>();

    const dateState = useHookstate(initialDate);
    const productState = useHookstate(initialProduct);
    const trackState = useHookstate(initialTrack);
    const raceState = useHookstate(initialRace);

    const syncURLParameters = () => {
        date && dateState.set(date);
        product && productState.set(product);
        track && trackState.set(track);
        race && raceState.set(race);
    };

    const clearURLParameters = () => {
        dateState.set('');
        productState.set('');
        trackState.set('');
        raceState.set('');
    };

    const generateURL = ({ product, date, track, race }: any) => {
        const productId = extractProductId(product ?? productState.get());

        return generateEventPath({
            productId,
            date: date ?? dateState.get(),
            track: track ?? trackState.get(),
            race: race ?? raceState.get(),
        });
    };

    useEffect(syncURLParameters, [date, product, track, race]);

    return {
        date: dateState.get(),
        product: productState.get(),
        track: trackState.get(),
        race: raceState.get(),
        // next 2 functions should be called only in the component inside <Route/>
        syncURLParameters,
        clearURLParameters,
        generateURL,
    };
};

export default useURLParameters;
