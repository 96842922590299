import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { request } from 'utils/server';
import useTrackPage from './useTrackPage';
import useURLParameters from './useURLParameters';
import { useCallback, useMemo } from 'react';
import { hasResultByRaceNr, isVProduct } from 'features/EventBoard/server/calendar';

interface QueryParameters {
    date: string;
    trackId: number;
    poolType: string;
    actualTrack: number;
}

export interface WinnersProps {
    horse: string;
    distibution: number;
    finishTime: string;
    winPos: number;
    driver: string;
    pOdds: number;
    startPos: number;
    finishPos: string;
    vOdds: number;
}

interface LegsProps {
    winners: Array<WinnersProps>;
    race: number;
    distance: number;
    systemsLeft: number;
    finalReserves: string;
    leg: number;
    postTime: string;
    mergeInfo: Array<string>;
    winodds: Array<any>;
    cancelled: string;
    track: number;
    value: number;
    status: string;
}

interface PoolResultsQueryData {
    payouts: Array<any>;
    country: string;
    product: string;
    systemCount: number;
    legs: Array<LegsProps>;
    turnover: number;
    tracks: Array<number>;
    status: string;
}

const fetchPoolResults = async ({ date, trackId, poolType, actualTrack }: QueryParameters) => {
    const response = await request<Array<PoolResultsQueryData>, QueryParameters>(
        'Results',
        'POST',
        `fetchPoolResults`,
        {
            date: moment(date).format('YYYY-MM-DD'),
            trackId,
            poolType: poolType === 'VP' ? 'V' : poolType,
            actualTrack,
        }
    );
    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const usePoolResultsQuery = () => {
    const { date, race: raceNumber } = useURLParameters();
    const { trackId, productId, raceDay, product, trackNames } = useTrackPage();

    const { status, data, error, isSuccess, isLoading } = useQuery<
        unknown,
        Error,
        Array<PoolResultsQueryData>
    >({
        queryKey: ['poolResults', date, trackId, productId],
        queryFn: () =>
            fetchPoolResults({ date, trackId, poolType: productId, actualTrack: trackId }),
        enabled:
            hasResultByRaceNr(raceDay, raceNumber) &&
            trackId > 0 &&
            !!date &&
            !isVProduct(productId),
        staleTime: 60 * 10 * 1000,
    });

    const sortWinners = useCallback((winners: Array<WinnersProps>) => {
        return winners
            .filter(item => parseInt(item.finishPos, 10) > 0)
            .sort((a, b) => (parseInt(a.finishPos, 10) > parseInt(b.finishPos, 10) ? 1 : -1));
    }, []);

    const winners = useMemo((): Array<WinnersProps> => {
        return data?.length
            ? sortWinners(data[Number(raceNumber) - 1]?.legs[0]?.winners).slice(0, 3)
            : [];
    }, [data, raceNumber, sortWinners]);

    return {
        status,
        results: data,
        winners,
        raceNumber,
        productId,
        productName: product.name,
        trackName: trackNames.join(' '),
        error,
        isSuccess,
        isLoading,
    };
};

export default usePoolResultsQuery;
