import React from 'react';
import * as HistoryREST from 'features/History/RequestHandler';
import ListView from './ListView';

class HistoryContainer extends React.Component {
    pageNamber = 1;
    pageSize = 10;
    dataHandlerName = null;
    mode = 'active';

    constructor() {
        super();

        this.state = {
            data: [],
            isPending: false,
            dataCounter: 0,
        };
    }

    setDataHandlerName = (dataHandlerName) => {
        this.dataHandlerName = dataHandlerName;
    };

    setMode = (mode) => {
        this.mode = mode;
    };

    componentDidMount() {
        if (this.props.auth.user) {
            this.getData();
        }
    }

    componentDidUpdate() {
        if (this.props.auth.user && !this.state.data) {
            this.getData();
        }
    }

    getData() {
        this.setState({
            isPending: true,
        });

        HistoryREST[this.dataHandlerName](
            this.pageNamber,
            this.pageSize,
            this.props.auth.user.id
        ).then((res) => {
            if (res.data) {
                this.setState({
                    isPending: false,
                    data: [...this.state.data, ...res.data],
                    dataCounter: res.size,
                });
            } else {
                this.setState({
                    isPending: false,
                });
            }
        });
    }

    loadMore = () => {
        this.pageNamber++;

        this.getData();
    };

    render() {
        return (
            <ListView
                {...this.state}
                loadMore={this.loadMore}
                pageNamber={this.pageNamber}
                pageSize={this.pageSize}
                mode={this.mode}
                showModal={this.props?.showModal}
            />
        );
    }
}

export default HistoryContainer;
