import axios from 'axios';
import moment from 'moment';
import { BATCH_BETTING_REST_PATH } from 'configs/rest';
import { getLS, saveLS } from '../../common/storage/localStor';
import { ServerResponse } from '../../types';
import { PRODUCT_IDS } from '../../configs/products';
//@ts-ignore
import uuid from 'uuid/v4';
import { BetStatus } from './types';

export interface ParseXMLDataResponse {
    total: {
        numberOfRows: number;
        numberOfSystems: number;
        price: number;
        product: string;
        totalCoupons: number;
        trackId: number;
        trackName: string | null;
        startTime: string;
    };
}

export const createNullParseXMLDataResponse = (): ParseXMLDataResponse => ({
    total: {
        numberOfRows: 0,
        numberOfSystems: 0,
        price: 0,
        product: PRODUCT_IDS.V4,
        totalCoupons: 0,
        trackId: 0,
        trackName: '',
        startTime: '',
    },
});

export const parseXMLData = async (xml: File): Promise<ServerResponse<ParseXMLDataResponse>> => {
    const formData = new FormData();

    formData.append('file', xml);

    const fingerPrint = uuid();
    const timestamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');

    saveLS({ bbfp: fingerPrint, bbts: timestamp });

    const response = await axios.post(`${BATCH_BETTING_REST_PATH}parseXmlData`, formData, {
        params: {
            fingerPrint,
            timestamp,
        },
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};

export const performBatchBet = async (): Promise<BetStatus> => {
    const fingerPrint = getLS('bbfp');
    const timestamp = getLS('bbts');

    const response = await axios.post(`${BATCH_BETTING_REST_PATH}performBatchBet`, undefined, {
        params: { fingerPrint, timestamp },
        withCredentials: true,
    });

    if (!response.data.success) {
        throw response.data.errorMessage;
    }

    return response.data.data;
};

export const downloadBatchFile = async (transactionId: number): Promise<any> => {
    const response = await axios.get(`${BATCH_BETTING_REST_PATH}downloadBatchFile`, {
        params: { transactionId },
        withCredentials: true,
    });

    return response.data;
};

export const buildDownloadBatchFileLink = (transactionId: number) => {
    return `${BATCH_BETTING_REST_PATH}downloadBatchFile?transactionId=${transactionId}`;
};
