import React from 'react';
import { BetPickBtn } from './PickButtons.styled';

const TrioPickColumnButtons = () => {
    return (
        <div className="flex col-gap-5 pl-15 fade-in">
            <BetPickBtn>1</BetPickBtn>
            <BetPickBtn>2</BetPickBtn>
            <BetPickBtn>3</BetPickBtn>
        </div>
    );
};

export default TrioPickColumnButtons;
