import { ComponentType } from 'react';
import { ChildrenProps, ClickableComponent, DraggableComponent } from '../../ui/types';
import { UploadAreaContainerProps } from './components/styled';

export enum BetStatus {
    pending = 'IN_QUEUE',
    failed = 'FAILED',
    accepted = 'PLACED',
    null = 'NO_ATTEMPTS_YET',
}

export interface OnPlaceBetResponse {
    (status: BetStatus): void;
}

export interface UploadHandler {
    (files: FileList): void;
}

export interface CustomComponents {
    BatchBettingText?: ComponentType;
    BatchBettingTitle?: any;
    UploadButton?: ComponentType;
    BetConfirmButton?: ComponentType;
    UploadAreaContainer?: ComponentType<
        UploadAreaContainerProps & DraggableComponent & ClickableComponent<HTMLDivElement>
    >;
}

export interface playVideoProps {
    (videoId: string, videoTitle: string, videoDescription: string): void;
}
export interface VideoProps {
    link: string;
    title?: string;
    description: string;
}

export interface VideoItemProps {
    playVideo: playVideoProps;
    videoLink: string;
    videoTitle?: string;
    description?: string;
}

export interface VideoThumbnailProps {
    videoLink: string;
}
