import { useSelector } from 'react-redux';
import User from '../DataObjects/User';
import { getAuth } from '../selectors/auth';

export interface Auth {
    pending: boolean;
    user: User | null;
    error: string | null;
    login: LoginStatus;
    pin: Pin;
}

interface LoginStatus {
    pending: boolean;
    success: boolean;
    error: string | null;
    initiated: boolean;
}

interface Pin {
    hash: string | null;
    userName: string | null;
    attemptsLeft: 0;
    error: string | null;
    pending: boolean;
}

const useAuth = (): Auth => {
    return useSelector(getAuth);
};

export default useAuth;
