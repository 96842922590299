import React, { useEffect } from 'react';
import useUnwrappedModals from 'common/hooks/useUnwrappedModals';
import { MODALS } from '../config';
import useBodyScrollLock from 'common/hooks/useBodyScrollLock';
import Screens from './Screens';
import useScreens from '../hooks/useScreens';

export interface BetBuddyEntryProps {
    sharedLink?: boolean;
}

const BetBuddyEntry = () => {
    const { isShown, hide, getData } = useUnwrappedModals();
    const rootModalShown = isShown(MODALS.BET_BUDDY_ENTRY);
    const rootModalData = getData(MODALS.BET_BUDDY_ENTRY);

    const [lockBodyScroll, unlockBodyScroll] = useBodyScrollLock(false, 300);

    const [
        displayScreen,
        closeScreen,
        screenShown,
        screens,
        closeAll,
    ] = useScreens();

    useEffect(
        () => {
            if (rootModalShown) {
                displayScreen(rootModalData.showScreens);
            } else {
                closeAll();
            }
        },
        [rootModalShown, rootModalData.showScreens]
    );

    return (
        <Screens
            screens={screens}
            closeScreen={closeScreen}
            closeAll={closeAll}
            displayScreen={displayScreen}
            screenShown={screenShown}
            onRootScreenClosed={() => {
                hide(MODALS.BET_BUDDY_ENTRY);
                unlockBodyScroll();
            }}
            onRootScreenOpen={lockBodyScroll}
        />
    );
};

export default BetBuddyEntry;
