import React from 'react';
import { connect } from 'react-redux';
import noop from 'lodash/noop';

import { login } from 'common/actions/authActions';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { Login as LoginDesktop, WithClearSafariPageCache } from '@it25syv/25syv-ui';
import { TransparentBackground } from 'ui/Popup';
import Modal from 'ui/ModalContent';
import { hidePopup, history, push } from 'utils/navigation';
import persistentStorage from '../../common/storage';
import storage from '../../common/storage';
import { showModal } from 'common/actions/uiActions';

class DesktopLogin extends React.Component {
    state = {
        login: '',
        pwd: '',
    };

    closeModal = () => {
        const { customModalCloseHandler = noop } = this.props;
        hidePopup('LOGIN');
        customModalCloseHandler();
    };

    componentDidUpdate(prevProps) {
        if (!prevProps.auth.user && this.props.auth.user) {
            const { redirectOnAuth, pushOnAuth, replaceOnAuth, locationState, onAuthSuccess } =
                this.props;

            if (redirectOnAuth) {
                window.location = redirectOnAuth;
            }

            if (pushOnAuth) {
                return push(pushOnAuth, locationState);
            }

            if (replaceOnAuth) {
                return history.replace(replaceOnAuth, locationState);
            }

            if (onAuthSuccess) {
                onAuthSuccess();
            }

            hidePopup('LOGIN');
        }

        if (this.props.auth.error && !prevProps.auth.error) {
            NotificationConductor.error(this.props.auth.error);
        }
    }

    loginHandler = (userName, password, setLoading, onSuccess = () => {}, onError = () => {}) => {
        if (userName && password) {
            this.props.login(userName, password, setLoading, onSuccess, onError);
            storage.saveLS({ userName: userName });
        } else {
            console.log('Handle error'); //todo handle error
        }
    };

    render() {
        const { priority } = this.props;
        return (
            <Modal priority={priority}>
                <TransparentBackground>
                    <LoginDesktop
                        closeAction={this.closeModal}
                        site={'heste'}
                        loginHandler={this.loginHandler}
                        isMitIdLogin={!persistentStorage.get('isItInitialLogin')}
                        forgotPassHandler={this.props.showForgotPassword}
                        showResetPassword
                    />
                </TransparentBackground>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (userName, password, setLoading, onSuccess = () => {}, onError = () => {}) => {
            dispatch(login(userName, password, setLoading, onSuccess, onError));
        },
        showForgotPassword: () => {
            dispatch(showModal('FORGOT_PASSWORD', 2));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(WithClearSafariPageCache(DesktopLogin));
