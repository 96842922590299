import React, { Component } from 'react';
import RaceCard from './RaceCard';
import { connect } from 'react-redux';
import {
    addAllBets,
    addBet,
    addReserve,
    markTrioRow,
    removeBet,
    removeLastReserve,
    removeReserve,
    resetBet,
    resetRaceBet,
    unmarkTrioRow,
} from 'features/BetSlip/state/actions';
import { priority } from 'configs/layout';
import { getTrackFromRacingCard } from 'common/selectors/trackSelector';
import { showModal } from 'common/actions/uiActions';
import poolOverviewSelector from 'common/selectors/poolOverviewSelector';
import Mark from 'ui/Mark';
import Wrapper from 'ui/Wrapper';
import shoeInfoSelector from 'common/selectors/shoeInfoSelector';
import { getDisabledRaceNumbers } from 'common/selectors/racesSelector';
import { getCurrentRaceReserves, getTrioMarksIndicator } from 'common/selectors/betSlipSelector';
import betTableSelector from 'common/selectors/betTableSelector';
import productSelector from 'common/selectors/productSelector';
import { getSelectedDate, getServerTime } from 'common/selectors/raceDaySelector';

class RaceCardContainer extends Component {
    constructor() {
        super();

        this.state = {
            hasError: false,
            raceStarted: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.race.raceNumber !== prevProps.race.raceNumber ||
            this.props.race.trackCode !== prevProps.race.trackCode
        ) {
            this.setState({ raceStarted: false });
        }
    }

    componentDidCatch(error, info) {
        console.log(error, info);
        this.setState({ hasError: true });
    }

    raceFinishedHandler = () => {
        this.setState({ raceStarted: true });
        //this.props.resetRaceBet(date, trackCode, productId, race.raceNumber);
    };

    render() {
        if (this.state.hasError) {
            return (
                <Wrapper>
                    <Mark grey>Could not display the racing card.</Mark>
                </Wrapper>
            );
        }
        return (
            <RaceCard
                {...this.props}
                onRaceFinished={this.raceFinishedHandler}
                raceStarted={this.state.raceStarted}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const track = getTrackFromRacingCard(state);
    return {
        poolOverview: poolOverviewSelector(state),
        selections: betTableSelector(state),
        rawPool: state.AISDataProvider.trackPool,
        trackPoolFetched: state.AISDataProvider.trackPoolFetched,
        date: getSelectedDate(state),
        serverTime: getServerTime(state),
        track,
        trackId: track.id,
        trackCode: track.code,
        productId: productSelector(state).id,
        selectedBets: state.BetSlip.selectedBets,
        shoeInfo: shoeInfoSelector(state),
        disabledRaceNumbers: getDisabledRaceNumbers(state),
        trioMarksIndicator: getTrioMarksIndicator(state),
        reserves: getCurrentRaceReserves(state),
        selectedProduct: productSelector(state),
        selectedDate: getSelectedDate(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        markTrioRow: (date, trackId, raceNr, row) => {
            dispatch(markTrioRow(date, trackId, raceNr, row));
        },
        unmarkTrioRow: (date, trackId, raceNr, row) => {
            dispatch(unmarkTrioRow(date, trackId, raceNr, row));
        },
        addAllBets: (date, trackId, productId, raceNr, betsByDates) => {
            dispatch(addAllBets(date, trackId, productId, raceNr, betsByDates));
        },
        addBet: (date, trackId, productId, raceNr, startNr, betValue) => {
            dispatch(addBet(date, trackId, productId, raceNr, startNr, betValue));
        },
        removeBet: (date, trackId, productId, raceNr, startNr, betValue) => {
            dispatch(removeBet(date, trackId, productId, raceNr, startNr, betValue));
        },
        addReserve: (date, trackId, productId, raceNr, startNr) => {
            dispatch(addReserve(date, trackId, productId, raceNr, startNr));
        },
        removeReserve: (date, trackId, productId, raceNr, startNr) => {
            dispatch(removeReserve(date, trackId, productId, raceNr, startNr));
        },
        removeLastReserve: (date, trackId, productId, raceNr, startNr) => {
            dispatch(removeLastReserve(date, trackId, productId, raceNr, startNr));
        },
        resetBet: () => {
            dispatch(resetBet());
        },
        resetRaceBet: (date, trackCode, productId, raceNumber) => {
            dispatch(resetRaceBet(date, trackCode, productId, raceNumber));
        },
        showStatisticPopup: (data) => {
            dispatch(showModal('GAME_STATISTIC', 0, data));
        },
        showReplayPopup: (data) => {
            dispatch(showModal('REPLAY', priority.replays, data));
        },
        showQuickPickAmountModal: (data) => {
            dispatch(showModal('QUICK_PICK_AMOUNT', -896, data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RaceCardContainer);
