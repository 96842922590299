import React from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

import Text from 'ui/Text';
import { PRODUCT_COLORS, PRODUCT_HOVER_COLORS } from 'configs/products';

import { ProductId, RaceDay, Shortcut } from 'features/EventBoard/server/calendar';
import {
    getSupportedProducts,
    hasProductOngoingRace,
    isAvailableForBetting,
} from 'features/EventBoard/server/calendar';
import { COLORS } from 'features/EventBoard/theme';
import { getJackpotProducts, sortProducts } from './Shortcuts/helpers';
import useShortcutState from '../hooks/useShortcutState';
import JackpotLabel from './JackpotLabel';

interface ProductsListProps {
    raceDay: RaceDay;
}

export const ProductsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`;

interface ProductButtonProps {
    background?: string;
    disabled?: boolean;
    hoverBackground?: string;
}

const disabledButtonCSS = css<ProductButtonProps>`
    cursor: pointer;

    &:hover {
        //box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
        background: ${({ hoverBackground, background }) => hoverBackground || background};
        transition: background 300ms ease;
    }
`;

export const ProductButton = styled.div<ProductButtonProps>`
    position: relative;
    border-radius: 5px;
    background-color: ${({ background }) => background || '#2CB16F'};
    padding: ${isMobile ? '2px 15px' : '5px 25px'};
    transition: box-shadow 0.3s ease-in-out;

    ${({ disabled }) => !disabled && disabledButtonCSS}}
`;

export const getProductColor = (product: string) => PRODUCT_COLORS[product];
const getProductHoverColor = (product: string) => PRODUCT_HOVER_COLORS[product];

const TrackProducts = ({ raceDay }: ProductsListProps) => {
    const supportedProducts = sortProducts(getSupportedProducts(raceDay));

    const { setShortcut } = useShortcutState();

    const navigateToProduct = (productId: ProductId) => {
        const productShortcut: Shortcut = {
            raceDate: raceDay.raceDayDate,
            trackId: raceDay.trackId,
            product: productId,
        };
        setShortcut(productShortcut);
    };

    return (
        <ProductsContainer>
            {isAvailableForBetting(raceDay) &&
                supportedProducts.map((productId, index) => {
                    const hasOngoing = hasProductOngoingRace(raceDay, productId);
                    const background = hasOngoing
                        ? COLORS.resultedProductBg
                        : getProductColor(productId);
                    const hoverBackground = hasOngoing
                        ? COLORS.resultedProductBg
                        : getProductHoverColor(productId);

                    const color = hasOngoing ? COLORS.resultedProductText : 'white';

                    return (
                        <ProductButton
                            key={index}
                            background={background}
                            hoverBackground={hoverBackground}
                            onClick={() => navigateToProduct(productId)}
                            disabled={hasOngoing}
                        >
                            <Text color={color} bold align={'center'}>
                                {productId}
                            </Text>
                            {!isMobile &&
                            getJackpotProducts(raceDay.productsWithJackpot).includes(productId) ? (
                                <JackpotLabel />
                            ) : null}
                        </ProductButton>
                    );
                })}
        </ProductsContainer>
    );
};

export default TrackProducts;
