import React, { useEffect } from 'react';
import { BatchBettingTextDef, BatchBettingTitleDef } from './components/styled';
import UploadArea from './components/UploadArea';
import useUpload from './useUpload';
import SystemSummary from './components/SystemSummary';
import { CustomComponents } from './types';
import useSystemBet from './useSystemBet';
import useModals from 'common/hooks/useModals';
import useInitialized from 'common/hooks/useInitialized';
import useRefreshBalance from 'common/hooks/useRefreshBalance';

interface BatchBettingProps {}

const BatchBetting = ({
    BatchBettingText = BatchBettingTextDef,
    BatchBettingTitle = BatchBettingTitleDef,
    UploadAreaContainer,
    UploadButton,
}: BatchBettingProps & CustomComponents) => {
    const {
        FileInput,
        openFileBrowser,
        upload,
        uploadPending,
        uploadSuccess,
        fileData,
        fileName,
        reset,
        error: uploadError,
    } = useUpload();

    const refreshBalance = useRefreshBalance();

    const { show, hide } = useModals();

    const { success, error: betError, status: betStatus } = useSystemBet();

    const firstRenderPassed = useInitialized();

    useEffect(() => {
        if (firstRenderPassed && (success || betError)) {
            hide('BATCH_BETTING');
            show('BATCH_BETTING_STATUS', 1, { betStatus });
        }
        if (firstRenderPassed && success) {
            refreshBalance();
        }
    }, [success, betError, firstRenderPassed, hide, show, betStatus, refreshBalance]);

    return (
        <>
            <FileInput />

            {!uploadSuccess ? (
                <BatchBettingTitle>Upload dit systemspil fra jokersystemet.se</BatchBettingTitle>
            ) : (
                <BatchBettingTitle>Bekræft og køb spil</BatchBettingTitle>
            )}

            {!uploadSuccess ? (
                <UploadArea
                    openFileBrowser={openFileBrowser}
                    pending={uploadPending}
                    onUpload={upload}
                    UploadAreaContainer={UploadAreaContainer}
                    UploadButton={UploadButton}
                    error={uploadError}
                />
            ) : (
                <SystemSummary
                    openFileBrowser={openFileBrowser}
                    fileData={fileData}
                    fileName={fileName}
                    removeFile={reset}
                />
            )}

            {/*<PlaceBetButton*/}
            {/*    uploadSuccess={uploadSuccess}*/}
            {/*    couponPrice={fileData.total.price}*/}
            {/*/>*/}
        </>
    );
};

export default BatchBetting;
