const indents = [5, 10, 15, 20];

export const fullWidth = () => `
    &.full-width {
        width: 100%;
        margin: 0;
    }
`;
export const fullHeight = () => `
    &.full-height {
        height: 100%;
    }
`;
export const uppercase = () => `
    &.uppercase {
        text-transform: uppercase;
    }
`;
export const lowercase = () => `
    &.lowercase {
        text-transform: lowercase;
    }
`;
export const capitalize = () => `
    &.capitalize {
        text-transform: capitalize;
    }
`;
export const flex = () => `
    &.flex {
        display: flex;
    }
    &.flex-col {
        flex-direction: column;
    }
    &.center-v {
        display: flex;
        align-items: center;
    }
    &.align-center {
        align-items: center;
    }
    &.space-between {
        display: flex;
        justify-content: space-between;
    }
    &.justify-evenly {
        display: flex;
        justify-content: space-evenly;
    }
    &.flex-end {
        display: flex;
        justify-content: flex-end;
    }
    &.flex-grow {
        flex-grow: 1;
    }
`;
export const textBold = () => `
    &.text-bold {
        font-weight: 900;
    }
`;
export const textUnderline = () => `
    &.text-underline {
        text-decoration: underline;
    }
`;
export const pointer = () => `
    &.pointer {
        cursor: pointer;
    }
`;
export const textCenter = () => `
    &.text-center {
        text-align: center;
    }
`;
export const textLeft = () => `
    &.text-left {
        text-align: left;
    }
`;
export const textRight = () => `
    &.text-right {
        text-align: right;
    }
`;

export const resetLink = () => `
    &.reset-link {
        a {
            text-decoration: none;
            color: inherit;
        }

        text-decoration: none;
        color: inherit;
    }
`;

export const blockCenter = () => `
    &.block-center {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
`;

export const verticalSpaceBetween = () => `
    &.vertical-space-between {
        display: flex;
        justify-content: space-evenly;
        flex-flow: column;
    }
`;

export const withoutScroll = () => `
    &.without-scroll {
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: -webkit-fill-available;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
         -webkit-overflow-scrolling: touch;
    }
`;

export const shortText = () => `
    &.short-text-100 {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        width: 100px;
    }
`;

export const fadeIn = () => `
    &.fade-in {
      visibility: visible;
      opacity: 1;
      transition: opacity 500ms linear;
    }
`;

export const fadeOut = () => `
    &.fade-out {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s 500ms, opacity 500ms linear;
    }
`;

export const gaps = () => `
    ${indents.map(
        indent => `
        &.gap-${indent} {
            gap: ${indent}px;
        }
    `
    )};
    
    ${indents.map(
        indent => `
        &.col-gap-${indent} {
            column-gap: ${indent}px;
        }
    `
    )};
`;
export const paddings = () => `
    ${indents.map(
        indent => `
        &.p-${indent} {
            padding: ${indent}px;
        }
        &.pl-${indent} {
            padding-left: ${indent}px;
        }
        &.pr-${indent} {
            padding-right: ${indent}px;
        }
        &.pt-${indent} {
            padding-top: ${indent}px;
        }
        &.pb-${indent} {
            padding-bottom: ${indent}px;
        }
    `
    )};
`;

export const grid = () => `
    &.grid {
        display: grid;
    }
`;
