import React from 'react';
import UserConductor from 'common/conductors/UserConductor';
import moment from 'moment';
import { ContentHeight, FullScreen } from 'ui/FullScreen';
import styled from 'styled-components';
import TopBar from 'ui/topbar';
import Wrapper from 'ui/Wrapper';
import FlexWrapper from 'ui/FlexWrapper';
import TopBarText from 'ui/topbar/Text';
import Button from 'ui/controls/Button';
import Icon from 'ui/Icon';
import Text, { TextLink } from 'ui/Text';
import Arrow from 'ui/Arrow';
import Spinner from 'ui/Spinner';
import { Col, Row } from 'react-grid-system';
import DatePicker from 'ui/DatePicker';
import { byPlatform, isDesktop, isMobile } from 'utils/platforms';
import { withRouter } from 'react-router-dom';
import calendarIcon from 'icons/calendar.svg';
import getTexts from 'utils/localization';
import COLORS from 'themes/dk/colors';

const t = getTexts();

const Header = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    color: ${COLORS.black};
`;
const TimeRange = styled.div`
    padding: 5px;
    border-radius: 5px;
    background-color: #ebeff6;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 12px;
    color: ${COLORS.black};
    height: 12px;
    cursor: pointer;
`;

class Transactioners extends React.Component {
    systemDateFormat = 'DDMMYYYY';
    uiDateFormat = 'DD MMM. YYYY';

    constructor() {
        super();

        this.state = {
            data: [],
            shouldShowMore: true,
            count: 15,
            dateFrom: moment().subtract(1, 'month').toDate(),
            dateTo: moment().toDate(),
            datePicker: null,
            setDateMode: true,
        };
    }

    componentDidMount() {
        isMobile && this.props.enableCloseButton();
    }

    openDatePicker = mode => {
        this.setState({
            count: 15,
            datePicker: (
                <DatePicker
                    maxDate={new Date()}
                    height={byPlatform(null, 445)}
                    width={byPlatform('100%', 537)}
                    small={!!this.props.desktop}
                    onDateSelected={this.onDateSelected.bind(this, mode)}
                    selectedDate={this.state[mode]}
                />
            ),
        });

        if (this.props.disableCloseButton) this.props.disableCloseButton();
    };

    onDateSelected = (mode, date) => {
        // it was disabled in openDatePicker handler, so have to enable close button again
        isMobile && this.props.enableCloseButton();

        if (mode === 'dateFrom') {
            this.setState({
                dateFrom: date,
                datePicker: null,
            });
        } else {
            this.setState({
                dateTo: date,
                datePicker: null,
            });
        }
    };

    showData = () => {
        this.setState({
            setDateMode: false,
            shouldShowMore: true,
        });
        UserConductor.getTransactionListAll(
            moment(this.state.dateFrom).format(this.systemDateFormat),
            moment(this.state.dateTo).format(this.systemDateFormat),
            0,
            this.state.count
        ).then(res => {
            let transactions;
            if (res.data) {
                transactions = res.data.transactions ? res.data.transactions : res.data;
            }

            this.setState({
                data: transactions ? transactions : [],
                shouldShowMore: res.data.count > this.state.data.length + 15,
            });
        });
    };

    chooseNewDate = () => {
        this.setState({
            setDateMode: true,
        });
    };
    showMore = () => {
        UserConductor.getTransactionListAll(
            moment(this.state.dateFrom).format(this.systemDateFormat),
            moment(this.state.dateTo).format(this.systemDateFormat),
            0,
            this.state.count + 15
        ).then(res => {
            const transactions = res.data.transactions ? res.data.transactions : res.data;

            this.setState({
                shouldShowMore: res.data.count > this.state.data.length + 15,
                count: this.state.count + 15,
                data: transactions ? transactions : [],
            });
        });
    };

    render() {
        const { dateFrom, dateTo, data, shouldShowMore } = this.state;

        return (
            <FullScreen>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <TopBar>
                            <TopBarText className="lg">{t.userArea.nav.transactions}</TopBarText>
                        </TopBar>
                    </ContentHeight>
                ) : null}
                <Wrapper>
                    {this.state.setDateMode ? (
                        <Wrapper>
                            <Text>{t.Transactions.showFrom}</Text>
                            <Button
                                onClick={this.openDatePicker.bind(this, 'dateFrom')}
                                lightGrey
                                width="100%"
                                block
                                rounded={30}
                            >
                                {dateFrom ? (
                                    <Text align="center">
                                        {moment(dateFrom).format(this.uiDateFormat)}
                                    </Text>
                                ) : (
                                    <FlexWrapper padding="0px" direction="row" align="space-around">
                                        <Text>{t.Transactions.showFrom}</Text>
                                        <Arrow block direction="down" margin="12px 10px 0px" />
                                    </FlexWrapper>
                                )}
                            </Button>
                            <Text>{t.Transactions.showTo}</Text>
                            <Button
                                onClick={this.openDatePicker.bind(this, 'dateTo')}
                                lightGrey
                                width="100%"
                                block
                                rounded={30}
                            >
                                {dateTo ? (
                                    <Text align="center">
                                        {moment(dateTo).format(this.uiDateFormat)}
                                    </Text>
                                ) : (
                                    <FlexWrapper padding="0px" direction="row" align="space-around">
                                        <Text>{t.Transactions.showTo}</Text>
                                        <Arrow block direction="down" margin="12px 10px 0px" />
                                    </FlexWrapper>
                                )}
                            </Button>

                            <Button
                                block
                                size="x3"
                                accent
                                margin="50px auto 10px"
                                rounded={30}
                                width="100%"
                                onClick={this.showData}
                            >
                                VIS
                            </Button>
                        </Wrapper>
                    ) : (
                        <Wrapper>
                            <Header>
                                <Text grey padding="0" size="x1" bold>
                                    {t.Transactions.transactionsFor}
                                </Text>
                                <TimeRange onClick={this.chooseNewDate}>
                                    <Icon
                                        size={'x025'}
                                        margin="0 5px 0 0"
                                        color="#404757"
                                        src={calendarIcon}
                                        width="11px"
                                        height="13px"
                                    />
                                    {moment(dateFrom).format(this.uiDateFormat)} -{' '}
                                    {moment(dateTo).format(this.uiDateFormat)}
                                </TimeRange>
                            </Header>

                            {data ? (
                                data.length > 0 ? (
                                    data.map((item, index) => (
                                        <Row key={index}>
                                            <Col>
                                                <Text padding={isMobile ? '1px 0' : '10px 0'} black>
                                                    {item.text}
                                                </Text>
                                                {isMobile ? (
                                                    <Text padding="1px 0 10px 0" black>
                                                        {moment(item.transactionDate).format(
                                                            this.uiDateFormat
                                                        )}
                                                    </Text>
                                                ) : null}
                                            </Col>
                                            {isDesktop ? (
                                                <Col>
                                                    <Text padding="10px 0" black>
                                                        {moment(item.transactionDate).format(
                                                            this.uiDateFormat
                                                        )}
                                                    </Text>
                                                </Col>
                                            ) : null}

                                            <Col>
                                                <Text black>
                                                    {item.amount} {t.currency}
                                                </Text>
                                            </Col>
                                            <Col>
                                                <Text black>
                                                    {item.balance} {t.currency}
                                                </Text>
                                            </Col>
                                        </Row>
                                    ))
                                ) : (
                                    <Wrapper>{t.dataNotFound}</Wrapper>
                                )
                            ) : (
                                <Spinner />
                            )}
                            {shouldShowMore ? (
                                <Button
                                    block
                                    size="x2"
                                    margin="10px auto"
                                    rounded={10}
                                    width="100%"
                                    onClick={this.showMore}
                                >
                                    {t.readMore}
                                </Button>
                            ) : null}
                        </Wrapper>
                    )}
                </Wrapper>

                {this.state.datePicker}

                <Wrapper padding="20px">
                    <Text size="15" color="#646464" weight="700" style={{ marginTop: '50px' }}>
                        For en fuld transaktionsoversigt, eller en liste af transaktioner for et
                        givet tidsrum bedes du rette henvendelse til vores&nbsp;
                        <TextLink bold onClick={() => window.Intercom('show')}>
                            kundeservice
                        </TextLink>
                        .
                    </Text>
                </Wrapper>
            </FullScreen>
        );
    }
}

export default withRouter(Transactioners);
