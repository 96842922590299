import React from 'react';
import ProductTips, { productTipsAppearance } from './ProductTips';
import { forMobile } from 'utils/platforms';
import { Row } from '../styled';

const ProductTipsRow = () => {
    return (
        productTipsAppearance.get() &&
        forMobile(
            <Row>
                <ProductTips />
            </Row>
        )
    );
};

export default ProductTipsRow;
