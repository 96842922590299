import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import BetSlipContainer from 'features/BetSlip2/BetSlipContainer';
import useModals from 'common/hooks/useModals';
import { priorityModal, priority as modalPriority } from 'configs/layout';
import ModalContent, { useModalAnimation } from 'ui/ModalContent';
import CloseBtn from 'layouts/sections/Modals/CloseBtn';
import { resetBet } from 'features/BetSlip2/state/actions';
import { getSmartLynMode } from 'features/BetSlip2/hooks/useSmartLyn';

const BetSlipMobile = ({ priority }) => {
    const { show: showModal } = useModals();
    const { animateHide, onAnimationEnd, hiding } = useModalAnimation({ id: 'BETSLIP_MOBILE' });

    const dispatch = useDispatch();
    const smartLynMode = useSelector(getSmartLynMode);

    /**
     * Redirect to receipt modal
     * @return {boolean}
     */
    const navigateToReceipt = (betResponse, sharedProps) => {
        animateHide();

        smartLynMode
            ? showModal('SMART_LYN_RECEIPT', modalPriority.overlapBetslipNotifier, {
                  ...sharedProps,
                  receipt: betResponse.data,
                  legs: betResponse.data.coupon.legs,
                  nowrap: true,
              })
            : showModal('RECEIPT', modalPriority.overlapBetslipNotifier, {
                  combinationPrice: betResponse.coupon.combinationPrice / 100,
                  ...sharedProps,
                  totalCost: betResponse.receipt.totalCost,
                  nowrap: true,
              });
        dispatch(resetBet());
    };

    return (
        <ModalContent
            priority={priority}
            className={classNames('modal-fade', { 'modal-fade-out': hiding })}
            onAnimationEnd={onAnimationEnd}
        >
            <CloseBtn iclosewhite size="x05" onClick={animateHide} />
            <BetSlipContainer
                onAuthenticationRequired={() => showModal('LOGIN', priorityModal.loginMobile)}
                onMobileBetWorkflow={(prices, uSaleInfo, lockedLegs) =>
                    showModal('SAVED_BET_CONTAINER', 102, {
                        prices,
                        uSaleInfo,
                        lockedLegs,
                    })
                }
                onBetPerformed={navigateToReceipt}
            />
        </ModalContent>
    );
};

export default BetSlipMobile;
