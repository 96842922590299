import styled from 'styled-components';
import { COLORS } from 'themes';

import {
    TableHeader as TableHeaderDef,
    TableContent as TableContentDef,
    TableRowCheckered,
} from 'ui/Table';
import { HorseNrBtn as HorseNrBtnDef } from 'features/RacesCarusel/Forecast/styled';

export const TableHeader = styled(TableHeaderDef)`
    line-height: 100%;
    align-items: center;
    cursor: pointer;
`;

export const TableRow = styled(TableRowCheckered)`
    padding: 10px 15px;
    column-gap: 15px;
    align-items: center;
    display: grid;
    grid-template-columns: 10px 1fr;
`;

export const TableCell = styled.span`
    color: ${COLORS.primaryText};
    font-size: 16px;
`;

export const TableContent = styled(TableContentDef)`
    max-height: ${({ isOpen }) => (isOpen ? `500px` : '0px')};
    transition: max-height 0.3s ease-in-out;
`;

export const HorseNrBtn = styled(HorseNrBtnDef)`
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
    font-size: 16px;
`;

export const HorseName = styled.span`
    font-weight: 900;
`;

export const HorseTip = styled.span`
    font-weight: bold;
`;
