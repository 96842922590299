import React from 'react';
import Products from './Products';
import { connect } from 'react-redux';
import { setProduct, setProductShortcut } from 'features/AISDataProvider/actions';
import { withRouter } from 'react-router-dom';
import {
    getProductsFromSelectedTrack,
    getShortcutProducts,
} from 'common/selectors/productSelector';
import Product from 'common/DataObjects/Product';
import {
    PRODUCT_IDS,
    PRODUCTS_PRIORITIES,
    SAVE_RACE_NUMBER_BETWEEN_PRODUCTS,
} from 'configs/products.ts';
import { PreloaderButtons } from 'ui/RaceNavigation';
import { byPlatform } from 'utils/platforms';
import { noRacesForSelectedDay } from 'common/selectors/raceDaySelector';
import { extractEventParams, generateEventPath, history } from 'utils/navigation';

class ProductsContainer extends React.Component {
    onSelectProductHandler = product => {
        const {
            racingCardPending,
            setProduct,
            setProductShortcut,
            defaultRaceDay,
            selectedProduct,
        } = this.props;

        if (!racingCardPending) {
            // TODO: Move shortcuts functionality from products layer
            if (defaultRaceDay) {
                setProduct(product);
            } else {
                setProductShortcut(product);
            }
        }

        const { date, track, race } = extractEventParams();

        const shouldKeepRaceNumber =
            SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(selectedProduct.id) &&
            SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(product.id);

        const newRaceValue = shouldKeepRaceNumber ? race : 1;

        history.push(generateEventPath({ productId: product.id, date, track, race: newRaceValue }));
    };

    renderPreloader() {
        return (
            <PreloaderButtons
                count={byPlatform(5, 11, 10)}
                width={byPlatform('80px', '60px', '80px')}
            />
        );
    }

    render() {
        const {
            productsFromSelectedTrack,
            selectedRaceDay,
            racingCardPending,
            selectedProduct,
            shortcutProducts,
            trackPoolPending,
            trackPool,
            loading,
        } = this.props;

        if (loading) {
            return this.renderPreloader();
        }

        const shortcutMode = !selectedRaceDay;
        const todaysProductsInPool = Object.keys(trackPool);
        // VP is available always if Vinder and Plads are available
        if (
            todaysProductsInPool.includes(PRODUCT_IDS.V) &&
            todaysProductsInPool.includes(PRODUCT_IDS.P)
        ) {
            todaysProductsInPool.push(PRODUCT_IDS.VP);
        }

        let products = shortcutMode ? shortcutProducts : productsFromSelectedTrack;

        products = products.map(product => {
            if (!shortcutMode && !todaysProductsInPool.includes(product.id)) {
                return Product.fill({ ...product, disabled: true });
            }

            product.priority = PRODUCTS_PRIORITIES[product.id] || -1;

            return product;
        });

        return (
            <Products
                disabled={racingCardPending || trackPoolPending}
                onSelect={this.onSelectProductHandler}
                selectedProductId={selectedProduct?.id}
                data={products}
            />
        );
    }
}

const mapStateToProps = state => ({
    productsFromSelectedTrack: getProductsFromSelectedTrack(state),
    trackPoolPending: state.AISDataProvider.trackPoolPending,
    trackPool: state.AISDataProvider.trackPool,
    shortcutProducts: getShortcutProducts(state),
    selectedRaceDay: state.AISDataProvider.selectedRaceDay,
    selectedProduct: state.AISDataProvider.selectedProduct,
    racingCardPending: state.AISDataProvider.racingCardPending,
    raceDayData: state.AISDataProvider.raceDayData,
    defaultProduct: state.AISDataProvider.defaultProduct,
    defaultRaceDay: state.AISDataProvider.defaultRaceDay,
    loading: state.AISDataProvider.racingCardPending,
    noRaces: noRacesForSelectedDay(state),
});

const mapDispatchToProps = dispatch => {
    return {
        setProduct: product => {
            dispatch(setProduct(product));
        },
        setProductShortcut: product => {
            dispatch(setProductShortcut(product));
        },
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProductsContainer));
