import React from 'react';
import styled from 'styled-components';
import { byPlatform } from 'utils/platforms';
import { COLORS, BACKGROUNDS } from 'themes';
import { icons } from 'features/StreamVideo/config';

const StreamTypeContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 2.5px -5px 0 0;
`;

const StreamTypeBtn = styled.div`
    cursor: pointer;
    flex: 1 0 30%;
    border: 0.5px solid #c6ccdb;
    background: ${({ active }) =>
        active ? BACKGROUNDS.primaryFlat : BACKGROUNDS.lightGrey};
    color: ${({ active }) => (active ? COLORS.white : COLORS.black)};
    text-align: center;
    font-size: ${byPlatform('12px', '10px')};
    font-weight: 900;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: ${({ borderRadius }) => borderRadius};
    margin: 5px 5px 0 0;
    box-sizing: border-box;
    padding-left: 10px;
    transition: 200ms ease all;
`;

const Icon = styled.img`
    width: 13px;
    margin-right: 4px;
`;

const StreamType = ({ streamTypes, selectedStreamType, borderRadius }) => {
    return (
        <StreamTypeContainer>
            {Object.entries(streamTypes).map(
                ([streamType, { title, streamTypeSetter }]) => {
                    return (
                        <StreamTypeBtn
                            key={title}
                            onClick={streamTypeSetter}
                            active={streamType === selectedStreamType}
                            borderRadius={borderRadius}
                        >
                            <Icon src={icons[streamType]} />
                            {title}
                        </StreamTypeBtn>
                    );
                }
            )}
        </StreamTypeContainer>
    );
};

export default StreamType;
