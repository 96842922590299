import {
    extractBetType,
    extractFirstAvailableRace,
    extractMultitrackTrackNames,
    extractRaceDay,
    findMultipleTrackSetup,
    isMultitrackProduct,
} from 'features/EventBoard/server/calendar';

import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import { extractProductId } from 'features/EventBoard/utils/common';

import useRacesQuery from './useRacesQuery';
import useURLParameters from './useURLParameters';
import { isVProduct as isV, isStrictVProduct } from '../model/Product';
import { NEW_TRACK_PAGE } from 'configs/main';
import { PRODUCTS_NAMES } from 'configs/products';

const useTrackPage = ({ fetchRaces = true } = {}) => {
    const { date, product: productName, track: trackName, race: raceNumber } = useURLParameters();

    const {
        isSuccess: calendarFetched,
        data,
        isLoading: calendarLoading,
        error: calendarError,
    } = useCalendarQuery({ date, enabled: Boolean(date) });

    const raceDay = extractRaceDay(data.raceDays, trackName);

    const productId = extractProductId(productName);

    const betType = extractBetType(raceDay, productId);

    const trackId = raceDay?.trackId;

    const isMultitrack = raceDay ? isMultitrackProduct(raceDay, productId) : false;

    const product = { id: productId, name: PRODUCTS_NAMES[productId], isMultitrack };

    const multitrackSetup = raceDay ? findMultipleTrackSetup(raceDay, productId) : null;

    const trackNames = raceDay ? extractMultitrackTrackNames(raceDay, productId) : [];

    const isVProduct = isV(productId);

    const isVStrictProduct = isStrictVProduct(productId);

    const {
        data: races,
        poolQueryData,
        isLoading: racesLoading,
        isSuccess: racesFetched,
        error: racesError,
    } = useRacesQuery({
        date,
        racingCardTrackId: (isMultitrack ? multitrackSetup?.trackId : trackId) as number,
        poolTrackId: trackId as number,
        productId,
        isMultitrack,
        enabled: fetchRaces && NEW_TRACK_PAGE && Boolean(raceDay) && calendarFetched,
    });

    const raceIndex = Number(raceNumber) - 1;
    const race = { ...races[raceIndex], index: raceIndex };

    return {
        raceDay,
        betType,
        calendarFetched,
        calendarLoading,
        calendarError,
        product,
        productId,
        trackId,
        isMultitrack,
        multitrackSetup,
        isVProduct,
        isVStrictProduct,
        productPool: poolQueryData[productId],
        trackNames,
        races,
        racesLoading,
        racesFetched,
        racesError,
        race,
        postTime: isVProduct ? races[0]?.postTime : races[raceIndex]?.postTime,
    };
};

export default useTrackPage;
