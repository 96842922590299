import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getRacingCardPick, getRacingCardReserves } from 'common/selectors/betTableSelector';
import { reservesPath } from 'common/selectors/betSlipSelector';

import { ProductId, RaceDay } from 'features/EventBoard/server/calendar';
import { AnyRace } from 'features/TrackPage/model/Race';

interface Parameters {
    raceDay: RaceDay;
    productId: ProductId;
    race: AnyRace;
}

const useBetPicks = ({ raceDay, productId, race }: Parameters) => {
    const shortenProduct = { id: productId };

    const params = {
        date: raceDay.raceDayDate,
        trackCode: raceDay.track.code,
        product: shortenProduct,
        raceIndex: race.index,
    };

    const singleRaceBetPicks = useSelector((state) => getRacingCardPick(state, params));

    const racePicksMap = useSelector((state) =>
        get(
            state,
            ['BetSlip', 'betsByDates', raceDay.raceDayDate, raceDay.track.code, productId],
            {}
        )
    );

    const singleRaceReserves = useSelector((state) => getRacingCardReserves(state, params));

    const raceReservesMap = useSelector((state) =>
        get(state, reservesPath(raceDay.raceDayDate, raceDay.track.code, productId), [])
    );

    return { singleRaceBetPicks, singleRaceReserves, racePicksMap, raceReservesMap };
};

export default useBetPicks;
