import { cleanSystemBet, placeSystemBetAsync } from './actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../common/store';

const useSystemBet = () => {
    const dispatch = useDispatch();

    const pending = useSelector(
        (state: RootState) => state.BatchBetting.bet.pending
    );

    const placeBet = () => {
        !pending && dispatch(placeSystemBetAsync.request());
    };

    const clearBetInfo = () => {
        dispatch(cleanSystemBet());
    };

    const success = useSelector(
        (state: RootState) => state.BatchBetting.bet.success
    );

    const error = useSelector(
        (state: RootState) => state.BatchBetting.bet.error
    );

    const status = useSelector(
        (state: RootState) => state.BatchBetting.bet.status
    );

    return { placeBet, clearBetInfo, pending, success, error, status };
};

export default useSystemBet;
